import Pagination from "@material-tailwind/react/Pagination";
import PaginationItem from "@material-tailwind/react/PaginationItem";
import Icon from "@material-tailwind/react/Icon";

export default function PaginationComponent({numPages, currentPage, setCurrentPage}) {
    return (
        <Pagination>
            <PaginationItem className="cursor-pointer" button ripple="dark" onClick={() => setCurrentPage(0)}>
                First
            </PaginationItem>
            <PaginationItem className="cursor-pointer" ripple="dark" onClick={currentPage !== 0 ? () => setCurrentPage(currentPage - 1) : _ => {}}>
                <Icon name="keyboard_arrow_left" />
            </PaginationItem>
            <span className="flex justify-center items-center text-gray-700 text-sm">{(currentPage + 1) + ' to ' + numPages}</span>
            <PaginationItem className="cursor-pointer" ripple="dark" onClick={currentPage !== (numPages - 1) ? () => setCurrentPage(currentPage + 1) : _ => {}}>
                <Icon name="keyboard_arrow_right" />
            </PaginationItem>
            <PaginationItem className="cursor-pointer" button ripple="dark" onClick={() => setCurrentPage(numPages - 1)}>
                Last
            </PaginationItem>
        </Pagination>
    );
}