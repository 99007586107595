import {useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Icon from '@material-tailwind/react/Icon';
import Button from '@material-tailwind/react/Button';
import Tooltips from '@material-tailwind/react/Tooltips';
import TooltipsContent from '@material-tailwind/react/TooltipsContent';
import Paragraph from '@material-tailwind/react/Paragraph';
import Popover from "@material-tailwind/react/Popover";
import PopoverContainer from "@material-tailwind/react/PopoverContainer";


import Modal from 'components/general/Modal';

import { Heading3 } from '@material-tailwind/react'

import {useGlobalState} from 'state-pool';

export default function ProfileDetails() {
    const [showEnable2faConfirm, setShowEnable2faConfirm] = useState(false);
    const [twoFaEnabled, setTwoFaEnabled] = useState(false);
    const [showEmailNotificationsModal, setShowEmailNotificationsModal] = useState(false);
    const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(false);
    const [numClicks, setNumClicks] = useState(1);
    const [currentUser] = useGlobalState('currentUser');
    const helpRef = useRef();
    const copiedRef = useRef();

    const onKeyClick = () => {
        navigator.clipboard.writeText(currentUser && currentUser.btc_pubkey);

        if (!(numClicks % 2 === 0)) {
            setTimeout(() => copiedRef.current.click(), 999);
        }
        setNumClicks(numClicks + 1);
    };

    const KYC_NAMES = {
        0: 'Unverified',
        1: 'Level 1',
        2: 'Level 2',
        3: 'Level 3',
    }

    const KYC_ACTIONS = {
        0: 'Verify',
        1: 'Level 2',
        2: 'Level 3',
        3: 'Verified',
    }

    return (
        <>
            <div className="h-5" />
            <Heading3 color="white">Your Information</Heading3>
            <div className="h-2" />
            <Card className="mb-2 bg-primary">
                <CardBody>
                    <div className="relative h-10">
                        <div className="inline left">
                            <h5 className="text-gray-500 font-normal tracking-wide text-xs">Legal name</h5>
                            <span className="text-sm text-white font-normal">{currentUser && currentUser.name_first} {currentUser && currentUser.name_last}</span>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Card className="mb-2 bg-primary">
                <CardBody>
                    <div className="relative h-10">
                        <div className="inline left">
                            <h5 className="text-gray-500 font-normal tracking-wide text-xs">Email</h5>
                            <span className="text-sm text-white font-normal">{currentUser && currentUser.email}</span>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <div className="break-all-child">
            <Card className="mb-2 bg-primary">
                <CardBody>
                    <div className="relative h-10">
                        <div className="inline left">
                            <h5 className="text-gray-500 font-normal tracking-wide text-xs">Bitcoin public key</h5>
                            <span className="cursor-pointer text-white" ref={copiedRef} onClick={onKeyClick}>{currentUser && currentUser.btc_pubkey}</span>
                            <Popover placement="top" ref={copiedRef}>
                                <PopoverContainer className="bg-black text-white  after:text-white after:fill-black p-2 popover">
                                    Copied to clipboard
                            </PopoverContainer>
                            </Popover>
                        </div>
                    </div>
                </CardBody>
            </Card>
            </div>
            {/* <Card className="mb-2 bg-primary">
                <CardBody>
                    <div className="relative h-10">
                        <div className="inline left">
                            <h5 className="text-gray-500 font-normal tracking-wide text-xs">Country</h5>
                            <span className="text-sm text-white font-normal">South Africa</span>
                        </div>
                    </div>
                </CardBody>
            </Card> */}
            <Card className="mb-2 bg-primary">
                <CardBody>
                    <div className="relative h-10">
                        <div className="inline left">
                            <div className="flex">
                                <h5 className="text-gray-500 font-normal tracking-wide text-xs">Verification status</h5>
                                <div className="flex justify-end">
                                    <span ref={helpRef} className="ml-2 text-gray-500 h-5 cursor-pointer z-50">
                                        <Icon className="ml-2" name="help" size="lg" color="white" />
                                    </span>
                                    <Tooltips placement="right" ref={helpRef}>
                                        <TooltipsContent>This is your current level of verification. Each level requires you to submit and prove documents which will give you access to more of our platforms features.</TooltipsContent>
                                    </Tooltips>
                                </div>
                            </div>
                            <span className="text-sm text-white font-normal">{currentUser && KYC_NAMES[currentUser?.kyc_current]}</span>
                        </div>
                        {
                            currentUser?.kyc_current < 3 &&
                            <Link to={`/verify/${currentUser?.kyc_current + 1}`}>
                                <Button color="green" className="inline float-right hover:bg-green hover:text-black" buttonType="outline">
                                    {currentUser && KYC_ACTIONS[currentUser?.kyc_current]}
                                    <Icon name="arrow_forward" color="black" size="sm" />
                                </Button>
                            </Link>
                        }
                    </div>
                </CardBody>
            </Card>
            {/* <Card className="mb-2 bg-primary">
                <CardBody>
                    <div className="relative h-10">
                        <div className="inline left">
                            <h5 className="text-gray-500 font-normal tracking-wide text-xs">2FA status</h5>
                            <span className="text-sm text-white font-normal">{twoFaEnabled ? "Enabled" : "Disabled"}</span>
                        </div>
                        {twoFaEnabled ? (
                            <Button color="green" onClick={() => setShowEnable2faConfirm(true)} className="inline float-right hover:bg-transparent" buttonType="outline">
                                Disable
                            </Button>
                        ) : (
                            <Button color="green" onClick={() => setShowEnable2faConfirm(true)} className="inline float-right hover:bg-transparent" buttonType="outline">
                                Enable
                            </Button>    
                        )}
                    </div>
                </CardBody>
            </Card> */}
            {/* TODO: May enable below at future stage */}
            {/* <Card className="mb-2 bg-primary">
                <CardBody>
                    <div className="relative h-10">
                        <div className="inline left">
                            <h5 className="text-gray-500 font-normal tracking-wide text-xs">Email login notifications</h5>
                            <span className="text-sm text-white font-normal">On</span>
                        </div>
                        <div className="inline float-right">
                            <Checkbox 
                                color="gray"
                                text=""
                                id="emailEnabled"
                                checked={emailNotificationsEnabled}
                                onClick={_ => setShowEmailNotificationsModal(true)}
                                onChange={() => {}}
                            />
                        </div>
                    </div>
                </CardBody>
            </Card> */}
            <Modal 
                showModal={showEnable2faConfirm}
                setShowModal={setShowEnable2faConfirm}
                title={!twoFaEnabled ? "Enable 2 factor authentication" : "Disable 2 factor authentication"}
                size="regular"
            >
                <Paragraph color="white">
                    {twoFaEnabled ? 
                        `Are you sure you want to disable 2 factor authentication?` :
                        `Enabling 2 factor authentication helps secure your acount. Each time you log in, we will email you a 4 digit code which you will enter as an extra step after email & password validation.`
                    }
                </Paragraph>
                <Button 
                    onClick={() => {
                        setShowEnable2faConfirm(false);
                        setTimeout(() => {
                            setTwoFaEnabled(!twoFaEnabled);
                        }, 500);
                    }} 
                    color={!twoFaEnabled ? "green" : "orange"} 
                    className="inline float-right border-primary" 
                    buttonType="fill"
                >
                    {twoFaEnabled ? "Disable" : "Enable"}
                </Button>
                <Button 
                    onClick={() => setShowEnable2faConfirm(false)} 
                    color="red" 
                    className="inline mr-2 float-right border-primary hover:bg-red hover:text-white" 
                    buttonType="outline"
                    ripple="dark"
                >
                    Cancel
                </Button>
            </Modal>
            <Modal 
                showModal={showEmailNotificationsModal}
                setShowModal={setShowEmailNotificationsModal}
                title={!emailNotificationsEnabled ? "Enable login notifications" : "Disable login notifications"}
                size="regular"
            >
                <Paragraph>
                    {emailNotificationsEnabled ? 
                        `Are you sure you want to disable login notifications?` :
                        `Enabling login notifications helps secure your acount. Each time a log in on your account occurs, we will email you. If it was not you, you can flag the activity in the email. We will immediately end that session and send you a reset password email, weher you'll be able to regain access to you account.`
                    }
                </Paragraph>
                <Button 
                    onClick={() => {
                        setShowEmailNotificationsModal(false);
                        setTimeout(() => {
                            setEmailNotificationsEnabled(!emailNotificationsEnabled);
                        }, 500);
                    }} 
                    color={!emailNotificationsEnabled ? "green" : "orange"} 
                    className="inline float-right border-primary" 
                    buttonType="fill"
                >
                    {emailNotificationsEnabled ? "Disable" : "Enable"}
                </Button>
                <Button 
                    onClick={() => setShowEmailNotificationsModal(false)} 
                    color="red" 
                    className="inline mr-2 float-right border-primary" 
                    buttonType="outline"
                >
                    Cancel
                </Button>
            </Modal>
        </>
    );
}
