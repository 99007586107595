import {useEffect, useRef} from 'react';
import decode from 'jwt-decode';
import {useHistory} from 'react-router-dom';
import {store, useGlobalState} from 'state-pool';

import {useOffline} from 'hooks/useOffline';
import {useAlert} from 'hooks/useAlert';
import {vbApi} from 'api/vb';
import { useLocalStorage } from 'usehooks-ts';
import { isValidJson } from 'utils';

store.setState("currentUser", null);

const initialValue = localStorage.getItem('currentUser') &&
typeof localStorage.getItem('currentUser') === 'string' &&
// @ts-ignore
isValidJson(localStorage.getItem('currentUser')) &&
// @ts-ignore
JSON.parse(localStorage.getItem('currentUser'));

export const useAuth = () => {
    const [currentUser, setCurrentUser] = useGlobalState(`currentUser`);
    const [persistedUser, setPersistedUser] = useLocalStorage('currentUser', initialValue);
    const {isOnline, addToQueue} = useOffline();
    const {newAlert} = useAlert();
    const timerRef: any = useRef();

    const history = useHistory();
    /* eslint-disable */
    useEffect(() => {
        if(!currentUser && persistedUser && typeof persistedUser === 'object') {

            // @ts-ignore /this is an object type hence the typof check above ?????!!!
            setCurrentUser({...persistedUser});
        }

        if(!persistedUser) {
            setCurrentUser(null);
        }

        const timerInstance = timerRef;

        return function cleanUp() {
            if(timerInstance && timerInstance.current) clearInterval(timerInstance.current);
        }

    }, []);

    async function getJwt(adminId: number, userId: number, adminToken: string){
        try{
            const res = await vbApi.post(`/authO/loginas`, {
                "admin_id": adminId,
                "user_id": userId,
                "admin_token": adminToken
            })
            return res.data
        } catch(e) {
            console.log(e)
        }
        
    }

    async function loginAs(accessToken: any, refreshToken: any){
        setCurrentUser(null);
        localStorage.clear();
        if(accessToken && refreshToken) {

            localStorage.setItem('access-token', accessToken);
            localStorage.setItem('refresh-token', refreshToken);
            
            const decoded: {id: number, firstname: string, lastname: string} = decode(accessToken);

            setCurrentUser(decoded);
            localStorage.setItem('currentUser', JSON.stringify(decoded));

            if (decoded) {
                getUserDetails(decoded).then(_ => {
                    return true;
                });

                timerRef.current = setInterval(() => {
                    getUserDetails(decoded);
                }, 30000);
            };

            return true;
        } else {

            return false;
        }
    }

    async function getAllUserDetails(id: number) {
        try{
            const res = await vbApi.get(`/admin/user/getAllUserDetails/${id}`)
            return res.data
        }  catch(e) {
            console.log(e)
        }
    }

    async function getUserDetails(decodedJwt?: {id: number, firstname: string, lastname: string}) {
        const res = await vbApi.get(`/user/getuser`);

        if(typeof res === 'object') {

            if(res.data) {
                let userData = {...currentUser, ...res.data};

                if(decodedJwt) {
                    userData = {...userData, ...decodedJwt};
                }

                setCurrentUser({...userData});
                setPersistedUser({...userData});

                return true;
            } else {
                let userData = {...currentUser, ...res};

                if(decodedJwt) {
                    userData = {...userData, ...decodedJwt};
                }

                setCurrentUser({...userData});
                setPersistedUser({...userData});

                return true;
            }
        }

        return false;
    }
    
    async function logout() {
        const accessToken = localStorage.getItem('access-token');
        const refreshToken = localStorage.getItem('refresh-token');

        if(refreshToken && typeof refreshToken === 'string' && accessToken && typeof accessToken === 'string') {
            await vbApi.delete('/authO/logout', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    token: refreshToken
                }
            });
            setCurrentUser(null);
            localStorage.clear();
        }
        
        history.push('/login');
    }

    async function login(email: string, password: string) {
        try {
            const res = await vbApi.post('/authO/login', {
                email,
                password
            });

            if(res && res.status === 200 && res.data.accessToken) {
                const {accessToken, refreshToken} = res.data;

                localStorage.setItem('access-token', accessToken);
                localStorage.setItem('refresh-token', refreshToken);
                
                const decoded: {id: number, firstname: string, lastname: string} = decode(accessToken);

                setCurrentUser(decoded);
                localStorage.setItem('currentUser', JSON.stringify(decoded));

                if (decoded) {
                    getUserDetails(decoded).then(_ => {
                        return true;
                    });

                    timerRef.current = setInterval(() => {
                        getUserDetails(decoded);
                    }, 30000);
                };

                return true;
            } else {

                return false;
            }
        } catch(err) {
            return false;
        }
    }

    async function commit(data: any, path: string) {
        const online = isOnline();

        if(!online) {
            addToQueue('PUT', path, data);
            // TODO
            newAlert('danger', 'No network connection', 'Item will be updated when connection is restored');

            // show no network
            return;
        }

        try {
            // updates data with updated data object
            const isSuccessful = await vbApi.put(path, data);

            if(isSuccessful) {
                // refreshes users data once complete
                getUserDetails();

                return true;
            }
        } catch (err) {
            addToQueue('PUT', path, data);
            return;
        }
    }

    return {
        logout,
        login,
        commit,
        currentUser,
        setCurrentUser,
        getUserDetails,
        getAllUserDetails,
        loginAs,
        getJwt
    }
}


