import { useState } from 'react';

import 'assets/styles/core.css'

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';

import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import SuccessfulSubmission from 'components/forgot-password/SuccessfulSubmission';

import {forgotPassword} from 'routes/userRoutes';

import {Link , useHistory} from 'react-router-dom';

import {displayAlertModal} from 'utils';

import { Helmet } from 'react-helmet';

export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const history = useHistory();

    async function onButtonClick() {
        if(!email) {
            setError('Please enter your email address to continue');

            return;
        }

        const isSuccessful  = await forgotPassword(email);

        if(isSuccessful) setSuccess(true);

        if(!isSuccessful) displayAlertModal('Something went wrong, please try again later.');
    }

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>ForgotPassword - Venturebay</title>
            <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div className="h-screen px-3 md:px-8 flex items-center justify-center flex-col bg-image">
            
            <div className="h-2" />
            <Card className="max-w-3xl text-center bg-primary">
            {!success && (
                <>
                    <h3 className="text-white text-4xl">Forgot your Password?</h3>
                    <div className="h-2" />
                </>
            )}
                <div className="h-2" />
                <CardBody>
                <div className="h-2" />
            {!success && (
                <div className="max-w-3xl text-center w-full">  
                    <div className="h-2" />
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onFocus={() => setError('')}
                            type="text"
                            color="green"
                            error={error}
                            placeholder="Enter your email"
                            size="regular"
                        />
                    </div>
                    
                </div>
            )}
            {success && <SuccessfulSubmission />}
                </CardBody>
                <CardFooter>
                    <Button
                        color="green"
                        className="w-full hover:bg-green hover:text-black hover:shadow-xl hover:pr-2"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={!success ? onButtonClick : () => history.push('/login')}
                    >
                        {!success ? "Next" : "Login"}
                        <Icon name="arrow_forward" size="sm" />
                    </Button>
                    <div className="h-5" />
                    <Button
                        color="green"
                        className="w-full hover:bg-green hover:text-black hover:shadow-xl"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={() => history.goBack()}
                    >
                        {"Back"}
                        <Icon name="arrow_backward" size="sm" style={{width: '14px', height: '14px'}}/>
                    </Button>
                    <div className="h-5" />
                    <Paragraph color="white">
                        Need an account? <Link className="text-green" to="/register">Register</Link>
                    </Paragraph>
                </CardFooter>
            </Card>
        </div>
        </>
    );
}
