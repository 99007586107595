import { UpdateController } from './UpdateController';
import Alert from "@material-tailwind/react/ClosingAlert";
import Button from '@material-tailwind/react/Button';

const UpdateAvailable = () => {
    const {show, refresh} = UpdateController();

    const button = <Button
        size="sm"
        color="white"
        onClick={refresh}
        ripple="dark"
        buttonType="outline"
        className="margin-none bg-white text-white absolute"
        style={{right: '5em', top: '23%'}}
    >
        REFRESH
    </Button>;

    return (
        <>
            {show && (
                <Alert style={style} color="lightBlue">
                    <span>
                        <b>Update available</b> A new version is availaible, refresh to activate
                    </span>
                    {button}
                </Alert>
            )}
        </>
    );
};

const style = {
    position: "fixed", 
    bottom: "-1em", 
    width: '100%',
    zIndex: 9999
}

export default UpdateAvailable;