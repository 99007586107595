import {useState, useEffect} from 'react';
import {useGlobalState} from 'state-pool';

import Icon from '@material-tailwind/react/Icon';

import TransactionCategories from 'components/transactions/TransactionCategories';
import TransactionsTable from 'components/transactions/TransactionsTable';

import { Helmet } from 'react-helmet'

export default function Transactions() {
    //const {selectedparam} = useParams();
    const [selected, setSelected] = useState('');
    const [transactions] = useGlobalState('transactions');

    useEffect(() => {
        setSelected('all');
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Transaction - Venturebay</title>
            </Helmet>
            {transactions && transactions.results && Array.isArray(transactions.results) && transactions.results.length > 0 ? (
                <>
                    <div className="px-3 md:px-8 h-auto mt-24">
                    <TransactionCategories selected={selected} setSelected={setSelected}/>
                        <div className="h-16" />
                        <div className="container mx-auto max-w-full">
                            <div className="grid grid-cols-1 px-4 mb-16">
                                <TransactionsTable selected={selected} />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="h-96 w-full flex flex-col items-center justify-center">
                    <Icon name="error_outline" size="9xl" color="white" />
                    <div className="h-5" />
                    <h2 className="text-white">You have not made any transactions yet</h2>
                </div>
            )}
        </>
    );
}
