import {useRef} from 'react';

import {Link, useHistory} from 'react-router-dom';
import { bitorsat } from 'utils/js';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";

import {useGlobalState} from 'state-pool';

export default function BalanceInfo() {
    const [currentUser] = useGlobalState('currentUser');
    const history = useHistory();
    const investBtcRef = useRef();
    const depositBtcRef = useRef();
    const withdrawBtcRef = useRef();
    const helpRef = useRef();

    return (
        <>
            <Card className="bg-primary">
                <CardBody>
                    <div className="relative flex items-center">
                        <div className="h-10 w-10 rounded-full mr-5 flex-shrink-0 bg-bitcoin flex items-center justify-center">
                            <img alt="btc_logo" src="/icons/bitcoin.svg" className="h-7 w-7 rounded-full" />
                        </div>
                        <div>
                            <div className="flex">
                                <h5 className="text-gray-500 font-normal tracking-wide">Cryptocurrency</h5>
                                <div className="flex justify-end">
                                    <span ref={helpRef} className="ml-2 text-gray-500 h-5 cursor-pointer z-10">
                                        <Icon className="ml-2" name="help" size="xl" color="white" />
                                    </span>
                                    <Tooltips placement="bottom" ref={helpRef}>
                                        <TooltipsContent>This is your accounts current balance. It is the total amount that you have invested into your Venturebay account which has not been invested in any fund yet. Use this balance to invest in one of our growth funds. If you invest in a fund you can withdraw to your wallet which you'll then be able to send to another bitcoin public key or bank account.</TooltipsContent>
                                    </Tooltips>
                                </div>
                            </div>
                            <span className="text-xl font-normal text-white">{currentUser && currentUser.balance_wallet && bitorsat(parseInt(currentUser.balance_wallet))}</span>
                        </div>
                        <div className='flex flex-col md:flex-row gap-2 w-full justify-end'>
                        <Button
                                color="gray"
                                className="ml-auto self-end"
                                buttonType="filled"
                                size="sm"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => history.push('/funds')}
                                ref={investBtcRef}
                            >
                            <Icon name="trending_up" size="2xl" color="white" />
                        </Button>
                        <Tooltips placement="top" ref={investBtcRef}>
                            <TooltipsContent>Invest in a fund with your Bitcoin</TooltipsContent>
                        </Tooltips>
                        <Button
                                color="gray"
                                className="ml-3 self-end"
                                buttonType="filled"
                                size="sm"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => history.push('/deposit')}
                                ref={depositBtcRef}
                            >
                        <Icon name="attach_money" size="2xl" color="white" />
                    </Button>
                    <Tooltips placement="top" ref={depositBtcRef}>
                        <TooltipsContent>Deposit bitcoin into your wallet</TooltipsContent>
                    </Tooltips>
                    <Link to="/profile/withdraw" className="ml-3 self-end">
                        <Button
                                    color="gray"
                                    buttonType="filled"
                                    size="sm"
                                    iconOnly
                                    rounded
                                    ripple="light"
                                    onClick={() => {}}
                                    ref={withdrawBtcRef}
                                >
                            <Icon name="account_balance_wallet" size="2xl" color="white" />
                        </Button>
                        <Tooltips placement="top" ref={withdrawBtcRef}>
                            <TooltipsContent>Withdraw from your wallet</TooltipsContent>
                        </Tooltips>
                    </Link>
                    </div>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}