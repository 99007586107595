import {useState, useRef, useEffect} from 'react';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Checkbox from '@material-tailwind/react/Checkbox';
import { useReadLocalStorage } from 'usehooks-ts';
import { bitorsat } from 'utils/js';

const HODL_OPTIONS = [
    "30 days",
    "6 months",
    "12 months",
    "24 months"
];

export default function WithdrawalAmount({stage, setStage, amount, setAmount, submit, hodlDate, setHoldDate, hodl, setHodl, insufFunds}) {
    const walletRef = useRef();
    const hodlRef = useRef();
    
    const [selected, setSelected] = useState(0);
    const [error, setError] = useState('');
    const currentUser = useReadLocalStorage('currentUser');
    // const [currencyType, setCurrencyType] = useState('BTC');

    const onContinueClick = async () => {
        if(!amount) {
            setError('Please enter the amount you would like to invest to continue');

            return;
        }

        if(amount && Number(amount) <= 0) {
            setError('Please enter a valid amount to continue.');

            return;
        }

        if(insufFunds){
            setError('Not enough funds');

            return;
        }

        const isSuccessful = await submit();

        if(isSuccessful.data !== "not enough funds") {
            setStage(stage + 1);
        } else {
            setError('Insufficient funds')
        }
        
    }

    useEffect(() => {
        if(insufFunds){
            setError('Not enough funds');

            return;
        } else{
            setError('')
        }
    }, [insufFunds])
/* eslint-disable */
    useEffect(() => {
        var result = new Date();
        if (selected === 0) {
            setHoldDate(result.setDate(result.getDate() + 30))
        }
        if (selected === 1) {
            setHoldDate(result.setDate(result.getDate() + 182))
        }
        if (selected === 2) {
            setHoldDate(result.setDate(result.getDate() + 365))
        }
        if (selected === 3) {
            setHoldDate(result.setDate(result.getDate() + 730))
        }
    },[selected])

    // const handleCurrencyType = (e) => {
    //     setCurrencyType(e.target.innerText);
    // }

    return (
        <div className="w-full text-left">
            <div className="h-10" />
            <h3 className="font-normal text-4xl text-white">How much would you like to invest?</h3>
            <div className="h-20" />
            
            <div className="flex mb-5 w-full">
                <div className={`w-full`}>
                    <div className="bg-primary w-full rounded-xl overflow-hdden p-4 shadow-2xl" >
                        <div className="flex items-center justify-start py-1 h-10">
                            <p className="font-bold text-xl text-white mt-0"><span className="fw-300 mr-3">Current Balance:</span> {currentUser ? bitorsat(currentUser.balance_wallet) : "Failed to load"}</p>

                            <div className="ml-auto cursor-pointer">
                                <span ref={walletRef} className="text-gray-500">
                                    <Icon className="ml-2" name="help" size="4xl" color="gray" />
                                </span>
                                <Tooltips placement="top" ref={walletRef}>
                                    <TooltipsContent>This is your current wallet balance that is the amount that your account has which is not currently invested in any fund.</TooltipsContent>
                                </Tooltips>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Card className="max-w-3xl text-left bg-primary">  
                <CardBody>
                    <div className="mb-4 text-left flex">
                        <span className="mt-2 mr-4 text-white font-normal text-2xl">
                            {'BTC'}
                            {/* <Dropdown
                                color="black"
                                placement="bottom-start"
                                buttonText={currencyType}
                                size="regular"
                                rounded={false}
                                block={false}
                            >
                                {currencyType !== 'BTC' 
                                ?<DropdownItem color="yellow" ripple="light">
                                    <span style={{color: 'black'}} onClick={(e) => handleCurrencyType(e)}>BTC</span>
                                </DropdownItem>
                                :<DropdownItem color="yellow" ripple="light">
                                    <span style={{color: 'black'}} onClick={(e) => handleCurrencyType(e)}>SAT</span>
                                </DropdownItem>
                                }
                            </Dropdown> */}
                        </span>
                        <Input
                            outline={false}
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            error={error}
                            color="gray"
                            placeholder="Amount"
                            size="large"
                            type="number"
                            min={1}
                        />
                    </div>
                    <div className="h-2" />
                    <div className="mb-4 text-left flex items-center">
                        <Checkbox
                            color="white"
                            text="HODL"
                            id="hodl"
                            className="flex-shrink-0"
                            value={hodl}
                            defaultChecked={true}
                            disabled={true}
                        />
                        <div className="ml-2 cursor-pointer">
                            <span ref={hodlRef} className="text-gray-500">
                                <Icon className="ml-2" name="help" size="2xl" color="gray" />
                            </span>
                            <Tooltips placement="top" ref={hodlRef}>
                                <TooltipsContent>One month manditory HODL(Holds on to investments for x amount of time)</TooltipsContent>
                            </Tooltips>
                        </div>
                    </div>
                    {hodl && (
                        <>
                            <p className="text-white fw-300 text-lg mb-4">Select <span className="font-bold">hodl</span> period</p>
                            <div className="mb-4 text-left flex items-center">
                                <div className="flex items-center w-full">
                                    {HODL_OPTIONS.map((o, i) => (
                                        <div key={i} onClick={() => setSelected(i)} className={`h-16 w-full md:w-1/4 ${i !== 0 ? "ml-4" : ""} ${selected === i ? "bg-beige border-none" : "border-beige border-2 text-beige"} rounded-lg flex items-center justify-center font-normal cursor-pointer`}>
                                            <span className="font-bold">{o}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </CardBody>
            </Card>
            <div className="h-5" />
            <Button
                color="green"
                className="hover:bg-green hover:text-black w-full hover:pr-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={onContinueClick}
            >
                Continue
                <Icon name="arrow_forward" size="sm" />
            </Button> 
            {/* <div className="h-10" />
            <Paragraph color="white">
                Min max amounts goes here ?
            </Paragraph> */}
        </div>
    );
}
