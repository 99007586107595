import Card from 'components/cards/Card';
import Icon from '@material-tailwind/react/Icon';
import { Link } from 'react-router-dom';

export default function WhatNext() {

    return (
        <>
            <div className="flex flex-col md:flex-row">
                <Link to="/profile" className="w-full p-2">
                    <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                        <div className="flex items-center justify-center flex-col h-full py-1">
                            <Icon name="person" color="white" size="6xl" />
                            <h3 className="text-white">Your profile</h3>
                        </div>
                    </Card>
                </Link>
                <Link to="/support" className="w-full p-2">
                    <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                        <div className="flex items-center justify-center flex-col h-full py-1">
                            <Icon name="help" color="white" size="6xl" />
                            <h3 className="text-white">Get help</h3>
                        </div>
                    </Card>
                </Link>
            </div>
            <div className="flex flex-col md:flex-row">
                <Link to="/funds" className="w-full p-2">
                    <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                        <div className="flex items-center justify-center flex-col h-full py-1">
                            <Icon name="trending_up" color="white" size="6xl" />
                            <h3 className="text-white">Funds</h3>
                        </div>
                    </Card>
                </Link>
                <Link to="/transactions" className="w-full p-2">
                    <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                        <div className="flex items-center justify-center flex-col h-full py-1">
                            <Icon name="sync_alt" color="white" size="6xl" />
                            <h3 className="text-white">Your Transactions</h3>
                        </div>
                    </Card>
                </Link>
            </div>
        </>
    );
}
