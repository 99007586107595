import { Paragraph, Heading3 } from "@material-tailwind/react";

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Link } from "react-router-dom";

export default function Welcome() {

    return (
        <Card className="max-w-3xl text-center bg-primary">   
            <CardBody className="text-center">
                <div className="w-full text-center">
                    <Icon name="check_circle_outline" size="9xl" color="white" />
                    <Heading3 color="white">Welcome to Venturebay</Heading3>
                    <Paragraph color="white">
                        Congratulations, you've successfully registered!
                    </Paragraph>
                </div>
            </CardBody>
            <CardFooter>
                <div className="flex justify-center flex-col">
                    <Link to="/login">
                        <Button
                            color="green"
                            className="hover:bg-green hover:text-black w-full hover:shadow-xl hover:pr-2"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                        >
                            Log in now
                            <Icon color="black" name="arrow_forward" size="sm" />
                        </Button>
                    </Link>
                </div>
            </CardFooter>
    </Card>
);
}
