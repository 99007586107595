import { useState, useEffect } from 'react';

import Progress from '@material-tailwind/react/Progress';

import InvestmentAmount from 'components/new-investment/InvestmentAmount';
import InsufficientFunds from 'components/new-investment/InsufficientFunds';
import Fin from 'components/new-investment/Fin';

import BackButton from 'components/buttons/BackButton';
import { useHistory, useParams } from 'react-router';

import { useGlobalState } from 'state-pool';
import { useGlobal } from 'hooks/useGlobalState';
import { parseQueryString } from 'utils';

import {displayAlertModal} from 'utils';

export default function NewInvestment() {
    const [stage, setStage] = useState(1);
    const [amount, setAmount] = useState('');
    const [fundName, setFundName] = useState('');
    const [currentUser] = useGlobalState('currentUser');
    const { investmentId } = useParams();
    const [hodlDate, setHoldDate] = useState('');
    const [hodl, setHodl] = useState(true);
    const { add } = useGlobal();
    const [
        insufficientFunds,
        setInsufficientFunds
    ] = useState(false);
    const [insufFunds, setInsufFunds] = useState(false);

    const history = useHistory();

    const numStages = insufficientFunds ? 3 : 2;

    const percentageComplete = String((stage / numStages) * 100);

    const submit = async () => {
            const body = {
                user_id: currentUser && currentUser.id,
                funds_id: investmentId,
                funds_amount: amount * 100000000
            }
            if (hodl) {
                body.hodl_until = new Date(hodlDate)
            }
        const isSuccessful = await add(body, '/user/usertofundtransaction');

        if(isSuccessful) return isSuccessful;

        if(!isSuccessful) displayAlertModal('Something went wrong, please try again later.');
    }

    useEffect(() => {
        if (currentUser && currentUser.balance_wallet === 0) {
            setInsufficientFunds(true);
        } 
    }, [currentUser]);

    useEffect(() => {
        if (currentUser && currentUser.balance_wallet < (amount * 100000000)){
            setInsufFunds(true);
        }else{
            setInsufFunds(false)
        }
    }, [amount])

    useEffect(() => {
        const pageQueries = parseQueryString();

        if (pageQueries && pageQueries.n) {
            setFundName(pageQueries.n);
        }
    }, []);

    return (
        <div className="h-auto flex items-center justify-center flex-col relative">
            <div className="w-full absolute top-0 right-0 left-0">
                <Progress color="gray" value={percentageComplete} percentage={false} />
            </div>
            <div className="px-3 md:px-8 md:w-full">
                {stage !== 1 && (
                    <div className="absolute left-1 xs:top-5 md:top-3">
                        <BackButton onClick={() => { stage !== 1 ? setStage(stage - 1) : history.goBack() }} />
                    </div>
                )}
                <div className="max-w-3xl flex items-center flex-col mx-auto">
                    {stage === 1 && insufficientFunds && <InsufficientFunds />}
                    {stage === 1 && !insufficientFunds && <InvestmentAmount submit={submit} amount={amount} setAmount={setAmount} stage={stage} setStage={setStage} hodlDate={hodlDate} setHoldDate={setHoldDate} hodl={hodl} setHodl={setHodl} insufFunds={insufFunds} />}
                    {stage === 2 && insufficientFunds && <InvestmentAmount submit={submit} amount={amount} setAmount={setAmount} stage={stage} setStage={setStage} hodlDate={hodlDate} setHoldDate={setHoldDate} hodl={hodl} setHodl={setHodl} />}
                    {stage === 2 && !insufficientFunds && <Fin fundName={fundName} amount={amount} />}
                    {stage === 3 && insufficientFunds && <Fin fundName={fundName} amount={amount} />}
                </div>
            </div>
        </div>
    );
}