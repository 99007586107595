import { useState } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';

import {useEventListener} from 'usehooks-ts';

import AdminNavbar from 'components/layout/AdminNavbar';
import Icon from '@material-tailwind/react/Icon';
import Button from '@material-tailwind/react/Button';

import InstallButton from 'components/pwa/InstallButton';

import SvgLogo from 'assets/svg-icons/SvgLogo';

// NOTE: "sb-noclose" is a class created & used for the purpose of determining which elements shouldn't close the sidebar(mobile view) when clicked

export default function Sidebar() {
    const [showSidebar, setShowSidebar] = useState('-right-64');
    const history = useHistory();

    useEventListener('click', (e) => {
        if(
            showSidebar !== '-right-64' && 
            e && e.target && e.target.classList && 
            !e.target.classList.contains('sb-noclose')
        ) setShowSidebar('-right-64');
    });

    const logout = () => {
        localStorage.clear();
        history.push('/');
    };

    return (
        <>
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-primary w-64 z-50 py-4 px-6 transition-all duration-300 sb-noclose`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative sb-noclose">
                    <div className="w-full flex justify-center mb-8">
                        <Link
                            to="/"
                            className="mt-2 sb-noclose"
                        >
                            <SvgLogo className="w-40 h-auto sb-noclose"/>
                        </Link>
                    </div>
                    <div className="flex flex-col sb-noclose justify-between">
                        {/* <hr className="my-4 min-w-full sb-noclose" /> */}

                        <ul className="flex-col min-w-full flex list-none">
                            <li className="rounded-lg mb-4">
                                <NavLink
                                    to="/dashboard"
                                    exact
                                    className="flex items-center gap-4 text-sm hover:bg-gray hover:bg-opacity-20 text-white font-normal px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-gray text-white shadow-md"
                                >
                                    <Icon name="dashboard" size="2xl" />
                                    Dashboard
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/deposit"
                                    className="flex items-center gap-4 text-sm hover:bg-gray hover:bg-opacity-20 text-white font-normal px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-gray text-white shadow-md"
                                >
                                    <Icon name="paid" size="2xl" />
                                    Deposit
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/funds"
                                    className="flex items-center gap-4 text-sm hover:bg-gray hover:bg-opacity-20 text-white font-normal px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-gray text-white shadow-md"
                                >
                                    <Icon name="apps" size="2xl" />
                                    Funds
                                </NavLink>
                            </li>
                            { <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/transactions"
                                    className="flex items-center gap-4 text-sm hover:bg-gray hover:bg-opacity-20 text-white font-normal px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-gray text-white shadow-md"
                                >
                                    <Icon name="sync_alt" size="2xl" />
                                    Transactions
                                </NavLink>
                            </li> }
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/investments"
                                    className="flex items-center gap-4 text-sm hover:bg-gray hover:bg-opacity-20 text-white font-normal px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-gray text-white shadow-md"
                                >
                                    <Icon name="trending_up" size="2xl" />
                                    Investments
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/profile"
                                    className="flex items-center gap-4 text-sm hover:bg-gray hover:bg-opacity-20 text-white font-normal px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-gray text-white shadow-md"
                                >
                                    <Icon name="person" size="2xl" color="#000000" />
                                    Profile
                                </NavLink>
                            </li>
                            {/* <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/news"
                                    className="flex items-center gap-4 text-sm hover:bg-gray hover:bg-opacity-20 text-white font-normal px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-gray text-white shadow-md"
                                >
                                    <Icon name="attach_file" size="2xl" />
                                    News
                                </NavLink>
                            </li> */}
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/support"
                                    className="flex items-center gap-4 text-sm hover:bg-gray hover:bg-opacity-20 text-white font-normal px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-gray text-white shadow-md"
                                >
                                    <Icon name="help" size="2xl" />
                                    Support
                                </NavLink>
                            </li>
                            <li className="block md:hidden">
                                <Button onClick={logout} color="" className="inline float-right border-white text-white w-full mb-2" buttonType="outline">
                                    <Icon name="logout" size="2xl" />
                                    logout
                                </Button>
                            </li>
                            <InstallButton className="block md:hidden" />
                        </ul>

                        <ul className="flex-col min-w-full list-none relative hidden md:flex">
                            <li>
                                <Button onClick={logout} color="" className="inline float-right border-white text-white w-full mb-2" buttonType="outline">
                                    <Icon name="logout" size="2xl" />
                                    logout
                                </Button>
                            </li>
                            <InstallButton />
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
