import { Button, Icon } from '@material-tailwind/react';

import {useHistory} from 'react-router-dom';

export default function BackButton(props) {
    const history = useHistory();
    
    return (
        <Button
            color="white"
            rounded
            className="pl-0 hover:ml-4 ml-6 text-white"
            buttonType="link"
            size="lg"
            ripple="dark"
            {...props}
            onClick={() => history.goBack()}
        >
            <Icon name="arrow_back" color="white" size="sm" />
            Back           
        </Button>
    )
}


