import {useRef} from 'react';

import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';
import { bitorsat } from 'utils/js';
import {useGlobalState} from 'state-pool';

const OPTIONS = [
    // {
    //     text: "Deposit to RSA bank account",
    //     helpText: "Lorem ipsum dolor sit amet, consectetur",
    //     image: "/images/flags/rsa.jpg",
    //     type: "zar"
    // },
    {
        text: "Send to bitcoin address",
        helpText: "Use this to send bitcoin",
        image: "/images/logos/btc.svg",
        type: "btc"
    },
];

export default function ChooseWithdrawalType({stage, setStage, setType}) {
    const helpRef = useRef();
    const walletRef = useRef();
    const [currentUser] = useGlobalState('currentUser');

    function onOptionClick(type) {
        setStage(stage + 1);
        setType(type);
    }

    return (
        <div className="w-full text-left">
            <div className="h-10" />
            <h3 className="font-normal text-4xl text-white">Withdrawal type</h3>
            <Paragraph color="white">
                You can withdraw from your crypto wallet in either Rand or Bitcoin
            </Paragraph>
            <div className="h-16" />
            <div className="flex mb-5 w-full">
                <div className={`w-full`}>
                    <div className="bg-primary w-full rounded-xl overflow-hdden p-4 shadow-2xl" >
                        <div className="flex items-center justify-start py-1 h-10">
                            <p className="font-bold text-xl text-white mt-0"><span className="fw-300 mr-3">Current Balance</span> {currentUser && bitorsat(Number(currentUser.balance_wallet))}</p>

                            <div className="ml-auto">
                                <span ref={walletRef} className="text-gray-500">
                                    <Icon className="ml-2" name="help" size="4xl" color="white" />
                                </span>
                                <Tooltips placement="top" ref={walletRef}>
                                    <TooltipsContent>This is your accounts current balance. It is the total amount that you have invested into your Venturebay account which has not been invested in any fund yet</TooltipsContent>
                                </Tooltips>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex mb-5 w-full">
                {OPTIONS.map(({text, helpText, image, type}, i) => (
                    <div key={i} onClick={_ => onOptionClick(type)} className={`w-full ${i !== 0 ? "ml-5" : ""}`}>
                        <div className="w-full rounded-xl overflow-hdden p-4 shadow-2xl cursor-pointer hover:opacity-80 relative bg-150 md bg-120" style={{background: `-webkit-linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("${image}")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} >
                            <div className="absolute top-5 right-5">
                                <span ref={helpRef} className="ml-2 text-gray-500">
                                    <Icon className="ml-2" name="help" size="5xl" color="white" />
                                </span>
                                <Tooltips placement="top" ref={helpRef}>
                                    <TooltipsContent>{helpText}</TooltipsContent>
                                </Tooltips>
                            </div>
                            <div className="flex items-center justify-center flex-col py-1 h-56">
                                <p className="font-bold text-xl text-white justify-self-bottom mt-auto">{text}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}