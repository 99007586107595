import FundCard from 'components/funds/FundCard';

import {useGlobalState} from 'state-pool';

import {isValidJson} from 'utils';

import { Helmet } from 'react-helmet';

export default function Funds() {
    const [funds] = useGlobalState('funds');
    const [transactions] = useGlobalState('transactions');
    const currentInvestments = transactions && transactions.results && Array.isArray(transactions.results) && 
    transactions.results
    .filter(t => t.destination.includes('fund'))
    .map(t => Number(t.destination.split(':')[1]));
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Funds - Venturebay</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="bg-transparent pt-14 pb-28 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-3xl xl:max-w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-5 xl:grid-cols-3">
                        {funds && Array.isArray(funds) && funds.length > 0 && funds.map((f, i) => (
                                <FundCard
                                    key={i}
                                    title={f.name}
                                    description={f && f.misc && isValidJson(f.misc) && JSON.parse(f.misc).description}
                                    icon=""
                                    minBuyIn={f.min_amount && f.min_amount}
                                    yearlyChange={f.growth}
                                    id={f.id}
                                    hasInvested={currentInvestments && currentInvestments.includes(f.id)}
                                    fund={f}
                                />
                            
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
