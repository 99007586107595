import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';

import Alert from "@material-tailwind/react/Alert";

const LoginAs = () => {
    const [isValid, setIsValid] = useState()
    const { adminId, userId, md5 } = useParams();
    const history = useHistory();
    const {loginAs, getJwt} = useAuth();
    
    useEffect(() => {
       getJwt(Number(adminId), Number(userId), md5).then((res) => {
            const {accessToken, refreshToken} = res;
            loginAs(accessToken, refreshToken).then((res) => {
                res?history.push('/'):setIsValid(false)
            })
        }) 

        return () => {
            setIsValid(true)
        }
    }, [])

    // function onLoginClick() {

    //     login(email, password).then((value) => !value ? setIncorrectDetailsShow(true) : history.push('/'));
        
    //     return;
        
    // }
    

  return (
      <>
      {
        isValid === undefined
        ?   (<>
                <div className='spinner-container' >
                    <div className="spinner">
                        <div className="half-spinner"></div>
                    </div> 
                </div>
            </>)
        : isValid === false 
        ?   (<>
                <Alert color="red">Something went wrong! Please Retry</Alert>
            </>)
        :   (<>
               <Alert color="green">Successful</Alert> 
            </>)
      }
      </>
  )
}

export default LoginAs