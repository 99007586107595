import {useState, useRef} from 'react';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Paragraph from '@material-tailwind/react/Paragraph';

import ImageUpload from 'components/image-handling/ImageUpload';
import Modal from 'components/general/Modal';

import Webcam from "react-webcam";

import {useHistory} from 'react-router-dom';

const defaultImages = {
    front: {
        file_name: '',
        file_data: ''
    },
    back: {
        file_name: '',
        file_data: ''
    },
}

export default function ProofOfResidence({idType, setFrontId, setBackId}) {
    const [images, setImages] = useState(defaultImages);
    const [activeCameraSide, setActiveCameraSide] = useState('');
    const [showCameraModal, setShowCameraModal] = useState(false);
    const [error, setError] = useState({});
    const webcamRef = useRef();

    const history = useHistory();
    
    const capture = (side) => {
        const imageSrc = webcamRef.current.getScreenshot();

        const idStringFormatted = idType.replace(' ', '_');

        const image = {
            file_name: `${idStringFormatted}_${side}.webp`,
            file_data: imageSrc
        };

        if(side === 'front') setImages({...images, front: image});
        if(side === 'back') setImages({...images, back: image});
    }

    const onContinueClick = () => {

        if((images && images.front && !images.front.file_data) || (images && images.back && !images.back.file_data)) {
            let tempError = {};
            if(!images.front.file_data) {
                tempError.front = `Please take a photo or upload a picture of the front of your ${idType}`;
            }

            if(!images.back.file_data) {
                tempError.back = `Please take a photo or upload a picture of the back of your ${idType}`;
            }

            setError(tempError);

            return;
        }

        setFrontId(images.front.file_data);
        setBackId(images.back.file_data);

        history.push('/verify/2/selfie');
    }

    return (
        <>
            <div className="w-full text-left">
                <div className="h-5" />
                <h3 className="font-normal text-4xl mb-4 text-white">Photos of your {idType}</h3>
                <div className="h-2" />
                <Card className="max-w-3xl text-center bg-primary">  
                    <div className="h-2" />
                    <CardBody>
                        <p className="font-semibold text-white text-left">
                            Front of {idType}
                        </p>
                        <div className="h-5 hidden md:flex" />
                        {(images && images.front && !images.front.file_data) && (
                            <>
                                <Button
                                    color="green"
                                    className="w-full text-black hidden md:flex"
                                    buttonType="fill"
                                    size="lg"
                                    ripple="dark"
                                    onClick={() => {
                                        setActiveCameraSide('front');
                                        setShowCameraModal(true);
                                        setError({});
                                    }}
                                >
                                    Take photo
                                    <Icon name="camera_alt" size="sm" />
                                </Button> 
                                <p className="text-gray-500 text-xs text-left hidden md:flex">Center your ID and take a photo, make sure all details are clear.</p>
                            </>
                        )}
                        <div className="h-5 hidden md:flex" />
                        {(images && images.front && images.front.file_data) && <img alt={`front of ${idType}`} src={images.front.file_data} />}
                        <ImageUpload setImage={(image) => setImages({...images, front: image})}>
                            <Button
                                color="green"
                                className="hover:bg-transparent w-full"
                                buttonType={(images && images.front && !images.front.file_data) ? "outline" : "link"}
                                size="lg"
                                ripple="dark"
                                onClick={() => setError({})}
                            >
                                {(images && images.front && !images.front.file_data) ? "Upload file" : images.front.file_name}
                                {(images && images.front && !images.front.file_data) && <Icon name="upload" size="sm" />}
                            </Button> 
                        </ImageUpload>
                        {(images && images.front && !images.front.file_data) && <p className="text-gray-500 text-xs text-left">Upload a color photo of the entire front of your {idType}. Screenshots are not allowed. JPG, JPEG or PNG formats only.</p>}
                        {error && error.front && <p className="text-red text-left my-4">{error.front}</p>}
                        <div className="border-t-2 border-primary w-full mt-5 mb-5" />
                        <p className="font-semibold text-white text-left">
                            Back of {idType}
                        </p>
                        <div className="h-5 hidden md:flex" />
                            {(images && images.back && !images.back.file_data) && (
                                <>
                                    <Button
                                        color="green"
                                        className="w-full text-black hidden md:flex"
                                        buttonType="fill"
                                        size="lg"
                                        ripple="dark"
                                        onClick={() => {
                                            setActiveCameraSide('back');
                                            setShowCameraModal(true);
                                            setError({});
                                        }}
                                    >
                                        Take photo
                                        <Icon name="camera_alt" size="sm" />
                                    </Button> 
                                    <p className="text-gray-500 text-xs text-left hidden md:flex">Center your ID and take a photo, make sure all details are clear.</p>
                                </>
                            )}
                        <div className="h-5 hidden md:flex" />
                        {(images && images.back && images.back.file_data) && <img alt={`back of ${idType}`} src={images.back.file_data} />}
                        <ImageUpload setImage={(image) => setImages({...images, back: image})}>
                            <Button
                                color="green"
                                className="hover:bg-transparent w-full"
                                buttonType={(images && images.back && !images.back.file_data) ? "outline" : "link"}
                                size="lg"
                                ripple="dark"
                                onClick={() => setError({})}
                            >
                                {(images && images.back && !images.back.file_data) ? "Upload file" : images.back.file_name}
                                {(images && images.back && !images.back.file_data) && <Icon name="upload" size="sm" />}
                            </Button> 
                        </ImageUpload>
                        {(images && images.back && !images.back.file_data) && <p className="text-gray-500 text-xs text-left">Upload a color photo of the entire back of your {idType}. Screenshots are not allowed. JPG, JPEG or PNG formats only.</p>}
                        {error && error.back && <p className="text-red text-left my-4">{error.back}</p>}
                    </CardBody>
                    <CardFooter className="text-left">
                    </CardFooter>
                </Card>
                <div className="h-5" />
                <Button
                    color="green"
                    
                    className="hover:bg-green hover:text-black w-full hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onContinueClick}
                >
                    Continue
                    <Icon name="arrow_forward" size="sm" />
                </Button> 
            </div>
            {showCameraModal && <Modal 
                showModal={showCameraModal}
                setShowModal={setShowCameraModal}
                title={`Take a photo of the ${activeCameraSide} of your ${idType}`}
                size="regular"
            >
                {images && images[activeCameraSide] && !images[activeCameraSide].file_name ? (
                    <>
                        <Paragraph color="white">
                            Taking a clear photograph info goes here...
                        </Paragraph>
                        <Webcam 
                            ref={webcamRef}
                        />
                        <div className="h-5" />
                        <Button 
                            onClick={() => {
                                capture(activeCameraSide);
                            }} 
                            color="green" 
                            className="inline float-right border-primary text-black" 
                            buttonType="fill"
                        >
                            Take photo
                        </Button>
                    </>
                ) : (
                    <>
                        <img alt="your_selfie" src={images && images[activeCameraSide] && images[activeCameraSide].file_data} className="w-full h-auto" />
                        <div className="h-5" />
                        <Button 
                            color="green"
                            className="inline mr-2 float-right" 
                            buttonType="filled"
                            ripple="dark"
                            onClick={_ => setShowCameraModal(false)}
                        >
                            Use photo
                        </Button>
                        <Button 
                            color="gray"
                            className="hover:bg-transparent inline mr-2 float-right" 
                            buttonType="outline"
                            ripple="dark"
                            onClick={_ => {
                                if(activeCameraSide === "front") setImages({...images, front: {file_data: '', file_name: ''}});

                                if(activeCameraSide === "back") setImages({...images, back: {file_data: '', file_name: ''}})
                            }}
                        >
                            Retake
                        </Button>
                    </>
                )}
                
                <Button 
                    onClick={() => setShowCameraModal(false)} 
                    color="red" 
                    className="hover:bg-transparent inline mr-2 float-left border-primary" 
                    buttonType="outline"
                >
                    Cancel
                </Button>
            </Modal>}
        </>
    )
}
