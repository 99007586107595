import { useState } from 'react';

import 'assets/styles/core.css'

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Paragraph from "@material-tailwind/react/Paragraph";
import H6 from "@material-tailwind/react/Heading6";

import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import { parseQueryString } from 'utils';

import { passwordReset, passwordLinkCheck } from 'routes/userRoutes';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';


export default function ResetPassword() {
    const [success, setSuccess] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);

    const queryString = parseQueryString();

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };

    function onButtonClick() {
        if(!password && !confirmPassword){
            setErrors({password: 'Please enter a password', confirmPassword: 'Please enter a password'})
            return;
        }else {
            setErrors('')
        }

        if(!confirmPassword){
            setErrors({confirmPassword: 'Please enter a password'})
            return;
        }else {
            setErrors('')
        }

        if(!password){
            setErrors({password: 'Please enter a password'})
            return;
        }else {
            setErrors('')
        }

        if (password !== confirmPassword){
            setErrors({confirmPassword: 'Passwords need to match', password: 'Passwords need to match'});
            return;
        }else{
            setErrors('')
        }
        if (password === confirmPassword) {
            let passwordCheck = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
            if (!passwordCheck.test(password)) {
                setErrors({confirmPassword: 'Your password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and must be a minimum of eight characters long.'});
                return;
            }
        }

        try{
            passwordLinkCheck(queryString.token);
            passwordReset(queryString.id,{
                confirm_password: confirmPassword,
                password: password
            })
            setSuccess(true)
        }catch(e) {
            console.log(e)
        };
    }

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Reset Password - Venturebay</title>
            <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div className="bg-image h-screen px-3 md:px-8 flex items-center justify-center flex-col">
            
            <div className="h-2" />
            <Card className="max-w-3xl text-center bg-primary">
            {!success && (
                <>
                    <h3 className="text-white text-4xl">Reset your password?</h3>
                    <div className="h-2" />
                </>
            )}
                <div className="h-2" />
                <CardBody>
                <div className="h-2" />
                {success && (
                    <>
                    <H6 color="white">Successfully Reset Password</H6>
                    <Paragraph color="white">
                        Click the button below to continue to login screen
                    </Paragraph>
                    <Link to={'/'}>
                        <Button
                            color="green"
                            className="w-full hover:bg-green hover:text-black hover:shadow-xl hover:pr-2"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                        >
                            Continue
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                    </Link>
                </>
                )}
                {!success && (
                    <div className="max-w-3xl text-center w-full">  
                        <div className="h-3" />
                        <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                onClick={() => setErrors('')}
                                type={passwordShown ? "text" : "password"}
                                color="green"
                                placeholder="New Password"
                                size="regular"
                                error={errors?.password}
                            />
                        </div>
                        <div className="h-6" />
                        <div className="flex mb-4 text-left">
                            <Input
                                outline={false}
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                onClick={() => setErrors('')}
                                type={passwordShown ? "text" : "password"}
                                color="green"
                                placeholder="Confirm Password"
                                size="regular"
                                error={errors?.confirmPassword}
                            />
                            <div className='ml-5'>
                            <Button
                                color="green"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={true}
                                ripple="light"
                                onClick={togglePassword}
                            >
                                {!passwordShown ? 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                </svg>
                                }
                                
                            </Button>
                        </div>
                        </div>
                        
                    </div>
                )}
                </CardBody>
                {!success &&<CardFooter>
                <Button
                        color="green"
                        className="w-full hover:bg-green hover:text-black hover:shadow-xl hover:pr-2"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={onButtonClick}
                    >
                        Next
                        <Icon name="arrow_forward" size="sm" />
                    </Button>
                    <div className="h-5" />
                </CardFooter>}
            </Card>
        </div>
        </>
    );
}
