import {useState, useEffect} from 'react';

import InvestmentCard from 'components/investments/InvestmentCard';
import AddInvestmentCard from 'components/cards/AddInvestmentCard';
import Icon from '@material-tailwind/react/Icon';

import {isValidDate} from 'utils';
import {useGlobalState} from 'state-pool';

import {bitorsat} from 'utils/js';
import {format} from 'date-fns';

import {getInvestments} from 'logic/transactionsSorting';

import { Helmet } from 'react-helmet';

export default function Investments() {
    const [transactions] = useGlobalState('transactions');
    const [funds] = useGlobalState('funds');
    const [investments, setInvestments] = useState([]);
    const [hasInvestments, setHasInvestments] = useState(false);
    
    useEffect(() => {
        if(!transactions || (transactions && transactions.results && !Array.isArray(transactions.results)) || (transactions.results && Array.isArray(transactions.results) && transactions.results.length === 0)) {
            setHasInvestments(false);
    
            return;
        }

        const tempInvestments = getInvestments(transactions, funds);
        setInvestments(tempInvestments);
    }, [transactions]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Investments - Venturebay</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="bg-transparent px-3 md:px-8 h-40" />

            <div className="px-3 md:px-8 -mt-20">
                <div className="container mx-auto max-w-full">
                    {investments && Array.isArray(investments) && investments.length > 0 && (
                        <>
                            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mb-4">
                                {investments.filter(value => {
                                    
                                    if (value?.fund) {
                                        return value;
                                    }
                                    return
                                } ).map(({currentTotal, fundName, lastInteracted, hodlUntil, fund, transactions, pending}, i) => (
                                    <InvestmentCard
                                        key={i}
                                        color="orange"
                                        icon="bitcoin"
                                        title={fund.name}
                                        amount={bitorsat(currentTotal)}
                                        percentage={1 < 0 ? `${Math.abs(1).toFixed(2)}%` : `${(1).toFixed(2)}%`}
                                        percentageIcon={1 > 0 ? "arrow_upward" : "arrow_downward"}
                                        percentageColor={1 > 0 ? "green" : "red"}
                                        hodlUntil={
                                            new Date(hodlUntil) > new Date() ? hodlUntil : ''
                                            // format(new Date(hodlUntil), 'dd-MM-yyyy HH:mm')
                                        }
                                        lastInteracted={lastInteracted && isValidDate(new Date(lastInteracted)) && format(new Date(lastInteracted), 'dd-MM-yyyy HH:mm')}
                                        fund={fund}
                                        transactions={transactions}
                                        pending={pending}
                                    />
                                ))}
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-0 mb-4">
                                <AddInvestmentCard />
                            </div>
                        </>
                    )}
                    {(investments && Array.isArray(investments) && investments.length === 0) || !investments && (
                        <div className="h-96 w-full flex flex-col items-center justify-center">
                            <Icon name="error_outline" size="9xl" color="white" />
                            <div className="h-5" />
                            <h2 className="text-white">You have not made any investments yet</h2>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
