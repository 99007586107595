import Main from 'components/support/Main';
import { Helmet } from 'react-helmet';

export default function Support() {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Support - Venturebay</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="bg-transparent px-3 md:px-8 h-24" />

            <div className="px-3 md:px-8 -mt-20">
                <div className="container mx-auto max-w-full">
                    <Main />
                </div>
            </div>
        </>
    );
}
