import {useState} from 'react';

import {useHistory} from 'react-router-dom';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';
import Input from '@material-tailwind/react/Input';
import { Textarea } from '@material-tailwind/react';

import SvgLogo from 'assets/svg-icons/SvgLogo';

export default function Stage2({firstname, setFirstname, lastname, setLastname, idNumber, setIdNumber, dob, setDob, address, setAddress }) {
    const [error, setError] = useState({});
    const history = useHistory();

    function onContinueClick() {
        let tempErrors = {};

        if(!firstname) {
            tempErrors.firstname = 'Please enter your first name';
        }

        if(!lastname) {
            tempErrors.lastname = 'Please enter your last name';
        }

        if(!idNumber) {
            tempErrors.idNumber = 'Please enter your ID number';
        }

        if(idNumber && typeof idNumber === 'string' && idNumber.length !== 13) {
            tempErrors.idNumber = 'Please enter a valid ID number';
        }

        if(!address) {
            tempErrors.address = 'Please enter your address';
        }

        if(tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            setError(tempErrors);

            return;
        }

        history.push('/register/confirm-details');
    }

    function clearError(key) {
        if(key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError(tempErrors);
        }
    }
    
    return (
        <>
            <Card className="max-w-3xl text-center bg-primary">  

            <div className="w-full flex justify-center">
                <SvgLogo className="w-40 h-auto sb-noclose"/>
            </div>
            <div className="h-2" />
            <Paragraph color="white">Create your free account. You'll be investing in no time</Paragraph>

            <CardBody className="text-center">
            <div className={`${error && error.firstname ? "mb-8" : "mb-4"} text-left`}>
                <Input
                    outline={false}
                    value={firstname}
                    onChange={e => setFirstname(e.target.value)}
                    onFocus={() => clearError('firstname')}
                    type="text"
                    color="green"
                    size="regular"
                    placeholder="Legal First Name"
                    error={error && error.firstname}
                />
            </div>

            <div className={`${error && error.lastname ? "mb-8" : "mb-4"} text-left`}>
                <Input
                    outline={false}
                    value={lastname}
                    onChange={e => setLastname(e.target.value)}
                    onFocus={() => clearError('lastname')}
                    type="text"
                    color="green"
                    size="regular"
                    placeholder="Legal Last Name"
                    error={error && error.lastname}
                />
            </div>

            <div className={`${error && error.idNumber ? "mb-8" : "mb-4"} text-left`}>
                <Input
                    outline={false}
                    value={idNumber}
                    onChange={e => setIdNumber(e.target.value)}
                    onFocus={() => clearError('idNumber')}
                    type="number"
                    color="green"
                    size="regular"
                    placeholder="ID Number"
                    error={error && error.idNumber}
                />
            </div>

            <div className={`mb-4 mt-10 text-left`}>
                <Textarea
                    outline={false}
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    onFocus={() => clearError('address')}
                    type="text"
                    color="green"
                    size="regular"
                    placeholder="Address (Street Address, City, State, and ZIP Code)"
                    error={error && error.address}
                />
            </div>

            </CardBody>
                <CardFooter>
                    <div className="flex justify-center flex-col">
                        <Button
                            color="green"
                            className="hover:bg-green hover:text-white w-full hover:shadow-xl hover:pr-2"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            onClick={onContinueClick}
                        >
                            Continue
                            <Icon color="black" name="arrow_forward" size="sm" />
                        </Button>
                    </div>
                </CardFooter>
            </Card>
        </>
    );
}
