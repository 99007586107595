import React from 'react';
import ReactDOM from 'react-dom';
import dotEnv from 'dotenv';

import App from 'App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

dotEnv.config();

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorkerRegistration.register();