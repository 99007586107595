import {vbApi} from 'api/vb';

import {ImageUpload, User, PasswordReset} from 'types';

// UNUSED
export async function uploadUserImage(file: ImageUpload, id: number) {
    try {
        const res = await vbApi.post(`/user/uploadprofileimg/${id}`, file);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// DONE
export async function addUser(body: User) {
    try {
        const res = await vbApi.post(`/user/adduser`, body);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

//  TODO Is implemented on update email address - however not working
export async function editUser(body: User) {
    try {
        const res = await vbApi.post(`/user/edituser`, body);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// DONE
export async function forgotPassword(email: string) {
    try {
        const res = await vbApi.put(`/user/forgotpwd/${email}`);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// TODO
// Link doesn't get emailed and therefore can not test
export async function passwordLinkCheck(token: string) {
    try {
        const res = await vbApi.get(`/user/pwdlinkcheck/${token}`);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// TODO 1/2 DONE
// Link doesn't get emailed and therefore can not test
export async function passwordReset(id: string, body: PasswordReset) {
    try {
        const res = await vbApi.put(`/user/pwd/${id}`, body);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// Not using atm
export async function verifyAccount(token: string,id: number) {
    try {
        const res = await vbApi.get(`/user/verifyaccount/${id}/${token}`);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// 404
export async function userViewFund(id: number) {
    try {
        const res = await vbApi.get(`/funds/viewfund/${id}`);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// DONE
export async function userViewAllFunds() {
    try {
        const res = await vbApi.get(`/funds`);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// 404
export async function linkedUsers(id: number) {
    try {
        const res = await vbApi.get(`/funds/linkedusers/${id}`);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

export async function viewTransactions(id: number) {
    try {
        const res = await vbApi.get(`/user/viewtransactions`);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

export async function supportEmail(body: any) {
    try {
        const res = await vbApi.post(`/user/supportemail`, body);

        if(res && res.data) return res;

    } catch(err) {
        console.log(err);
    }
}