import { useRef, useState } from 'react';

import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';
import Popover from "@material-tailwind/react/Popover";
import PopoverContainer from "@material-tailwind/react/PopoverContainer";

import Modal from 'components/general/Modal';

import { useGlobalState } from 'state-pool';

import VideoPlayer from './VideoPlayer';

export default function ChooseDepositType() {
    const [currentUser] = useGlobalState('currentUser');
    const [numClicks, setNumClicks] = useState(1);
    const [showBitcoinModal, setShowBitcoinModal] = useState(false);
    const helpRef = useRef();
    const copiedRef = useRef();
    const [isClicked, setIsClicked] = useState(false);

    // useEffect(() => {
    //   if (!showVideoModal && playerState.isPlaying) {
    //     togglePlay()
    //   } else if (showVideoModal && !playerState.isPlaying) {
    //       togglePlay()
    //   }

    // }, [showVideoModal])
    
    
    // const videoElement = useRef(null);
    // const {
    //     playerState,
    //     togglePlay,
    //     handleOnTimeUpdate,
    //     handleVideoProgress,
    //     toggleMute,
    // } = useVideoPlayer(videoElement);

    const onKeyClick = () => {
        navigator.clipboard.writeText(currentUser && currentUser.btc_pubkey);

        if (!(numClicks % 2 === 0)) {
            setTimeout(() => copiedRef.current.click(), 999);
        }
        setNumClicks(numClicks + 1);
    };

    return (
        <>
            <div className="h-20" />
            <h3 className="font-normal text-4xl text-white">How would you like to add to add to your balance?</h3>
            <div className="h-10" />
            <p className="text-white border-l-2 border-white mb-2 pl-2">Luno is a trusted and tried Crypto exchange platform which has been established nearly 10 years ago. We work with Luno because Luno has shown to be trusted my millions of South Africans in the past and has plenty of success before. When you want to invest in our available funds, you need to exchange your money for Bitcoin on the Luno platform. Transfer it to your VentureBay wallet and get ready to invest in your future. Here is an instructional video if you need any clarification:</p>
            <div className="h-10" />

            <div className="h-10" />
            <div className="flex flex-col xl:flex-row gap-4">
                <div onClick={() => setShowBitcoinModal(true)} className={`w-full`}>
                    <div className="w-full rounded-xl p-8 shadow-2xl cursor-pointer hover:opacity-80 relative bg-150 md bg-120" style={{ background: `-webkit-linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("/images/logos/btc.svg")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', }} >
                        <div className="absolute top-5 left-5 w-96">
                            <span ref={helpRef} className="ml-2 text-gray-500">
                                <Icon className="ml-2" name="help" size="5xl" color="gray" />
                            </span>
                            <Tooltips placement="right" ref={helpRef}>
                                <TooltipsContent>Learn how to send bitcoin to your bitcoin address which you'll be able to use to invest</TooltipsContent>
                            </Tooltips>
                        </div>
                        <div className="flex items-center justify-center flex-col py-1 h-56">
                            <p className="font-bold text-xl text-white justify-self-bottom mt-auto">Bitcoin</p>
                        </div>
                    </div>


                    
                </div>
                {/* <Transak className="w-full ml-5">
                    <div className="w-full rounded-xl p-8 shadow-2xl cursor-pointer hover:opacity-80 relative bg-150 md bg-120" style={{ background: `-webkit-linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("/images/flags/rsa.jpg")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', }} >
                        <div className="absolute top-5 left-5 w-96">
                            <span ref={helpRef} className="ml-2 text-gray-500">
                                <Icon className="ml-2" name="help" size="5xl" color="gray" />
                            </span>
                            <Tooltips placement="right" ref={helpRef}>
                                <TooltipsContent>Add to your balance by depositing in Rand</TooltipsContent>
                            </Tooltips>
                        </div>
                        <div className="flex items-center justify-center flex-col py-1 h-56">
                            <p className="font-bold text-xl text-white justify-self-bottom mt-auto">South African Rand</p>
                        </div>
                    </div>
                </Transak> */}
                <div className='w-full'>
                    <div 
                        className="w-full rounded-xl p-8 shadow-2xl cursor-pointer hover:opacity-80 relative bg-150 md bg-120" 
                        style={{ background: `-webkit-linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("/images/logos/Luno-logo.jpg")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', }} 
                        onClick={() => setIsClicked(true)}
                    >
                            
                            <div className="absolute top-5 left-5 w-96">
                                <span className="text-gray-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-play-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                    </svg>
                                </span>
                                {/* <Tooltips placement="right" ref={helpRef}>
                                    <TooltipsContent>Add to your balance by purchasing and depositing via luno</TooltipsContent>
                                </Tooltips> */}
                            </div>
                            <div className="flex items-center justify-center flex-col py-1 h-56">
                                <p className="font-bold text-xl text-white justify-self-bottom mt-auto">Luno Tutorial</p>
                            </div>
                        </div>
                    </div>
                </div>
                

            <Modal
                showModal={showBitcoinModal}
                setShowModal={setShowBitcoinModal}
                title="Send to your Venturebay bitcoin address"
                size="regular"
            >
                <div className="grid grid-cols-1 lg:gap-5 xl:grid-cols-6">
                    <div className="xl:col-start-1 xl:col-end-7 min-h-96 w-full rounded-xl bg-primary shadow-xl mb-5 p-8">
                        <div className="xl:col-start-1 xl:col-end-4">
                            <div className="h-5" />
                            <Paragraph color="white">
                                To add to your balance, you'll need to send bitcoin to your Venturebay public key, you can do this by following the simple steps below:
                        </Paragraph>
                            <ul className="text-white font-normal">
                                <li>1. Select one of the available Deposit options</li>
                                <li>2. On your local currency wallet, select Deposit</li>
                                <li>3. Choose between the available deposit methods and follow the prompts</li>
                                <li>4. Once we receive your deposit, we’ll send you a notification so you can continue to buy crypto</li>
                            </ul>
                            {/* <span className="text-white">When making a deposit, look out for the <Icon name="help" size="3xl" color="gray" /> icon, for helpful hints and info about deposits.</span> */}
                        </div>
                        <div className="h-10"></div>
                        <div className="xl:col-start-1 xl:col-end-4">
                            <h2 className="text-white text-1xl ">
                                <span className="fw-300">Your Bitcoin public key:{" "}</span>
                                <br />
                                <span className="cursor-pointer" ref={copiedRef} onClick={onKeyClick}>{currentUser && currentUser.btc_pubkey}</span>
                                <Popover placement="top" ref={copiedRef}>
                                    <PopoverContainer className="bg-black after:text-black after:fill-black p-2 popover">
                                        Copied to clipboard
                                </PopoverContainer>
                                </Popover>
                            </h2>
                            <div className="h-10" />
                            <Paragraph color="white">
                                If you have any other questions regarding Deposits please visit our <a href="/support" className="cursor-pointer underline">FAQ</a> page for further answers
                        </Paragraph>
                        </div>
                    </div>
                </div>
            </Modal>
            {
                isClicked &&
                // <div className="video-container">
                //     <VideoPlayer />
                // </div>
                <div class="modal-window">
                    <div>
                        <a onClick={() => setIsClicked(false)} title="Close" class="modal-close">Close</a>
                        <h1>Luno Tutorial</h1>
                        <div>
                            <VideoPlayer />
                        </div>
                    </div>
                </div>
            }

        </>
    )
}