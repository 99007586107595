import Card from '@material-tailwind/react/Card';
import CardRow from '@material-tailwind/react/CardRow';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardStatus from '@material-tailwind/react/CardStatus';
import CardStatusFooter from '@material-tailwind/react/CardStatusFooter';
import Label from '@material-tailwind/react/Label';

import {format} from 'date-fns';

export default function ArticleCard({
    image,
    title,
    body,
    publishedOn,
    categories,
}) {
    return (
        <div className="px-4 mb-10">
            <Card className="hover:shadow-2xl transition cursor-pointer bg-primary" >
                <CardRow className="border-none">
                    <CardHeader color="" iconOnly className="mb-0">
                        <img className="rounded-lg" alt="article_image" src={image} />
                    </CardHeader>

                    <CardStatus className="text-white" title={publishedOn && format(new Date(publishedOn * 1000), 'd-MM-yyyy')} amount={""} />
                    <h3 className="text-white text-3xl mb-5">{title}</h3>
                </CardRow>
                <CardRow className="border-none">
                    <div className="text-white my-5" dangerouslySetInnerHTML={{__html: `${body}`}} />
                </CardRow>
                <CardStatusFooter
                    amount={
                        <div className="flex">
                            {categories.split("|").map(c => (
                                    <Label color="gray">{c}</Label>
                            ))}
                        </div>
                    }
                    color={"white"}
                    date={""}
                />
            </Card>
        </div>
    );
}
