import {
    useState,
    useEffect
} from 'react';

import axios from 'axios';

import ArticleCard from 'components/cards/ArticleCard';
import Button from '@material-tailwind/react/Button';

import {useGlobalState} from 'state-pool';
import { useOffline } from 'hooks/useOffline';

const INITIAL_LIMIT = 8;

export default function Articles() {
    const [articles, setArticles] = useGlobalState('articles');
    const [limit, setLimit] = useState(INITIAL_LIMIT);

    const {isOnline} = useOffline();

    useEffect(() => {
        const online = isOnline();

        if(online) {
            axios.get('https://min-api.cryptocompare.com/data/v2/news/?lang=EN&api_key=6e951e047ce125140cade505e8194efa1482cc09a8c3609df397c1b72926d8d2')
            .then(res => {
                if(res && res.data && res.data.Data) setArticles(res.data.Data);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="bg-transparent px-3 md:px-8 h-40" />

            <div className="px-3 md:px-8 -mt-20">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 mb-4">
                        {articles && Array.isArray(articles) && articles.length > 0 && articles.slice(0, limit).map((article, i) => (
                            <ArticleCard 
                                key={i}
                                image={article.imageurl}
                                title={article.title}
                                body={article.body}
                                categories={article.categories}
                                publishedOn={article.published_on}
                            />
                        ))}
                    </div>
                    <div className="w-full flex justify-center">
                        <Button
                            onClick={() => setLimit(limit + 8)}
                            color=""
                            className="border-white text-white w-1/2 hover:bg-white hover:text-black"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                        >
                            View More
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
