import { useState } from 'react';

import Checkbox from "@material-tailwind/react/Checkbox";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import { useHistory } from 'react-router-dom';

export default function BitcoinAddress() {
    // const [country, setCountry] = useState('');
    const [addressName, setAddressName] = useState('');
    const [btcAddress, setBtcAddress] = useState('');
    const [acknowledgeCheck, setAcknowledgeCheck] = useState(false);
    const [error, setError] = useState({});
    const history = useHistory();

    function clearError(key) {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError(tempErrors);
        }
    }
    
    function onDoneClick() {
        let tempErrors = {};
        if (!addressName) {
            tempErrors.addressName = 'Please enter your address name';
        }
        if (!btcAddress) {
            tempErrors.btcAddress = 'Please enter your Bitcoin address';
        }
        if (tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            setError(tempErrors);

            return;
        }
        console.log('done');
    }

    return (
        <div className="h-screen px-3 md:px-8 flex relative justify-center">
            <div className=" absolute left-1 top-5 md:top-3">
                <Button
                    color="white"
                    rounded
                    className="hover:pl-3 text-white"
                    buttonType="link"
                    size="lg"
                    ripple="dark"
                    onClick={() => history.goBack()}
                >
                    <Icon name="arrow_back" size="sm" />
                    Back
                </Button>
            </div>
            <div className="mt-20">
                <h3 className="font-normal text-4xl text-white">Bitcoin address</h3>
                <div className="h-2" />
                <Paragraph color="white">
                    Enter your bitcoin address below
                </Paragraph>
                <Card className="max-w-3xl text-left bg-primary">
                    <div className="h-2" />
                    <CardBody>
                        <div className={`${error && error.addressName ? "mb-8" : "mb-4"} text-left`}>
                            <Input
                                outline={false}
                                value={addressName}
                                onChange={e => setAddressName(e.target.value)}
                                type="text"
                                color="green"
                                placeholder="Address name"
                                size="regular"
                                error={error && error.addressName}
                                onFocus={() => clearError('addressName')}
                            />
                        </div>

                        <div className={`${error && error.btcAddress ? "mb-8" : "mb-4"} text-left`}>
                            <Input
                                outline={false}
                                value={btcAddress}
                                onChange={e => setBtcAddress(e.target.value)}
                                type="text"
                                color="green"
                                placeholder="Bitcoin address"
                                size="regular"
                                error={error && error.btcAddress}
                                onFocus={() => clearError('btcAddress')}
                            />
                        </div>

                    </CardBody>
                    <CardFooter>
                        <Button
                            color="green"
                            disabled={!acknowledgeCheck}
                            className={`${!acknowledgeCheck ? "cursor-not-allowed hover:bg-transparent" : "hover:bg-green hover:text-white hover:shadow-xl hover:pr-2"} w-full`}
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            onClick={onDoneClick}
                        >
                            Done
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                        <div className="h-5" />
                        <Paragraph color="white">
                            By withdrawing, I acknowledge that Venturebay is not responsible for any coins sent to the wrong address.
                        </Paragraph>
                        <Paragraph color="white">
                            I am the sole owner and operator of this Bitcoin address. Bitcoin sent to 3rd party addresses won't be processed.
                        </Paragraph>
                        <Paragraph color="white">
                            I understand that I will not be able to withdraw from my account to this Bitcoin address for 24 hours after updating it.
                        </Paragraph>
                        <Checkbox
                            color="white"
                            text="I acknowledge"
                            id={`checkbox-btc`}
                            className="flex-shrink-0"
                            onClick={() => setAcknowledgeCheck(!acknowledgeCheck)}
                        />
                    </CardFooter>
                </Card>
                <div className="h-10" />
            </div>
        </div>
    );
}
