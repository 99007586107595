import { useState, useEffect } from 'react';

import Modal from 'components/general/Modal';
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import { useGlobalState } from 'state-pool';

import { fundToUserTransaction } from 'routes/transactionRoutes';

import { useGlobal } from 'hooks/useGlobalState';

import {displayAlertModal} from 'utils';

export default function WithdrawFromFundToWalletModal({show, setShow, fund, currentAmount}) {
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');
    const [currentUser] = useGlobalState('currentUser');
    const {refresh} = useGlobal();

    async function onContinueClick() {
        if(!amount) {
            setError('Please enter the amount you would like to withdraw to continue.');

            return;
        }

        if(amount && Number(amount) <= 0) {
            setError('Please enter a valid amount to continue.');

            return;
        }

        if ((Number(amount) * 100000000) > currentUser.balance_wallet){
            setError('Insufficient funds')

            return;
        }

        const body = {
            user_id: currentUser && currentUser.id,
            funds_id: fund && fund.id,
            funds_amount: Number(amount) * 100000000
        };

        if(error !== '') {
            return
        }

        fundToUserTransaction(body).then((res) => {
            if(!res) { 
                setShow(false);

                displayAlertModal('Something went wrong, please try again later.');

                return;
            }
            
            if(res) {
                refresh('/user/viewtransactions', 'transactions');

                setShow(false);

                return;
            }
        });
    }

    useEffect(() => {
        try {
            if((Number(amount) * 100000000) > Number(currentAmount.props.text.split(' ')[0])){
                setError('Can\'t withdraw more than what is in the fund');

                return;
            } else {
                setError('')
            }
        } catch (e) {
            
        }
        
    }, [amount])

    return (
        <Modal 
            showModal={show}
            setShowModal={setShow}
            // title={`Withdraw from fund - ${fund && fund.name} to your wallet`}
            size="lg"
        >   
            <div className="p-5">
                <h3 className="font-normal text-4xl text-white">How much would you like to withdraw?</h3>
                <div className="h-5" />

                <div className="mb-4 text-left flex">
                    <span className="mt-2 mr-4 text-white font-normal text-2xl">BTC</span>
                    <Input
                        outline={false}
                        value={amount}
                        onChange={e => setAmount(e.target.value)}
                        onFocus={() => setError('')}
                        error={error}
                        color="gray"
                        placeholder="Amount"
                        size="large"
                        type="number"
                        min={1}
                    />
                </div>
                <div className="h-5" />
                <Button
                    color="green"
                    className="hover:bg-green hover:text-white w-full hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onContinueClick}
                >
                    Withdraw
                    <Icon name="arrow_forward" size="sm" />
                </Button> 
            </div>
        </Modal>
    )
}
