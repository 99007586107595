import Alert from "@material-tailwind/react/ClosingAlert";
import Icon from "@material-tailwind/react/Icon";

import {useAlert} from 'hooks/useAlert';

const AlertComponent = () => {
    const {
        showAlert,
        alertMessage,
        alertTitle,
        alertType
    } = useAlert();

    const alertIcons = {
        info: "info",
        success: "check_circle",
        warning: "warning",
        danger: "error"
    }

    const colors = {
        info: "lightBlue",
        success: "green",
        warning: "orange",
        danger: "red"
    }

    return (
        <>
            {showAlert && (
                <Alert style={style} color={colors[alertType]}>
                    <Icon name={alertIcons[alertType]} color="white" size="xl" className="mr-2" />
                    <span>
                        <b>{alertTitle}</b> {alertMessage}
                    </span>
                </Alert>
            )}
        </>
    )
}

const style = {
    position: "fixed", 
    bottom: "-1em", 
    width: "100%", 
    zIndex: 9999
}

export default AlertComponent;