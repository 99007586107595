import {useRef} from 'react';

import {vbApi} from 'api/vb';
import {useAlert} from './useAlert';

import { useLocalStorage, useEventListener } from 'usehooks-ts';

type RequestType = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface QueueItem {
    type: RequestType,
    path: string,
    body?: any
};

export const useOffline = () => {
    const [networkQueue, setNetworkQueue] = useLocalStorage<QueueItem[]>(`network-queue`, []);
    const isPosting = useRef<Boolean>(false);
    
    const {newAlert} = useAlert();

    useEventListener('online', () => {
        if(networkQueue && networkQueue.length > 0) {
            postQueue();
        } else {
            newAlert('success', 'Connection restored', `Your device has restored network connection.`);
        }
    });

    useEventListener('offline', () => {
        const errorOfflineDescription = window.innerWidth < 767 ? 
        `You are currently in offline mode` :
        `You are currently in offline mode, any changes made will only be reflected when connection is restored`;

        newAlert('danger', 'No network connection', errorOfflineDescription);
    });

    function addToQueue(type: RequestType, path: string, body?: any) {
        //adds to request to network queue with request type, body & path
        const newQueueItem = {
            type,
            body,
            path
        };

        setNetworkQueue([...networkQueue, newQueueItem]);
    }

    function postQueue() {
        //maps through network queue and attempts to post/get each request
        //only executes once there is connection

        if(networkQueue && Array.isArray(networkQueue) && networkQueue.length > 0 && !isPosting.current) {
            const length = networkQueue.length;
            let index = 0;
            networkQueue.map(async ({path, type, body}: QueueItem, i: number) => {
                try {
                    await vbApi({
                        method: type,
                        url: path,
                        data: body && body,
                    });

                    index++;
                    newAlert('success', 'Connection restored', `Network connection has been restored, syncing data. ${((index / length) * 100).toFixed()}%`);

                    if(index === length) setNetworkQueue([]);
                } catch(err) {
                    console.log(err);
                }
            });
        }
    }

    function isOnline() {
        return navigator.onLine;
    }

    return {
        addToQueue,
        postQueue,
        isOnline
    }
}


