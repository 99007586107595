import Icon from '@material-tailwind/react/Icon';

import Card from 'components/cards/Card';

export default function TransactionCategories({selected, setSelected}) {
    return (
        <div className="flex flex-col lg:flex-row px-4 gap-4">
            <div className="md:w-full" onClick={() => setSelected('all')}>
                <Card className={selected === "all" ? "bg-primary shadow-2xl cursor-pointer border-green border-2" : "bg-green-500 shadow-2xl cursor-pointer border-primary border-2"}>
                    <div className="flex items-center h-full">
                        <Icon name="star" size="3xl" color={selected === "all" ? "yellow" : "black"}/>
                        <h3 className={`ml-4 ${selected === "all" ? "text-green" : "text-black"}`}>All</h3>
                    </div>
                </Card>
            </div>
            <div className="md:w-full" onClick={() => setSelected('pending')}>
                <Card className={selected === "pending" ? "bg-primary shadow-2xl cursor-pointer border-green border-2" : "bg-green-500 shadow-2xl cursor-pointer border-primary border-2"}>
                    <div className="flex items-center h-full">
                        <Icon name="hourglass_empty" size="3xl" color={selected === "pending" ? "yellow" : "black"}/>
                        <h3 className={`ml-4 ${selected === "pending" ? "text-green" : "text-black"}`}>Pending</h3>
                    </div>
                </Card>
            </div>
            <div className="md:w-full" onClick={() => setSelected('success')}>
                <Card className={selected === "success" ? "bg-primary shadow-2xl cursor-pointer border-green border-2" : "bg-green-500 shadow-2xl cursor-pointer border-primary border-2"}>
                    <div className="flex items-center h-full">
                        <Icon name="check_circle" size="3xl" color={selected === "success" ? "yellow" : "black"}/>
                        <h3 className={`ml-4 ${selected === "success" ? "text-green" : "text-black"}`}>Successfull</h3>
                    </div>
                </Card>
            </div>
        </div>
    )
}
