import { useEffect, useState } from 'react';

type alertType = 'info' | 'success' | 'warning' | 'danger';

export const useAlert = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertType, setAlertType] = useState<alertType>('info');

    useEffect(() => {
        // @ts-ignore
        window.addEventListener('display-alert', (e: CustomEvent) => {
            setShowAlert(true);
            setAlertTitle(e.detail.alertTitle);
            if (typeof e.detail.alertMessage === "string") {
                setAlertMessage(e.detail.alertMessage);
            } else if (e.detail.alertMessage && typeof e.detail.alertMessage === 'object' && e.detail.alertMessage.message !== 'Internal server error') {
                setAlertMessage(e.detail.alertMessage.message);
            } else {
                setAlertMessage(`Something went wrong and we're not sure what to do about it. Please try again and contact us if the error persists.`);
            }

            setAlertType(e.detail.alertType);
        });

    }, []);

    function newAlert(type: alertType, title: string, message: string) {
    
        const event = new CustomEvent('display-alert', { 
            detail: {
                alertTitle: title,
                alertMessage: message,
                alertType: type,
            }
        });
    
        window.dispatchEvent(event);
    }

    return {
        newAlert,
        showAlert,
        alertMessage,
        alertTitle,
        alertType
    }
}


