import WhatNext from 'components/dashboard/WhatNext';
import Options from 'components/dashboard/Options';
import DepositNow from 'components/dashboard/DepositNow';
import BalanceInfo from 'components/dashboard/BalanceInfo';
import BtcChart from  'components/charts/BtcChart';

import {useGlobalState} from 'state-pool';

import { Helmet } from 'react-helmet';

export default function Dashboard() {
    const [currentUser] = useGlobalState('currentUser');

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard - Venturebay</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="bg-gray-900 px-3 md:px-8 h-40" />

            <div className="px-3 md:px-8 -mt-32">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 lg:gap-5 xl:grid-cols-6">
                        <div className="xl:col-start-1 xl:col-end-7">
                            {currentUser && (currentUser.kyc_current !== 3) && (
                                <>
                                    <WhatNext level={currentUser.kyc_current} />
                                    <div className="h-5" />
                                </>
                            )}
                        </div>
                        <div className="xl:col-start-1 xl:col-end-4 mb-14">
                            <BalanceInfo />
                            <div className="h-5" />
                            {currentUser && currentUser.balance_wallet === 0 && (
                                <>
                                    <DepositNow />
                                    <div className="h-5" />
                                </>
                            )}

                            <Options />
                        </div>
                        <div className="xl:col-start-4 xl:col-end-7 mb-14">
                            <BtcChart />
                        </div>
                        <div className="xl:col-start-1 xl:col-end-3">
                            {/* {currentUser && currentUser.balance_wallet === 0 && (
                                <>
                                    <DepositNow />
                                    <div className="h-5" />
                                </>
                            )}

                            <Options /> */}
                        </div>
                        <div className="xl:col-start-3 xl:col-end-7">
                            {/* <BtcChart /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}