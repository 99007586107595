import {useState} from 'react';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import DropdownInput from 'components/inputs/DropdownInput';

const BANK_OPTIONS = [
    {
        label: "FNB",
        value: "fnb"
    },
    {
        label: "Nedbank",
        value: "nedbank"
    },
    {
        label: "Standard Bank",
        value: "standard-bank"
    },
    {
        label: "Capitec",
        value: "capitec"
    },
    {
        label: "Absa",
        value: "absa"
    },
    {
        label: "Investec",
        value: "investec"
    },
    {
        label: "Discovery",
        value: "discovery"
    },
    {
        label: "African Bank",
        value: "african-bank"
    },
];

export default function BankDetails({stage, setStage}) {
    const [error, setError] = useState({});
    const [accountNumber, setAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [accountType, setAccountType] = useState('');
    const [branchCode, setBranchCode] = useState('');

    function onDoneClick() {
        let tempErrors = {};

        if (!accountNumber) {
            tempErrors.accountNumber = 'Please enter your account number';
        }
        if (!bankName) {
            tempErrors.bankName = 'Please select your bank name';
        }
        if (!accountType) {
            tempErrors.accountType = 'Please enter your account type';
        }
        if (!branchCode) {
            tempErrors.branchCode = 'Please enter your branch code';
        }
        if (tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            setError(tempErrors);

            return;
        }

        setStage(stage + 1);
    }

    function clearError(key) {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError(tempErrors);
        }
    }

    return (
        <div className="h-screen px-3 md:px-8 flex relative justify-center">
            <div className="mt-12">
                <h3 className="font-normal text-4xl text-white">Bank details</h3>
                <div className="h-2" />
                <Paragraph color="white">
                Enter your banking details below
                </Paragraph>
                <div className="h-10" />
                <Card className="max-w-3xl text-left bg-primary">  
                    <div className="h-2" />
                    <CardBody>
                        <div className={`${error && error.accountNumber ? "mb-8" : "mb-4"} text-left`}>
                            <Input
                                outline={false}
                                value={accountNumber}
                                onChange={e => setAccountNumber(e.target.value)}
                                type="text"
                                color="cream"
                                placeholder="Account number"
                                size="regular"
                                error={error && error.accountNumber}
                                onFocus={() => clearError('accountNumber')}
                            />
                        </div>

                        <div className={`${error && error.bankName ? "mb-8" : "mb-4"} text-left`}>
                            <DropdownInput 
                                value={bankName} 
                                setValue={setBankName}
                                color="gray"
                                placeholder="Bank name" 
                                options={BANK_OPTIONS}
                                error={error && error.bankName}
                                onFocus={() => clearError('bankName')}
                            />
                        </div>

                        <div className={`${error && error.accountType ? "mb-8" : "mb-4"} text-left`}>
                            <Input
                                outline={false}
                                value={accountType}
                                onChange={e => setAccountType(e.target.value)}
                                type="text"
                                color="cream"
                                placeholder="Account type"
                                size="regular"
                                error={error && error.accountType}
                                onFocus={() => clearError('accountType')}
                            />
                        </div>

                        <div className={`${error && error.branchCode ? "mb-8" : "mb-4"} text-left`}>
                            <Input
                                outline={false}
                                value={branchCode}
                                onChange={e => setBranchCode(e.target.value)}
                                type="number"
                                color="cream"
                                placeholder="Branch code"
                                size="regular"
                                error={error && error.branchCode}
                                onFocus={() => clearError('branchCode')}
                            />
                        </div>

                    </CardBody>
                    <CardFooter>
                        <Button
                            color="green"
                            className="w-full hover:bg-green hover:text-white hover:pr-2"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            onClick={onDoneClick}
                        >
                            Done
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                        <div className="h-5" />
                        <Paragraph color="white">
                            It is required that the account be registered in your name.
                        </Paragraph>
                        <Paragraph color="white">
                            A 24hr waiting period applies after updating your banking details, before you are able to withdraw funds.
                        </Paragraph>
                        <Paragraph color="white">
                            Withdrawals to third party accounts are strictly prohibited.
                        </Paragraph>
                    </CardFooter>
                </Card>
                <div className="h-10" />
            </div>
        </div>
    );
}
