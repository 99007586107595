import {useEffect} from 'react';

import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";

export default function CustomModal({showModal, setShowModal, title, children, size, onClose, isTransparent=false}) {
    
    useEffect(() => {
        const modalEls = document.querySelectorAll('.bg-white');

        modalEls.forEach(el => {
            if(el) el.classList.add('bg-primary')
        });
    }, []);

    return (
        <Modal id="test" size={size} active={showModal} toggler={onClose ? onClose : () => setShowModal(false)}>
            {title && (
                <ModalHeader toggler={onClose ? onClose : () => setShowModal(false)}>
                    <div className="text-white text-2xl font-bold mt-0 mb-0">{title}</div>
                </ModalHeader>
            )}
            <ModalBody color="white">
                {children}
            </ModalBody>
        </Modal>
    );
}

//<h5 class="text-white text-2xl font-bold mt-0 mb-0">{title}</h5>