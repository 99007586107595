import {useRef} from 'react';
import {Link} from 'react-router-dom';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Tooltips from '@material-tailwind/react/Tooltips';
import TooltipsContent from '@material-tailwind/react/TooltipsContent';

import { Heading3 } from '@material-tailwind/react';

export default function ProfileDetails() {
    const helpRef = useRef();

    return (
        <>
            <div className="h-5" />
            <Heading3 color="white">Account Details</Heading3>
            <div className="h-2" />
            {/* TODO: may enable below at future stage */}
            {/* <Link to="/profile/banking-details">
                <Card className="mb-2 transition cursor-pointer group bg-primary">
                    <CardBody>
                        <div className="relative h-5">
                            <div className="inline left">
                                <span className="text-sm text-gray-900 font-normal text-white">Banking details</span>
                            </div>
                            <Button
                                color=""
                                className="inline float-right -mt-1 group-hover:pl-4"
                                buttonType="link"
                                size="sm"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => {}}
                            >
                                    <Icon name="arrow_forward" size="2xl" color="white" />
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Link> */}
            {/* <Link to="/profile/bitcoin-address">
                <Card className="mb-2 transition cursor-pointer group bg-primary">
                    <CardBody>
                        <div className="relative h-5">
                            <div className="inline left">
                                <span className="text-sm text-gray-900 font-normal text-white">Bitcoin address</span>
                            </div>
                            <Button
                                color=""
                                className="inline float-right -mt-1 group-hover:pl-4"
                                buttonType="link"
                                size="sm"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => {}}
                            >
                                    <Icon name="arrow_forward" size="2xl" color="white" />
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Link> */}
            <Link to="/profile/update-email">
                <Card className="mb-2 transition cursor-pointer group bg-primary">
                    <CardBody>
                        <div className="relative h-5">
                            <div className="inline left">
                                <span className="text-sm text-gray-900 font-normal text-white">Update email address</span>
                            </div>
                            <Button
                                color=""
                                className="inline float-right -mt-1 group-hover:pl-4"
                                buttonType="link"
                                size="sm"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => {}}
                            >
                                    <Icon name="arrow_forward" size="2xl" color="white" />
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Link>
            <Link to="/profile/change-password">
                <Card className="mb-2 transition cursor-pointer group bg-primary">
                    <CardBody>
                        <div className="relative h-5">
                            <div className="inline left">
                                <span className="text-sm text-gray-900 font-normal text-white">Change password</span>
                            </div>
                            <Button
                                color=""
                                className="inline float-right -mt-1 group-hover:pl-4"
                                buttonType="link"
                                size="sm"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => {}}
                            >
                                    <Icon name="arrow_forward" size="2xl" color="white" />
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Link>
            <Link to="/profile/withdraw">
                <Card className="mb-2 transition cursor-pointer group bg-primary">
                    <CardBody>
                        <div className="relative h-5">
                            <div className="inline left">
                                <div className="flex">
                                    <span className="text-sm text-gray-900 font-normal text-white">Withdraw from your wallet</span>
                                    <div className="flex justify-end">
                                        <span ref={helpRef} className="ml-2 text-gray-500 h-5 cursor-pointer z-10">
                                            <Icon className="ml-2" name="help" size="xl" color="white" />
                                        </span>
                                        <Tooltips placement="right" ref={helpRef}>
                                            <TooltipsContent>You can withdraw from your wallet by sending to a bitcoin address.</TooltipsContent>
                                        </Tooltips>
                                    </div>
                                </div>
                            </div>
                            <Button
                                color=""
                                className="inline float-right -mt-1 group-hover:pl-4"
                                buttonType="link"
                                size="sm"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => {}}
                            >
                                    <Icon name="arrow_forward" size="2xl" color="white" />
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Link>
        </>
    );
}
