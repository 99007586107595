import {useState} from 'react';

import Card from 'components/cards/Card';
// import {Link} from 'react-router-dom';
import Icon from '@material-tailwind/react/Icon';

import Faq from './Faq';
import Legal from './Legal';
import Fees from './Fees';
// import ContactUs from './ContactUs';

import {useParams} from 'react-router-dom';

export default function Main() {
    const { section } = useParams();
    const [selected, setSelected] = useState(section ? section : 'faq');

    return (
        <>
            <div className="h-5" />
            <div className="flex flex-col justify-center items-center md:flex-row md:justify-between px-4">
                <div className="w-full md:w-1/3" onClick={() => setSelected('faq')}>
                    <Card className={selected === "faq" ? "bg-primary shadow-2xl cursor-pointer border-green border-2" : "bg-green-500 shadow-2xl cursor-pointer border-primary border-2"}>
                        <div className="flex items-center justify-center flex-col h-full">
                            <Icon name="live_help" color={selected === "faq" ? "yellow" : "black"} size="6xl" />
                            <h3 className={selected === "faq" ? "text-green" : "text-black" }>FAQ</h3>
                        </div>
                    </Card>
                </div>
                <div className="w-full mt-4 md:mt-0 md:w-1/3 md:pl-4" onClick={() => setSelected('fees')}>
                    <Card className={selected === "fees" ? "bg-primary shadow-2xl cursor-pointer border-green border-2" : "bg-green-500 shadow-2xl cursor-pointer border-primary border-2"}>
                        <div className="flex items-center justify-center flex-col h-full">
                            <Icon name="paid" color={selected === "fees" ? "yellow" : "black"} size="6xl" />
                            <h3 className={selected === "fees" ? "text-green" : "text-black"}>Fees</h3>
                        </div>
                    </Card>
                </div>
                <div className="w-full mt-4 md:mt-0 md:w-1/3 md:pl-4" onClick={() => setSelected('legal')}>
                    <Card className={selected === "legal" ? "bg-primary shadow-2xl cursor-pointer border-green border-2" : "bg-green-500 shadow-2xl cursor-pointer border-primary border-2"}>
                        <div className="flex items-center justify-center flex-col h-full">
                            <Icon name="language" color={selected === "legal" ? "yellow" : "black"} size="6xl" />
                            <h3 className={selected === "legal" ? "text-green" : "text-black"}>Legal</h3>
                        </div>
                    </Card>
                </div>
            </div>
            <div className="h-10" />
            <div className="grid grid-cols-1">
                <div className="px-4 mb-5">
                    {selected === 'faq' && <Faq />}
                    {selected === 'fees' && <Fees />}
                    {selected === 'legal' && <Legal />}
                </div>
                {/* <div className="xl:col-start-5 xl:col-end-7 px-4 mb-5">
                    <ContactUs />
                </div> */}
            </div>
        </>
    );
}
