import { useEffect, useState } from 'react';

// routing
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

//PWA components
import UpdateAvailable from 'components/pwa/updatePwa/UpdateAvailable';

// pages
import Dashboard from 'pages/Dashboard';
import NotFound from 'pages/404';
import Login from 'pages/Login';
import Register from 'pages/Register';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import Profile from 'pages/Profile';
import Transactions from 'pages/Transactions';
import Investments from 'pages/Investments';
import Verify from 'pages/Verify';
import Funds from 'pages/Funds';
import Deposit from 'pages/Deposit';
import News from 'pages/News';
import Support from 'pages/Support';
import Withdraw from 'pages/Withdraw';
import NewInvestment from 'pages/NewInvestment';
import LoginAs from 'pages/LoginAs';

// ui components
import Alert from 'components/general/Alert';
// import Footer from 'components/Footer';
import Sidebar from 'components/layout/Sidebar';

// profile components (forms)
import BitcoinAddress from 'components/profile/BitcoinAddress';
import UpdateEmail from 'components/profile/UpdateEmail';
import ChangePassword from 'components/profile/ChangePassword';
import BankDetails from 'components/profile/BankDetails';

// manage global state hook
import { useGlobal } from 'hooks/useGlobalState';
// import {useGlobalState} from 'state-pool';

import { useAuth } from 'hooks/useAuth';

// utility functions
import { requestNotificationPermission, isIos } from 'utils';

// scrolls to top when page changes
import ScrollToTop from 'components/general/ScrollToTop';

// auth guard
import PrivateRoute from 'components/routing/PrivateRoute';

import ShowSideBarController from 'components/general/ShowSideBarController';

// global styling
import "theme/utilities.css";
import "theme/core.css";
import "theme/fonts.css";
// Font Awesome Style Sheet
import '@fortawesome/fontawesome-free/css/all.min.css';

// Tailwind CSS Style Sheet
import 'assets/styles/tailwind.css';

import AlertModal from 'components/general/AlertModal';
import FundsDetails from 'components/funds/FundsDetails';

function App() {
    const [showSideBar, setShowSidebar] = useState(false);
    const { 
        load, 
        autoRefresh 
    } = useGlobal();
    const {currentUser} = useAuth();

    useEffect(() => {
        const ios = isIos();

        // if not ios then request permission for push notifications
        if (!ios && process.env.NODE_ENV === 'production') requestNotificationPermission();
    }, []);

    useEffect(() => {
        if(currentUser && currentUser.id) {

        // TODO BACKEND
        // loading admin funds as user route /funds doesn't return id field in funds object - which is needed for following requests....
        load(`/funds`, 'funds');
        load(`/user/viewtransactions`,'transactions');

        // set up auto refresh 
        const seconds = 30;

        autoRefresh(seconds, `/funds`, 'funds');
        autoRefresh(seconds, `/user/viewusertransactions`,'transactions');
        // load users transactions
        // load(`/transactions/${id}`, 'transactions');
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    return (
        <div>
            <UpdateAvailable />
            <Router>
                {showSideBar && <Sidebar />}
                <ScrollToTop />
                <ShowSideBarController setShowSidebar={setShowSidebar} currentUser={currentUser} />
                <div className={showSideBar ? "md:ml-64 overflow-x-hidden" : ""}>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/dashboard" />
                        </Route>
                        <Route exact path="/loginas/:adminId/:userId/:md5" component={LoginAs}/>
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/register">
                            <Redirect to="/register/email-password" />
                        </Route>
                        <Route exact path="/register/:stage" component={Register} />
                        <Route exact path="/forgot-password" component={ForgotPassword} />
                        <Route exact path="/reset-password" component={ResetPassword} />
                        <Route exact path="/news" component={News} />
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />
                        <PrivateRoute exact path="/deposit" component={Deposit} />
                        <PrivateRoute exact path="/investments" component={Investments} />
                        <PrivateRoute exact path="/investments/:id" component={Investments} />
                        <PrivateRoute exact path="/invest/new/:investmentId" component={NewInvestment} />
                        <PrivateRoute exact path="/profile" component={Profile} />
                        <PrivateRoute exact path="/profile/bitcoin-address" component={BitcoinAddress} />
                        <PrivateRoute exact path="/profile/update-email" component={UpdateEmail} />
                        <PrivateRoute exact path="/profile/change-password" component={ChangePassword} />
                        <PrivateRoute exact path="/profile/banking-details" component={BankDetails} />
                        <PrivateRoute exact path="/profile/withdraw" component={Withdraw} />
                        <Route exact path="/support">
                            <Redirect to="/support/faq" />
                        </Route>
                        <PrivateRoute exact path="/support/:section" component={Support} />
                        <PrivateRoute exact path="/transactions" component={Transactions} />
                        <Route exact path="/verify/1">
                            <Redirect to="/verify/1/id-address" />
                        </Route>
                        <Route exact path="/verify/2">
                            <Redirect to="/verify/2/expectations" />
                        </Route>
                        <Route exact path="/verify/3">
                            <Redirect to="/verify/3/expectations" />
                        </Route>
                        <PrivateRoute exact path="/verify/:level/:stage" component={Verify} />
                        <PrivateRoute exact path="/funds" component={Funds} />
                        <PrivateRoute exact path="/funds/details/:fundId" component={FundsDetails} />

                        <Route component={NotFound} />
                    </Switch>
                </div>
            </Router>
            <Alert />
            <AlertModal />
        </div>
    );
}

export default App;