import Icon from '@material-tailwind/react/Icon';

const Fin = ({amount}) => (
    <div className="text-white font-7xl h-96 flex flex-col items-center justify-center">
        <div className="w-full bg-primary rounded-xl overflow-hdden py-16 px-8 shadow-2xl cursor-pointer flex h-72 flex-col items-center justify-center">
            <Icon name="check_circle_outline" size="7xl" />
            <div className="h-8 w-full" />
            <h3 className="text-2xl text-white">Your payment is being processed</h3>
            <div className="h-4 w-full" />
            <p className="text-md text-white">{amount} BTC has been deducted from your balance and will reflect in your account/address in 5-7 workin days.</p>
        </div>
    </div>
);

export default Fin;