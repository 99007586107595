import {useRef} from 'react';

import Card from 'components/cards/Card';
import Icon from '@material-tailwind/react/Icon';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";

import {Link} from 'react-router-dom';
import {useGlobalState} from 'state-pool';

import { Button } from '@material-tailwind/react';

export default function WhatNext({level}) {
    const [currentUser] = useGlobalState('currentUser');
    const helpRef = useRef();

    const TITLES = {
        0: 'Your account is unverified',
        1: 'You are level 1 verified',
        2: 'You are level 2 verified',
        3: 'Your account is fully verified',
    };

    const DESCRIPTIONS = {
        0: 'The next step is to submit your ID number to get your verification status to level 1',
        1: 'The next step is to provide photos of your identity document to get your verification status to level 2',
        2: 'The next step is to provide your proof of residence to get your verification status to level 3',
        3: 'Your account is fully verified',
    };


    return (
        <Card className="bg-gradient-to-tr from-gray-500 to-gray-700 shadow-2xl overflow-visible">
            <div className="relative flex flex-col md:flex-row items-center h-auto text-left justify-between">
                <div>
                    <div className="flex">
                        <h3 className="text-white text-2xl md:text-4xl font-bold">{TITLES[level]}</h3>
                        <div className="flex justify-end">
                            <span ref={helpRef} className="ml-2 text-gray-500 h-5 cursor-pointer z-10">
                                <Icon className="ml-2" name="help" size="4xl" color="white" />
                            </span>
                            <Tooltips placement="right" ref={helpRef}>
                                <TooltipsContent>Your verification level determines what you can and can't do on the system. We will ask you for proof of ID and residence depending on what level of verification you have. There are 3 stages of verification.</TooltipsContent>
                            </Tooltips>
                        </div>
                    </div>
                    <div className="h-2" />
                    <h6 className="text-white text-lg md:text-xl text-bold">{DESCRIPTIONS[level]}</h6>
                </div>
                <Link to={`/verify/${currentUser && currentUser.kyc_current + 1}`} className="ml-auto hidden md:block">
                    <Button
                        color=""
                        className="border-white text-white w-full hover:bg-white hover:text-black"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                    >
                        Verify now
                        <Icon name="arrow_forward" size="sm" />
                    </Button>
                </Link>
                <Link to={`/verify/${currentUser && currentUser.kyc_current + 1}`} className="md:hidden block w-full mt-5">
                    <Button
                        color=""
                        className="border-white text-white w-full hover:bg-white hover:text-black"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                    >
                        Verify now
                        <Icon name="arrow_forward" size="sm" />
                    </Button>
                </Link>
            </div>
        </Card>
    );
}
