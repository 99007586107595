import {useRef} from 'react';
import {
    Link, 
    // useHistory
} from 'react-router-dom';
import { bitorsat } from 'utils/js';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
// import Dropdown from '@material-tailwind/react/Dropdown';
// import DropdownItem from '@material-tailwind/react/DropdownItem';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import {useGlobalState} from 'state-pool';

import NavigationControls from 'components/buttons/NavigationControls';

export default function AdminNavbar({ showSidebar, setShowSidebar }) {
    const [currentUser] = useGlobalState('currentUser');
    const addIconInfoRef = useRef();

    // const history = useHistory();

    return (
        <nav className="bg-primary md:ml-64 py-6 px-3">
            <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-0">
                <div className="flex justify-between items-center w-full">
                    <div className="flex items-center">
                        <div className="hidden md:block"><NavigationControls /></div>
                        <h4 className="text-white text-sm tracking-wider">
                            <span className="font-normal">BALANCE</span> {currentUser && bitorsat(currentUser.balance_wallet, 'bottom')}
                        </h4>
                        <Link to="/deposit">
                            <Button
                                color="transparent"
                                buttonType="link"
                                size="lg"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => {}}
                                ref={addIconInfoRef}
                            >
                                    <Icon name="add" size="2xl" color="white" />
                            </Button>
                        </Link>
                        <Tooltips placement="top" ref={addIconInfoRef}>
                            <TooltipsContent>Click to deposit money or bitcoin into your account and add to your balance</TooltipsContent>
                        </Tooltips>
                    </div>

                    <div className="flex">
                        {/* <div className="hide-sm">
                            <NavbarInput placeholder="Search" />
                        </div> */}

                        <div className="-mr-4 ml-6 hidden md:block">
                            <Link to="/support">
                                <div className="w-10 h-10 shadow-md flex" style={{borderRadius: '50%', alignItems: 'center', justifyContent: 'center'}}>
                                    <Icon name="help" size="2xl" color="white" />
                                </div>
                            </Link>
                            {/* <Dropdown
                                color="transparent"
                                buttonText={

                                }
                                rounded
                                style={{
                                    padding: 0,
                                    color: 'transparent',
                                }}
                            >
                                <DropdownItem onClick={() => history.push("/support/faq")} color="gray" className="hover:bg-primary">
                                    FAQ
                                </DropdownItem>
                                <DropdownItem onClick={() => history.push("/support/fees")} color="gray" className="hover:bg-primary">
                                    Fees
                                </DropdownItem>
                                <DropdownItem onClick={() => history.push("/support")} color="gray" className="hover:bg-primary">
                                    Contact Us
                                </DropdownItem>
                            </Dropdown> */}
                        </div>
                    </div>
                </div>
                <div className="md:hidden">
                    <Button
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        iconOnly
                        rounded
                        ripple="light"
                        onClick={() => setShowSidebar('right-0')}
                    >
                        <Icon name="menu" size="2xl" color="white" />
                    </Button>
                    <div
                        className={`absolute top-2 md:hidden ${
                            showSidebar === 'right-0' ? 'right-59' : 'hidden'
                        } z-50 transition-all duration-300`}
                    >
                        <Button
                            color="transparent"
                            buttonType="link"
                            size="lg"
                            iconOnly
                            rounded
                            ripple="light"
                            className="text-white"
                            onClick={() => setShowSidebar('-right-64')}
                        >
                            <Icon name="close" size="2xl" color="black" />
                        </Button>
                    </div>
                </div>
            </div>
        </nav>
    );
}
