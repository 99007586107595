import {vbApi} from 'api/vb';

import {TransactionStatus, FundTransaction} from 'types';

// USER

// Invest DONE
export async function userToFundTransaction(body: FundTransaction) {
    try {
        const res = await vbApi.post(`/user/usertofundtransaction`, body);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

export async function walletToWallet(body: any){
    try {
        const res = await vbApi.post(`/user/usertopublickeytransaction`, body);

        if(res) return res;
    }catch(e){
        console.log(e);
    }   
}

// TODO: Do not have any way to get a list of funds a user has invested in to get the fund_id needed for this request
export async function fundToUserTransaction(body: FundTransaction) {
    try {
        const res = await vbApi.post(`/user/fundtousertransaction`, body);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// ADMIN
export async function transactionStatus(body: TransactionStatus) {
    try {
        const res = await vbApi.post(`/admin/funds/transactionstatus`, body);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}
