import {useRef} from 'react';

import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Link } from 'react-router-dom';
import { useGlobalState } from 'state-pool';
import { bitorsat } from 'utils/js';


export default function InsufficientFunds() {
    const [currentUser] = useGlobalState('currentUser');
    const walletRef = useRef();
    return (
        <div className="w-full text-left">
            <div className="h-10" />
            <h3 className="font-normal text-4xl text-white">Please add to your wallet to continue</h3>
            <div className="h-20" />

            <div className="flex mb-5 w-full">
                <div className={`w-full`}>
                    <div className="bg-primary border-2 border-red-500 w-full rounded-xl overflow-hidden p-4 shadow-2xl" >
                        <div className="flex items-center justify-start py-1 h-10">
                            <p className="font-bold text-xl text-white mt-0">
                                <span className="fw-300 mr-3" style={{color: "#f55244"}}>You do not have enough to invest in this fund</span>
                            </p>

                            <div className="ml-auto">
                                <span ref={walletRef} className="text-gray-500">
                                    <Icon className="ml-2" name="info" size="4xl" color="red" />
                                </span>
                                <Tooltips placement="top" ref={walletRef}>
                                    <TooltipsContent>Insufficient funds</TooltipsContent>
                                </Tooltips>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`flex mb-5 w-full`}>
                <div className={`w-full`}>
                    <div className="bg-primary w-full rounded-xl overflow-hdden p-4 shadow-2xl" >
                        <div className="flex items-center justify-start py-1 h-10">
                            <p className="font-bold text-xl text-white mt-0"><span className="fw-300 mr-3">Current balance</span>{currentUser && currentUser.balance_wallet && bitorsat(parseInt(currentUser.balance_wallet))}</p>
                            <p className="font-bold text-xl text-white mt-0 ml-auto"><span className="fw-300 mr-3">Min buy in</span> BTC 0.0005</p>
                            <div className="ml-auto">
                                <span ref={walletRef} className="text-gray-500">
                                    <Icon className="ml-2" name="help" size="4xl" color="white" />
                                </span>
                                <Tooltips placement="top" ref={walletRef}>
                                    <TooltipsContent>This is your what you currently have in our wallet and the minimal you need to invest in this fund</TooltipsContent>
                                </Tooltips>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Link
                to="/deposit"
                className="mt-2 sb-noclose"
            >
                <Button
                    color="green"
                    className="hover:bg-green hover:text-white w-full hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                >
                    Continue to disposits
                <Icon name="arrow_forward" size="sm" />
                </Button>
            </Link> 

        </div>
    );
}
