import {useState, useEffect} from 'react';

import Checkbox from "@material-tailwind/react/Checkbox";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

const OPTIONS = [
    "I have read and understand Venture Bay's Privacy Policy, Risk Disclosures and Disclaimers",
    "I understand I can only withdraw supported currencies",
    "I would like to receive Venture Bay's weekly newsletter"
];

export default function AcceptConditions({submit}) {
    const [accept, setAccept] = useState({});
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if(accept && typeof accept === 'object') {
            if(accept[0] && accept[1]) setDisabled(false);

            if(accept[0] && !accept[1]) setDisabled(true);

            if(!accept[0] && accept[1]) setDisabled(true);
        }
    }, [accept]);

    const onContinueClick = () => submit();

    function setAcceptKey(key) {
        let tempAccept =  accept;

        tempAccept[key] = !tempAccept[key];

        setAccept({...accept});
    }

    return (
        <Card className="max-w-3xl text-center bg-primary">   
            <h3 className="font-normal text-4xl text-white">Please read and accept the following to continue</h3>
            <div className="h-2" />
            <CardBody className="text-center">
            <div className="w-full text-left">
                {OPTIONS.map((o, i) => (
                    <div key={i}>
                        <Checkbox
                            color="white"
                            text={o}
                            id={`checkbox-${i}`}
                            className="flex-shrink-0"
                            value={accept && typeof accept === 'object' && accept[i]}
                            onClick={() => setAcceptKey(i)}
                        />
                        <div className="h-3" />
                    </div>
                ))}
            </div>
            </CardBody>
            <CardFooter>
                <div className="flex justify-center flex-col">
                    <Button
                        color="green"
                        className={`${!disabled ? "hover:bg-green hover:text-white hover:shadow-xl hover:pr-2" : "hover:bg-transparent cursor-not-allowed"} w-full`}
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={onContinueClick}
                        disabled={disabled}
                    >
                        Accept and finish
                        <Icon color="black" name="arrow_forward" size="sm" />
                    </Button>
                </div>
            </CardFooter>
        </Card>
    )
}