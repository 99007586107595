import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import {Link} from 'react-router-dom';

const YOU_NEED = {
    2: [
        "Your physical ID, Drivers License or Passport",
        "Be in a well lit room to take a selfie"
    ],
    3: [
        "Proof of residence, not older than 3 months",
        "PO Box addresses are not accepted"
    ],
}

export default function Expectations({level}) {
    return (
        <div className="w-full text-left">
            <div className="h-5" />
            <h3 className="font-normal text-4xl text-white">This usually takes around 5 minutes</h3>
            <div className="h-7" />
            <Card className="max-w-3xl text-left bg-primary">  
                <div className="h-2" />
                <p className="font-semibold text-white">
                    What you will need
                </p>
                <div className="h-2" />
                <CardBody>
                    {YOU_NEED[level].map((text, i) => (
                        <div key={i} className="mb-4 text-left">
                            <div className="inline-block">
                                <span className="w-7 h-7 bg-green text-black rounded-full flex items-center justify-center">
                                    {i + 1}
                                </span>
                            </div>
                            <span className="inline-block ml-2 text-white text-xs md:text-sm font-normal leading-relaxed whitespace-pre-line">
                                {text}
                            </span>
                        </div>
                    ))}
                </CardBody>
                <CardFooter>
                    <p className="font-semibold text-white mb-2">
                        Why do I need to verify my identity?
                    </p>
                    <div className="mb-0 text-left">
                        <div className="inline-block">
                            <span className="w-3 h-3 bg-green text-black rounded-full flex items-center justify-center" />
                        </div>
                        <span className="inline-block ml-2 text-white text-xs md:text-sm font-normal leading-relaxed">
                            We are obliged to comply with the law
                        </span>
                    </div>
                    <div className="mb-1 text-left">
                        <div className="inline-block">
                            <span className="w-3 h-3 bg-green text-black rounded-full flex items-center justify-center" />
                        </div>
                        <span className="inline-block ml-2 text-white text-xs md:text-sm font-normal leading-relaxed">
                            To make sure that only you can access your funds
                        </span>
                    </div>
                </CardFooter>
            </Card>
            <div className="h-5" />
            <Link to={level === "2" ? "/verify/2/id-type" : "/verify/3/proof-of-residence"}>
                <Button
                    color="green"
                    className="hover:bg-green hover:text-black w-full hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                >
                    Continue
                    <Icon name="arrow_forward" size="sm" />
                </Button> 
            </Link>
        </div>
    )
}
