import {useRef} from 'react';

import {useHistory} from 'react-router-dom';

import Card from '@material-tailwind/react/Card';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

const OPTIONS = [
    {
        text: "ID document",
        helpText: "ID"
    },
    {
        text: "Drivers License",
        helpText: "Drivers License"
    },
    {
        text: "Passport",
        helpText: "Passport"
    },
];

export default function Identification({setIdType}) {
    const helpRef = useRef();
    const history = useHistory();

    const onOptionClick = option => {
        setIdType(option);

        history.push('/verify/2/id-proof');
    }

    return (
        <>
            <div className="w-full text-left">
                <div className="h-5" />
                <h3 className="font-normal text-4xl text-white">Grab your ID, passport or Drivers License</h3>
                <Paragraph color="white">
                    You'll be required to provide a photo of your physical ID, passport or drivers license
                </Paragraph>
                <div className="h-7" />
                {OPTIONS.map(({text, helpText}, i) => (
                    <div key={i} onClick={_ => onOptionClick(text)}>
                        <Card className="mb-2 transition cursor-pointer group bg-primary">
                                <div className="flex items-center justify-start w-full h-10 px-4">

                                    <span className="text-sm font-normal text-white mr-2">{text}</span>
                                    {helpText && (
                                        <>
                                            <span ref={helpRef} className="ml-2 text-gray-500">
                                                <Icon color="gray" name="help" size="2xl" />
                                            </span>
                                            <Tooltips placement="top" ref={helpRef}>
                                                <TooltipsContent>{helpText}</TooltipsContent>
                                            </Tooltips>
                                        </>
                                    )}
                                    <Button
                                        color="white"
                                        className="text-white group-hover:pl-4 justify-self-end ml-auto"
                                        buttonType="link"
                                        size="sm"
                                        iconOnly
                                        ripple="light"
                                        onClick={() => {}}
                                    >
                                            <Icon name="arrow_forward" size="2xl" color="black" />
                                    </Button>
                                </div>
                        </Card>
                    </div>
                ))}
            </div>
        </>
    )
}
