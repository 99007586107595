import {isValidJson} from 'utils';

const getDestinationVars = (destination) => {

    if(destination && typeof destination === 'string') {
        const split = destination.split(':');

        if(split && Array.isArray(split) && split[0] && split[1]) {
            return {
                destinationId: split[1],
                type: split[0]
            }
        }
    }
    
    return false;
}

const getTransactionStatus = (transaction) => {
    if(transaction && transaction.misc && isValidJson(transaction.misc) && JSON.parse(transaction.misc).status) {
        return JSON.parse(transaction.misc).status;
    }
}

export function getInvestments(transactions, funds) {

        if(transactions.results && Array.isArray(transactions.results)) {
            let uniqueInvestements = {};

            transactions.results.forEach((transaction) => {
                const toFund = transaction.destination.includes('fund');
                const toUser = transaction.src.includes('fund');

                const destination = (transaction && toFund) ? transaction.destination : toUser ? transaction.src : '';

                const destinationVars = getDestinationVars(destination);

                const transactionStatus = getTransactionStatus(transaction);

                if(destinationVars && destinationVars.destinationId && destinationVars.type) {
                    const {type, destinationId} = destinationVars;

                    if(uniqueInvestements && typeof uniqueInvestements === 'object') {
                        
                        if(transaction && transaction.amount && uniqueInvestements[destinationId] && typeof uniqueInvestements[destinationId] === 'object' && uniqueInvestements[destinationId].amount) {
                            uniqueInvestements[destinationId] = {
                                amount: getExistingAmount(toFund, uniqueInvestements, destinationId, transaction, transactionStatus),
                                pending: getExistingPending(toFund, uniqueInvestements, destinationId, transaction, transactionStatus),
                                lastInteractedWith: getExistingLastInteractedWith(uniqueInvestements, destinationId, transaction),
                                hodlUntil: getExistingHodlUntil(uniqueInvestements, destinationId, transaction),
                                transactions: accumulateTransactions(uniqueInvestements, destinationId, transaction),
                                fundId: getFundId(uniqueInvestements, destinationId, transaction, toFund),
                                pendings: uniqueInvestements[destinationId].pendings += (' ' + transactionStatus)
                            } 
                        } else if(transaction && transaction.amount) {
                            uniqueInvestements[destinationId] = {
                                amount: getAmount(transactionStatus, Number(transaction.amount), toFund),
                                pending: getPending(transactionStatus, Number(transaction.amount)),
                                lastInteractedWith: new Date(transaction.created_at),
                                hodlUntil: transaction.hodl_until,
                                fundId: toFund && destinationId,
                                transactions: [transaction],
                                pendings: transactionStatus
                            };
                        }
                    } 
                }
            });

            const values = Object.values(uniqueInvestements);

            return values.map((investment, i) => {
                if(funds && Array.isArray(funds) && investment && typeof investment === 'object' && investment.amount) {
                    return {
                        currentTotal: investment.amount,
                        pending: investment.pending,
                        transactions: investment.transactions,
                        fundName: funds[i] && funds[i].name,
                        lastInteracted: new Date(investment.lastInteractedWith),
                        hodlUntil: investment.hodlUntil,
                        fund: funds.find(f => f.id === Number(investment.fundId)),
                        fundId: Number(investment.fundId).fundId,
                        pendings: investment.pendings
                    }
                }
            });
        }
}

function getExistingAmount(toFund, uniqueInvestements, destinationId, transaction, status) {
    if(status !== 'success') return uniqueInvestements[destinationId].amount;

    return toFund ? uniqueInvestements[destinationId].amount += Number(transaction.amount) : uniqueInvestements[destinationId].amount -= Number(transaction.amount)
}

function getAmount(status, transactionAmount, toFund) {
    if(status === 'success') return toFund ? Number(transactionAmount) : -Number(transactionAmount)

    return 0;
}

function getExistingPending(toFund, uniqueInvestements, destinationId, transaction, status) {
    if(status === 'pending') {
        return uniqueInvestements[destinationId].pending + Number(transaction.amount);
    } else {
        return uniqueInvestements[destinationId].pending;
    }
}

function getPending(status, transactionAmount) {

    if(status === 'pending') {
        return Number(transactionAmount);
    } else {
        return 0;
    }
}

function getExistingLastInteractedWith(uniqueInvestements, destinationId, transaction) {
    return new Date(transaction.created_at) > new Date(uniqueInvestements[destinationId].lastInteractedWith) ? new Date(transaction.created_at) : uniqueInvestements[destinationId].lastInteractedWith;
}

function getExistingHodlUntil(uniqueInvestements, destinationId, transaction) {
    return transaction.hodl_until ? transaction.hodl_until : uniqueInvestements[destinationId].hodlUntil;
}

function accumulateTransactions(uniqueInvestements, destinationId, transaction) {
    return [...uniqueInvestements[destinationId].transactions, transaction];
}

function getFundId(uniqueInvestements, destinationId, transaction, toFund) {
    return uniqueInvestements[destinationId].fundId ? uniqueInvestements[destinationId].fundId : toFund ? transaction.fundId : null;
}
