import {useState} from 'react';

import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";

const OPTIONS = [
    "An article",
    "On social media",
    "A podcast",
    "A search engine",
    "A friend or colleague",
    "An online video",
    "Other"
];

export default function WhereDidYouHearAboutUs() {
    const [selected, setSelected] = useState('');
    const [dropdownShow, setDropdownShow] = useState(false);
    return (
        <div className="w-full text-center">
            <Dropdown
                color="white"
                className="border-primary text-white w-full"
                placement="bottom-start"
                buttonText={!selected ? "Select an option" : selected}
                buttonType="link"
                size="regular"
                block={true}
                ripple="light"
                dropdownShow={dropdownShow}
                setDropdownShow={setDropdownShow}
            >
                {OPTIONS.map((o, i) => (
                    <DropdownItem key={i} color="white" className="hover:bg-primary" ripple="light" onClick={() => {setSelected(o); setDropdownShow(false);}}>
                        {o}
                    </DropdownItem>
                ))}
            </Dropdown>
        </div>
    )
}