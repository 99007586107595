import Card from '@material-tailwind/react/Card';
import Icon from '@material-tailwind/react/Icon';

import {Link} from 'react-router-dom';

export default function AddInvestmentCard() {
    return (
        <div className="px-4 mb-10">
            <Link to="/funds">
                <Card className="cursor-pointer w-full h-full flex justify-center items-center bg-transparent border-2 border-orange-500 hover:shadow-2xl transition text-orange">
                    <div className="flex items-center h-full">
                        <Icon name="add" size="2xl"/>
                        <h2 className="ml-1 m-0 font-semibold text-lg">{" "}New Investment</h2>
                    </div>
                </Card>
            </Link>
        </div>
    );
}
