import {Link} from 'react-router-dom';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph, Heading3 } from '@material-tailwind/react';

export default function Fin() {
    return (
        <>
            <div className="w-full text-left">
                <div className="h-5" />
                <h3 className="font-normal text-4xl text-white">Verification complete</h3>
                <div className="h-8" />
                {/* <Paragraph color="white">
                    Your submission was successfull
                </Paragraph> */}
                <Card className="max-w-3xl text-center bg-primary">  
                    <div className="h-2" />
                    <CardBody>
                        <Icon name="check_circle_outline" size="9xl" color="white" />
                        <div className="h-2" />
                        <Heading3 color="white">Thank you!</Heading3>
                        <div className="h-4" />
                        <Paragraph color="white">
                        Your submission was successfull. An admin will review your submission in the next 24 hours. We will email you the results,
                        </Paragraph>
                    </CardBody>
                    <CardFooter className="text-left">
                    </CardFooter>
                </Card>
                <div className="h-5" />
                <Link to="/dashboard">
                    <Button
                        color="green"
                        
                        className="hover:bg-green hover:text-white w-full hover:pr-2"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                    >
                        Take me to my dashboard
                        <Icon name="arrow_forward" size="sm" />
                    </Button> 
                </Link>
            </div>
        </>
    )
}
