import {
    // useState, 
    useRef
} from 'react';

import {Link} from 'react-router-dom';

import Card from 'components/cards/Card';
import Icon from '@material-tailwind/react/Icon';
// import Input from '@material-tailwind/react/Input';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import { Button } from '@material-tailwind/react';

export default function WhatNext() {
    // const [amount, setAmount] = useState('');
    const helpRef = useRef();

    return (
        <>
            {/* <div className="flex mb-10 w-full">
                <div className={`w-full`}>
                    <div className="bg-success w-full rounded-xl overflow-hidden p-4 shadow-2xl" >
                        <div className="flex items-center justify-start py-1 h-10">
                            <p className="font-bold text-md text-white mt-0">
                                <span className="fw-300 mr-3">Your wallet is empty. Add to your balance to start investing</span>
                            </p>

                            <div className="ml-auto">
                                <span ref={infoRef} className="text-gray-500">
                                    <Icon className="ml-2" name="info" size="4xl" color="white" />
                                </span>
                                <Tooltips placement="top" ref={infoRef}>
                                    <TooltipsContent>Insufficient help text</TooltipsContent>
                                </Tooltips>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Card className="bg-primary shadow-2xl relative">
                <div className="absolute top-5 right-5 w-full flex justify-end">
                    <span ref={helpRef} className="ml-2 text-gray-500 h-5 cursor-pointer z-10">
                        <Icon className="ml-2" name="help" size="4xl" color="white" />
                    </span>
                    <Tooltips placement="right" ref={helpRef}>
                        <TooltipsContent>Deposit using Luno to your Venturebay Bitcoin public key to add to your balance which can then be used to invest in one of our funds.</TooltipsContent>
                    </Tooltips>
                </div>
                <div className="relative h-auto text-left">
                    <h3 className="text-white text-4xl font-bold">Deposit now</h3>
                    <div className="h-2" />
                    <p className="font-bold text-md text-white mt-0">
                        <span className="fw-300 mr-3">Your wallet is empty. Add to your balance to start investing</span>
                    </p>
                    {/* <div className="h-4" /> */}
                    {/* <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            type="text"
                            color="green"
                            placeholder="Amount"
                            size="regular"
                        />
                    </div> */}
                    <div className="h-8" />
                    <Link to="/deposit">
                        <Button
                            color=""
                            className="border-white text-white w-full hover:bg-white hover:text-black"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                        >
                            Deposit now
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                    </Link>
                    <div className="h-2" />
                </div>
            </Card>
        </>
    );
}
