import {useRef, useState} from 'react';

import {useHistory} from 'react-router-dom';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import Webcam from "react-webcam";

import {useGlobalState} from 'state-pool';
import {useAuth} from 'hooks/useAuth';

export default function Selfie({selfie, setSelfie, submit}) {
    const webcamRef = useRef();
    const [started, setStarted] = useState(false);
    const [error, setError] = useState('');
    const [currentUser] = useGlobalState('currentUser');
    const {commit} = useAuth();
    const history = useHistory();
    
    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();

        setSelfie(imageSrc);
    }

    const onFinishClick = async () => {
        if(!selfie) {
            setError('Please take a photo of yourself to continue');

            return;
        }

        const isSuccessfulSubmit = await submit();

        const isSuccessfulUpdateUser = await commit({...currentUser, kyc_pending: 2}, '/user/edituser');
        
        if(isSuccessfulSubmit && isSuccessfulUpdateUser) history.push('/verify/2/complete');

    }

    return (
        <>
            <div className="w-full text-left">
                <div className="h-5" />
                <h3 className="font-normal text-4xl text-white">Take a selfie</h3>
                <div className="h-2" />
                <Paragraph color="white">
                    Make sure you're in a well lit room and that all your features show cearly.
                </Paragraph>
                <Card className="max-w-3xl text-center bg-primary">  
                    <div className="h-2" />
                    <CardBody>
                        {!started && (
                            <>
                                <Paragraph color="white">
                                    You're required to take a selfie, so that we can verify your identification document.
                                </Paragraph>
                                <Button 
                                    color="green"
                                    className="hover:bg-green hover:text-black w-full"
                                    buttonType="outline"
                                    size="lg"
                                    ripple="dark"
                                    onClick={_ => {
                                        setStarted(true);
                                        setError('');
                                    }}
                                >
                                    I'm ready
                                </Button>
                            </>
                        )}
                        {started && !selfie && (
                            <>
                                <Webcam 
                                    ref={webcamRef}
                                />
                                <div className="h-5" />
                                <Button 
                                    color="cream"
                                    className="bg-primary w-full"
                                    buttonType="filled"
                                    size="lg"
                                    ripple="dark"
                                    onClick={capture}
                                >
                                    Take Photo
                                </Button>
                            </>
                        )}
                        {started && selfie && (
                            <>
                                <img alt="your_selfie" src={selfie} className="w-full h-auto" />
                                <div className="h-5" />
                                <Button 
                                    color="cream"
                                    className="bg-primary w-full"
                                    buttonType="filled"
                                    size="lg"
                                    ripple="dark"
                                    onClick={_ => setSelfie(null)}
                                >
                                    Retake
                                </Button>
                            </>
                        )}

                        {error && <p className="text-left mt-4 text-red">{error}</p>}
                    </CardBody>
                    <CardFooter className="text-left">
                    </CardFooter>
                </Card>
                <div className="h-5" />
                <Button
                    color="green"
                    
                    className="hover:bg-green hover:text-black w-full hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onFinishClick}
                >
                    Finish
                    <Icon name="arrow_forward" size="sm" />
                </Button> 
            </div>
        </>
    )
}
