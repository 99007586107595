import {useRef, useState, useEffect} from 'react';

import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import Input from '@material-tailwind/react/Input';
// import Paragraph from '@material-tailwind/react/Paragraph';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import { bitorsat } from 'utils/js';
import {useGlobalState} from 'state-pool';
import { vbApi } from 'api/vb';

export default function WithdrawalAmount({stage, setStage, amount, setAmount}) {
    const [error, setError] = useState('');
    const walletRef = useRef();
    const [currentUser] = useGlobalState('currentUser');
    const [insufficientFunds, setInsufficientFunds] = useState(false);

    useEffect(() => {
        if (currentUser && currentUser.balance_wallet === 0) {
            setInsufficientFunds(true);
        }
    }, [currentUser])

    const onContinueClick = () => {
        if(!amount) {
            setError('Please enter the amount you would like to withdraw to continue.');

            return;
        }

        if(amount && Number(amount) <= 0) {
            setError('Please enter a valid amount to continue.');

            return;
        }

        if (amount > currentUser.balance_wallet){
            setError('Insufficient funds')

            return;
        }

        if (error !== '') return;

        vbApi.get(`/user/getuser`).then((value) => {
            const userData = value.data;
            if (userData.balance_wallet === 0 || userData.balance_wallet < amount){
                setError('You don\'t have enough funds to widthdraw');
                return ;
            } else {
                setStage(stage + 1);
                return;
            }
        });
        
    }

    
    useEffect(() => {
        try {
            if((Number(amount) * 100000000) > Number(currentUser.balance_wallet)){
                setError('Not enough in your balance');

                return;
            } else {
                setError('')
            }
        } catch (e) {
            
        }
        
    }, [amount])

    return (
        <div className="w-full text-left">
            <div className="h-10" />
            <h3 className="font-normal text-4xl text-white">How much would you like to withdraw?</h3>
            <div className="h-20" />

            {
                insufficientFunds &&
                <div className="flex mb-5 w-full">
                    <div className={`w-full`}>
                        <div className="bg-primary border-2 border-red-500 w-full rounded-xl overflow-hidden p-4 shadow-2xl" >
                            <div className="flex items-center justify-start py-1 h-10">
                                <p className="font-bold text-xl text-white mt-0">
                                    <span className="fw-300 mr-3" style={{color: "#f55244"}}>You do not have enough funds to withdraw</span>
                                </p>

                                <div className="ml-auto">
                                    <span ref={walletRef} className="text-gray-500">
                                        <Icon className="ml-2" name="info" size="4xl" color="red" />
                                    </span>
                                    <Tooltips placement="top" ref={walletRef}>
                                        <TooltipsContent>Insufficient funds</TooltipsContent>
                                    </Tooltips>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="flex mb-5 w-full">
                <div className={`w-full`}>
                    <div className="bg-primary w-full rounded-xl overflow-hdden p-4 shadow-2xl" >
                        <div className="flex items-center justify-start py-1 h-10">
                            <p className="font-bold text-xl text-white mt-0"><span className="fw-300 mr-3">Current Balance</span> {currentUser && bitorsat(Number(currentUser.balance_wallet))}</p>

                            <div className="ml-auto cursor-pointer">
                                <span ref={walletRef} className="text-gray-500">
                                    <Icon className="ml-2" name="help" size="4xl" color="gray" />
                                </span>
                                <Tooltips placement="top" ref={walletRef}>
                                    <TooltipsContent>This is your current balance. It is the amount you have available, that is not currently invested in any funds. </TooltipsContent>
                                </Tooltips>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Card className="max-w-3xl text-left bg-primary">  
                <CardBody>
                    <div className="mb-4 text-left flex">
                        <span className="mt-2 mr-4 text-white font-normal text-2xl">BTC</span>
                        <Input
                            outline={false}
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            onFocus={() => setError('')}
                            error={error}
                            color="gray"
                            placeholder="Amount"
                            size="large"
                            type="number"
                            min={1}
                        />
                    </div>
                </CardBody>
            </Card>
            <div className="h-5" />
            <Button
                color="green"
                className="hover:bg-green hover:text-black w-full hover:pr-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={onContinueClick}
            >
                Continue
                <Icon name="arrow_forward" size="sm" />
            </Button> 
            {/* <div className="h-10" />
            <Paragraph color="white">
                Min max amounts goes here ?
            </Paragraph> */}
        </div>
    );
}
