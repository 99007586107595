import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Paragraph from "@material-tailwind/react/Paragraph";

const FEES = [
    {
        title: 'Deposit fees',
        fee: 'No fee',
        description: 'We don’t charge any deposit fees but external network fees may be incurred that are out of our control.'
    },
    {
        title: 'Storage fees',
        fee: 'No fee',
        description: 'There are no fees for securely storing your digital assets with us.'
    },
    {
        title: 'Performance fees',
        fee: 'No fee',
        description: 'We don’t charge performance fees.'
    },
    {
        title: 'Monthly rebalancing fees',
        fee: '0.17% per month',
        description: 'The annualised rebalancing fee is 2.04% which is based on the average Crypto Bundle value as recorded at each rebalancing date. This equates to a monthly charge of 0.17% and is deducted on the first of every month from your Crypto Bundle value.'
    },
    {
        title: 'Transaction fees',
        fee: '1.00% trading fee',
        description: 'Buying or selling crypto incurs a 1.00% trading fee on the value transacted. Please note that there is a minimum trade charge of R 20.00.'
    },
    {
        title: 'Withdrawal fees',
        fee: '50.00 or 0.25% for any amounts over R 200,000.00',
        description: 'Withdrawing your funds from your account incurs a nominal fee as displayed on the left.'
    },
    {
        title: 'Vault fees',
        fee: 'Lock and add fees',
        description: 'We do not charge a fee when you add funds to your vault'
    },
    {
        title: 'Unlock fees',
        fee: 'Check description',
        description: `
            1% fee if you unlock within a year,
            0% fee if you unlock within 12 and 18 months,
            0% fee and receive a 0.25% bonus if your first unlock is after 18 months.
        `
    }
];

export default function Fees() {

    return (
        <div className="mt-5">
            {FEES.map(({title, fee, description}, i) => (
                <div key={i} className="flex w-full h-auto">
                    <Card className="mb-2 transition cursor-pointer group bg-primary">
                        <CardBody>
                            <Paragraph>
                                <div className="flex flex-col lg:flex-row">
                                    <div className="w-100 lg:w-1/3">
                                        <span className="text-base font-normal text-white">Title: {title}</span>
                                    </div>
                                    <div className="w-100 lg:w-1/3">
                                        <span className="text-base font-normal text-white">Fee: {fee}</span>
                                    </div>
                                    <div className="w-100 lg:w-1/3">
                                        <span className="text-base font-normal text-white">Description: {description}</span>
                                    </div>
                                </div>
                            </Paragraph>
                        </CardBody>
                    </Card>
                </div>
            ))}
        </div>
    );
}
