
import {Link} from 'react-router-dom';

import {useHistory} from 'react-router-dom';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Paragraph from '@material-tailwind/react/Paragraph';

export default function ConfirmYourDetails({firstname, lastname, idNumber, address}) {
    const history = useHistory();

    function onContinueClick() {
        history.push('/register/accept-conditions');
    }

    return (

        <Card className="max-w-3xl text-center bg-primary">  
            <h3 className="font-normal text-4xl text-white">Please confirm your details</h3>
            <div className="h-2" />
            <CardBody className="text-center">
                <div className="w-full text-left">
                    <div className="gs-12 max-w-2xl">
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Legal first name</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{firstname}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Legal surname</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{lastname}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">ID number</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{idNumber}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Address</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{address}</p>
                        </div>
                    </div>
                </div>
            </CardBody>
            <CardFooter>
                <div className="h-5" />
                <Paragraph color="white">
                    Something not right?{" "} <Link className="text-green" to="/register/your-details">Edit your details</Link>
                </Paragraph>
                <div className="flex justify-center flex-col">
                    <Button
                        color="green"
                        className="hover:bg-green hover:text-white w-full hover:shadow-xl hover:pr-2"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={onContinueClick}
                    >
                        Continue
                        <Icon color="black" name="arrow_forward" size="sm" />
                    </Button>
                </div>
            </CardFooter>
        </Card>
    )
}