import decode from 'jwt-decode';
import axios from 'axios';
import {store} from 'state-pool';

export async function checkTokens() {
    const accessToken = localStorage.getItem('access-token');
    const refreshToken = localStorage.getItem('refresh-token');

    if(!accessToken && !refreshToken) {
        logout();

        return Promise.resolve(true);
    }

    const accessExpired = checkJwt(accessToken);
    const refreshExpired = checkJwt(refreshToken);

    if(accessExpired && !refreshExpired && accessToken && refreshToken) {
        const tokens = await getAccessToken(refreshToken);
        
        return Promise.resolve(tokens);
    }

    if(accessExpired && refreshExpired && accessToken && refreshToken) {
        logout();

        return Promise.resolve(true);
    }

    if((accessExpired || !accessToken) && window.location.pathname !== '/login') {
        logout();

        return Promise.resolve(true);
    }

    if(!accessExpired && !refreshExpired && accessToken && refreshToken) {
        const tokens = {
            accessToken,
            refreshToken
        };

        return Promise.resolve(tokens);
    }
}

function checkJwt(token?: string | null) {
    
    if(!token) token = localStorage.getItem('access-token');

    if(token) {
        const decoded: {exp: number} = decode(token);

        if(new Date(decoded.exp * 1000) < new Date()) {
            return true;
        }

        return false;
    }
}

export async function getAccessToken(refreshToken: string) {
    const accessToken = localStorage.getItem('access-token');
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    };

    const body = {
        token: refreshToken
    };

    try {
        const res: any = await axios.post(`${process.env.REACT_APP_API_URL}/authO/refresh`, body, {headers});

        if(res.data) {
            if(res.data.accessToken) localStorage.setItem('access-token', res.data.accessToken);
            if(res.data.refreshToken) localStorage.setItem('refresh-token', res.data.refreshToken);

            return Promise.resolve({
                accessToken: res.data.accessToken,
                refreshToken: res.data.refreshToken,
            });
        } else if(res) {
            if(res.accessToken) localStorage.setItem('access-token', res.accessToken);
            if(res.refreshToken) localStorage.setItem('refresh-token', res.refreshToken);

            return Promise.resolve({
                accessToken: res.accessToken,
                refreshToken: res.refreshToken,
            });
        }
    } catch {
        logout();
    }
}

async function logout() {
    const accessToken = localStorage.getItem('access-token');
    const refreshToken = localStorage.getItem('refresh-token');

    if(refreshToken && typeof refreshToken === 'string' && accessToken && typeof accessToken === 'string') {
        await axios.delete(`${process.env.REACT_APP_API_URL}/authO/logout`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            data: {
                token: refreshToken
            }
        });
        store.setState("currentUser", null);
        localStorage.clear();
    }
}