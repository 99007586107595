import { Button, Icon } from '@material-tailwind/react';

import {useHistory} from 'react-router-dom';

export default function NextButton(props) {
    const history = useHistory();

    return (
        <Button
            color="white"
            rounded
            className="hover:mr-4 mr-6 text-white"
            buttonType="link"
            size="lg"
            ripple="dark"
            {...props}
            onClick={() => history.goForward()}
        >
            Next           
            <Icon name="arrow_forward" size="sm" />
        </Button>
    )
}
