import {useState} from 'react';

import ChooseDepositType from 'components/deposit/ChooseDepositType';

import BackButton from 'components/buttons/BackButton';
import { useHistory } from 'react-router';

import { Helmet } from 'react-helmet';

export default function Deposit() {
    const [stage, setStage] = useState(1);

    const history = useHistory();

    return (
        <div className="h-auto flex items-center justify-center flex-col relative">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Deposit - Venturebay</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="px-3 md:px-8 md:w-full">
                <div className="absolute left-1 xs:top-5 md:top-3">
                    <BackButton onClick={() => {stage !== 1 ? setStage(stage - 1) : history.goBack()}} />
                </div>

                <div>
                    <ChooseDepositType />
                </div>
            </div>
        </div>
    );
}
