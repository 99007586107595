import SvgNotFound from 'assets/svg-icons/SvgNotFound';
import {Helmet} from "react-helmet";

export default function NotFound() {
    return (
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen ">
            <Helmet>
                <meta charSet="utf-8" />
                <title>404 - Venturebay</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="bg-landing-background bg-cover bg-center absolute top-0 w-full h-full" />
            <div className="container max-w-8xl relative mx-auto">
                <div className="items-center flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                        <SvgNotFound className="fill-white" />
                        <h3 className="font-bold uppercase italic text-4xl text-white">Page not found</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}