export const fundsDetails = [
    {
        fund: 'Fund 1',
        name: 'Top 10 Layer 1 Ecosystems',
        cryptoType: [
            {
                name: 'Solana',
                weighting: 20,
                ticker: 'SOL',
                rank: 5,
                exchange: 'Kucoin',
                currentMarketCap: 44509655532
            },
            {
                name: 'Cardano',
                weighting: 20,
                ticker: 'ADA',
                rank: 8,
                exchange: 'Kucoin',
                currentMarketCap: 37609647710
            },
            {
                name: 'Binance',
                weighting: 20,
                ticker: 'BINACE',
                rank: 7,
                exchange: 'Kucoin',
                currentMarketCap: 1857489211
            },
            {
                name: 'Polkadot',
                weighting: 20,
                ticker: 'DOT',
                rank: 9,
                exchange: 'Kucoin',
                currentMarketCap: 26860596667
            },
            {
                name: 'Ethereum',
                weighting: 20,
                ticker: 'ETH',
                rank: 2,
                exchange: 'Kucoin',
                currentMarketCap: 377329910681
            },
        ],
        description: 'You will receive diversified, equal-weighted exposure to currencies in the top ten cryptocurrencies based on market capitalization. We designed this product with one objective in mind, to mimic the performance of the broader cryptocurrency market and ensure that you own a piece of at least 5 of the biggest, and highest performing, success stories in the crypto space, whatever they may be.'
    },
    {
        fund: 'Fund 2',
        name: 'USD Balanced Fund',
        cryptoType: [
            {
                name: 'Tether',
                weighting: 20,
                ticker: 'USDT',
                rank: 3,
                exchange: 'Kucoin',
                currentMarketCap: 78290182508
            },
            {
                name: 'USD Coin',
                weighting: 20,
                ticker: 'USDC',
                rank: 6,
                exchange: 'Kucoin',
                currentMarketCap: 43659384669
            },
            {
                name: 'Avalanche',
                weighting: 20,
                ticker: 'AVAX',
                rank: 11,
                exchange: 'Kucoin',
                currentMarketCap: 21934709769
            },
            {
                name: 'Polygon',
                weighting: 20,
                ticker: 'MATIC',
                rank: 14,
                exchange: 'Kucoin',
                currentMarketCap: 14641308932
            },
            {
                name: 'Fantom',
                weighting: 20,
                ticker: 'FTM',
                rank: 31,
                exchange: 'Kucoin',
                currentMarketCap: 44509655532
            },
        ],
        description: 'By choosing option 2, you are taking on a greater risk, but the rewards are so much greater. With cryptocurrencies in the top 35 and 2 USD-balanced coins, you are taking control of your future with this investment'
    }
]