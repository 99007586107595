import React, { useEffect, useState } from 'react'

import {useParams} from 'react-router-dom';
import { useGlobalState } from 'state-pool';

import H4 from "@material-tailwind/react/Heading4";
import Paragraph from '@material-tailwind/react/Paragraph'
import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import H6 from "@material-tailwind/react/Heading6";

import { fundsDetails } from './data';
import StackedBarChart from 'components/charts/StackedBarChart';


const FundsDetails = () => {
    const {fundId} = useParams();
    const [funds] = useGlobalState('funds');
    const [currentFund, setCurrentFund] = useState({});
    const [activeFund, setActiveFund] = useState({});

    useEffect(() => {
        setCurrentFund(funds.find(f => Number(f?.id) === Number(fundId)));
        setActiveFund(fundsDetails.filter((x) => x?.name === currentFund?.name)[0]);
    })
    
    
  return (
    <>
        <div className='flex flex-col w-full h-full justify-center'>
           <div className='w-full flex p-4 justify-center border-green border-t-2'>
                <H4 color='yellow'>{currentFund?.name}</H4>
            </div>
            <div className='w-full flex flex-col p-4'>
                <div className='w-full flex justify-center'>
                    <div className='flex flex-col w-full px-6 md:flex-row justify-center'>
                        <div className='flex px-2 w-full md:w-1/2 h-full py-2'>
                            <Card className='bg-green'>
                                <CardBody>
                                    <H6 color="gray">Fund Details:</H6>
                                    <Paragraph color="black">
                                        <strong>
                                           <div className='text-lg'>
                                                {activeFund?.description} 
                                            </div> 
                                        </strong>
                                        
                                    </Paragraph>
                                </CardBody>
                            </Card>  
                        </div>
                        <div className='flex px-2 w-full md:w-1/2 h-full py-2'>
                            <Card className='bg-green'>
                                <CardBody>
                                    <H6 color="gray">Our Product:</H6>
                                    <Paragraph color="black">
                                        <strong>
                                            <div className='text-lg'>
                                                Purchasing a slice of Solana, Ethereum, and other cryptocurrencies all at once allows you to reap their rewards without being affected too much when any individual drops in value.
                                                Cryptocurrencies are high-risk investments, which has the potential for a higher return. Diversifying your portfolio of cryptocurrencies can reduce risk when investing in cryptocurrencies 
                                            </div>
                                        </strong>
                                    </Paragraph>
                                </CardBody>
                            </Card>
                        </div>
                        
                    </div>
                </div>

                <div className='flex justify-center w-full h-96 mt-4'>
                    <div className='mr-10 w-full h-full'>
                       <StackedBarChart data={activeFund?.cryptoType} dataKeyOne={'weighting'} dataKeyTwo={'rank'}/> 
                    </div>
                </div>

                <div className='w-full flex justify-center'>
                    <div className='flex flex-col w-full px-6 md:flex-row justify-center'>
                        <div className='flex px-2 w-full h-full py-2'>
                            <Card className='bg-primary'>
                                <CardBody>
                                    <H6 color="white">Types of crypto currency:</H6>
                                    {
                                        activeFund?.fund === 'Fund 1'
                                        ? <Paragraph color="white">
                                            <strong>
                                            <div className='text-lg'>
                                                    <ul>
                                                        <li>
                                                        - Solana:
                                                            <span className='pl-8 w-full flex'>Solana is a public blockchain platform with smart contract functionality. Its native cryptocurrency is SOL. Solana claims to offer faster transaction times and lower costs than its main competitor, Ethereum.</span><br/>
                                                        </li>
                                                        <li>
                                                        - Cardano:
                                                            <span className='pl-8 w-full flex'>Cardano is a public blockchain platform. It is open-source and decentralized, with consensus achieved using proof of stake. It can facilitate peer-to-peer transactions with its internal cryptocurrency, ADA. Cardano was founded in 2015 by Ethereum co-founder Charles Hoskinson</span><br/>
                                                        </li>
                                                        <li>
                                                        - Binance:
                                                            <span className='pl-8 w-full flex'>Binance is a public blockchain which is the largest exchange in the world in terms of daily trading.</span><br/>
                                                        </li>
                                                        <li>
                                                        - Polkadot:
                                                            <span className='pl-8 w-full flex'>Polkadot is an open source, blockchain platform and cryptocurrency. It provides interconnectivity and interoperability between blockchains, by enabling independent chains to securely exchange messages and perform transactions with each other without trusted third-party.</span><br/>
                                                        </li>
                                                        <li>
                                                        - Ethereum:
                                                            <span className='pl-8 w-full flex'>Ethereum is a decentralized, open-source blockchain with smart contract functionality. Ether is the native cryptocurrency of the platform. Among cryptocurrencies, Ether is second only to Bitcoin in market capitalization. Ethereum was conceived in 2013 by programmer Vitalik Buterin</span><br/>
                                                        </li>
                                                    </ul>
                                                </div> 
                                            </strong>
                                            
                                        </Paragraph>
                                        : <Paragraph color="white">
                                                <strong>
                                                    <div className='text-lg'>
                                                        <ul>
                                                            <li>
                                                            - USD coin:
                                                                <span className='pl-8 w-full flex'>USD Coin is a digital stablecoin that is pegged to the United States dollar. USD Coin is managed by a consortium called Centre, which was founded by Circle and includes members from the cryptocurrency exchange Coinbase and Bitcoin mining company Bitmain, an investor in Circle.</span><br/>
                                                            </li>
                                                            <li>
                                                            - Avalanche:
                                                                <span className='pl-8 w-full flex'>Avalanche is a decentralized, open-source proof of stake blockchain with smart contract functionality. AVAX is the native cryptocurrency of the platform.</span><br/>
                                                            </li>
                                                            <li>
                                                            - Polygon:
                                                                <span className='pl-8 w-full flex'>In recent years, Polygon (MATIC) has been one of the most ambitious cryptocurrency projects, which has a lot of potential for growth with the increased demand for Non-Fungible Tokens (NFT) and various decentralized finance projects (DeFi).</span><br/>
                                                            </li>
                                                            <li>
                                                            - Fantom:
                                                                <span className='pl-8 w-full flex'>Fantom is a layer-1 blockchain aiming to provide an alternative to the high costs and low speeds about which users of Ethereum often complain. Fantom runs on a directed acyclic graph blockchain, similar to Hedera Hashgraph</span><br/>
                                                            </li>
                                                            <li>
                                                            - Tether:
                                                                <span className='pl-8 w-full flex'>Tether is a cryptocurrency that is hosted on the Ethereum and Bitcoin blockchains, among others. Its tokens are issued by the Hong Kong company Tether Limited, which in turn is controlled by the owners of Bitfinex</span><br/>
                                                            </li>
                                                        </ul>
                                                    </div> 
                                                </strong>
                                                
                                            </Paragraph>

                                    }
                                    
                                </CardBody>
                            </Card>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </>
  )
}

export default FundsDetails