import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import {useHistory} from 'react-router-dom';

export default function NavigationControls({className}) {
    const history = useHistory();

    return (
        <div className={`flex ${className ? className : ""}`}>
            <Button
                className="text-white"
                color="transparent"
                buttonType="link"
                size="regular"
                rounded={true}
                block={false}
                iconOnly={true}
                ripple="light"
                onClick={() => history.goBack()}
            >
                <Icon name="arrow_back_ios" size="sm" />
            </Button>
            <Button
                className="text-white"
                color="transparent"
                buttonType="link"
                size="regular"
                rounded={true}
                block={false}
                iconOnly={true}
                ripple="light"
                onClick={() => history.goForward()}
            >
                <Icon name="arrow_forward_ios" size="sm" />
            </Button>
        </div>
    )
}
