import {useState, useEffect} from 'react';

import {useHistory} from 'react-router-dom';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';
import DocumentUpload from 'components/document-handling/DocumentUpload';

import {useGlobalState} from 'state-pool';
import {useAuth} from 'hooks/useAuth';

export default function ProofOfResidence({submit}) {
    const [proofDocument, setProofDocument] = useState({
        file_name: '',
        file_data: ''
    });
    const [error, setError] = useState('');
    const history = useHistory();
    const [currentUser] = useGlobalState('currentUser');
    const {commit} = useAuth();

    // useEffect(() => console.log(proofDocument), [proofDocument]);

    const onContinueClick = async () => {
        if(proofDocument && !proofDocument.file_data) {
            setError('Please upload your proof of residence document to continue');

            return;
        }

        if(proofDocument && proofDocument.file_data) {
            const isSuccessfulSubmit = await submit(proofDocument.file_data)

            const isSuccessfulUpdateUser = await commit({...currentUser, kyc_pending: 3}, '/user/edituser');
            
            if(isSuccessfulSubmit && isSuccessfulUpdateUser) history.push('/verify/3/complete');
        }
    }

    return (
        <>
            <div className="w-full text-left">
                <div className="h-5" />
                <h3 className="font-normal text-4xl text-white">Proof of residence</h3>
                <Paragraph color="white">
                    Please enter your residential address and provide us with proof that you reside at this address.
                </Paragraph>
                <div className="h-7" />
                <Card className="text-center bg-primary">  
                    <div className="h-2" />
                    <CardBody>
                        <DocumentUpload
                            setFile={setProofDocument}
                        >
                            <Button
                                color="green"
                                
                                className="w-full text-cream hover:pr-2 hover:bg-transparent"
                                buttonType={(proofDocument && !proofDocument.file_name) ? "outline" : "link"}
                                size="lg"
                                ripple="dark"
                                onClick={() => setError('')}
                            >
                                {(proofDocument && !proofDocument.file_name) ? "Upload proof" : proofDocument.file_name}
                                {(proofDocument && !proofDocument.file_name) && <Icon name="upload" size="sm" />}
                            </Button> 
                        </DocumentUpload>
                        {error && <p className="text-left text-red">{error}</p>}
                    </CardBody>
                    <CardFooter className="text-left">
                        <p className="font-semibold text-black">
                            Accepted documents
                        </p>
                        <div className="mb-0 text-left">
                            <div className="inline-block">
                                <span className="w-3 h-3 bg-green text-black rounded-full flex items-center justify-center" />
                            </div>
                            <span className="inline-block ml-2 text-white text-xs md:text-sm font-normal leading-relaxed">
                                1MB Max file size
                            </span>
                        </div>
                        <div className="mb-0 text-left">
                            <div className="inline-block">
                                <span className="w-3 h-3 bg-green text-black rounded-full flex items-center justify-center" />
                            </div>
                            <span className="inline-block ml-2 text-white text-xs md:text-sm font-normal leading-relaxed">
                                Utility bill (not older than 3 months)
                            </span>
                        </div>
                        <div className="mb-0 text-left">
                            <div className="inline-block">
                                <span className="w-3 h-3 bg-green text-black rounded-full flex items-center justify-center" />
                            </div>
                            <span className="inline-block ml-2 text-white text-xs md:text-sm font-normal leading-relaxed">
                                Bank statement (not older than 3 months)
                            </span>
                        </div>
                        <div className="mb-0 text-left">
                            <div className="inline-block">
                                <span className="w-3 h-3 bg-green text-black rounded-full flex items-center justify-center" />
                            </div>
                            <span className="inline-block ml-2 text-white text-xs md:text-sm font-normal leading-relaxed">
                                Tax certificates
                            </span>
                        </div>
                    </CardFooter>
                </Card>
                <div className="h-5" />
                <Button
                    color="green"
                    
                    className="hover:bg-green hover:text-white w-full hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onContinueClick}
                >
                    Continue
                    <Icon name="arrow_forward" size="sm" />
                </Button> 
            </div>
        </>
    )
}
