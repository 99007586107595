import {useState} from 'react';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import {useHistory} from 'react-router-dom';

export default function BankDetails() {
    const [accountNumber, setAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [accountType, setAccountType] = useState('');
    const [branchCode, setBranchCode] = useState('');

    const history = useHistory();

    function onDoneClick() {
        console.log('done');
    }

    return (
        <div className="h-screen px-3 md:px-8 flex relative justify-center">
            <div className=" absolute left-1 top-5 md:top-3">
                <Button
                    color=""
                    rounded
                    className="hover:pl-3"
                    buttonType="link"
                    size="lg"
                    ripple="dark"
                    onClick={() => history.goBack()}
                >
                    <Icon name="arrow_back" size="sm" />
                    Back           
                </Button>
            </div>
            <div className="mt-20">
                <h3 className="font-normal text-4xl">Bank details</h3>
                <div className="h-2" />
                <Paragraph>
                Enter your banking details below
                </Paragraph>
                <Card className="max-w-3xl text-left border-t-8 border-primary">  
                    <div className="h-2" />
                    <CardBody>
                        <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={accountNumber}
                                onChange={e => setAccountNumber(e.target.value)}
                                type="text"
                                color="cream"
                                placeholder="Account number"
                                size="regular"
                            />
                        </div>

                        <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={bankName}
                                onChange={e => setBankName(e.target.value)}
                                type="text"
                                color="cream"
                                placeholder="Bank name"
                                size="regular"
                            />
                        </div>

                        <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={accountType}
                                onChange={e => setAccountType(e.target.value)}
                                type="text"
                                color="cream"
                                placeholder="Account type"
                                size="regular"
                            />
                        </div>

                        <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={branchCode}
                                onChange={e => setBranchCode(e.target.value)}
                                type="number"
                                color="cream"
                                placeholder="Branch code"
                                size="regular"
                            />
                        </div>

                    </CardBody>
                    <CardFooter>
                        <Button
                            color=""
                            
                            className="bg-primary w-full hover:shadow-xl hover:pr-2"
                            buttonType="filled"
                            size="lg"
                            ripple="dark"
                            onClick={onDoneClick}
                        >
                            Done
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                        <div className="h-5" />
                        <Paragraph>
                            It is required that the account be registered in your name.
                        </Paragraph>
                        <Paragraph>
                            A 24hr waiting period applies after updating your banking details, before you are able to withdraw funds.
                        </Paragraph>
                        <Paragraph>
                            Withdrawals to third party accounts are strictly prohibited.
                        </Paragraph>
                    </CardFooter>
                </Card>
                <div className="h-10" />
            </div>
        </div>
    );
}
