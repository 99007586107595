export const requestNotificationPermission = () => {
    if (Notification.permission === "granted") {

    } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
            if (permission === "granted") {
                new Notification("Notification enabled");
            }
        });
    }
}

export const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
};

export function isValidJson(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function numberWithSpaces(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function numberWithCommas(x: number) {
    return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function isValidDate(d: Date) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
        // it is a date
        if (isNaN(d.getTime())) {  // d.valueOf() could also work
            // date is not valid
            return false;
        } else {
            // date is valid
            return true;
        }
    } else {
        // not a date
        return false;
    }
}

export const isValidEmail = (email: string) => {
    if (email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) return true;

    return false;
}

export function invalid(val: string | string[], opt: string, name: string) {

    if (!(typeof val === 'string' || Array.isArray(val))) return 'Invalid Type';
    
    if (opt.includes('match')) if (!Array.isArray(val) || val.length!==2 || val[0].length < 1 || val[0] !== val[1] ) return `${name} s don't match`;
    
    if (Array.isArray(val)) val=val[0];

    if (opt.includes('required')) if (val.length<1) return `${name} Required`;
    
    if (opt.includes('min5')) if (val.length<5) return `${name} needs to be longer than 5`; 
    if (opt.includes('min6')) if (val.length<6) return `${name} needs to be longer than 6`; 
    if (opt.includes('min7')) if (val.length<7) return `${name} needs to be longer than 7`; 
    if (opt.includes('min8')) if (val.length<8) return `${name} needs to be longer than 8`; 
    if (opt.includes('min9')) if (val.length<9) return `${name} needs to be longer than 9`; 
    if (opt.includes('min10')) if (val.length<10) return `${name} needs to be longer than 10`; 
    if (opt.includes('min11')) if (val.length<11) return `${name} needs to be longer than 11`; 
    
    if (opt.includes('number') || opt.includes('complex')) if (!val.match(/.*[0-9].*/g)) return `${name} requires a number`;
    if (opt.includes('special') || opt.includes('complex')) if (!val.match(/.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*/g)) return `${name} requires a special character`;
    if (opt.includes('upper') || opt.includes('complex')) if (!val.match(/.*[A-Z].*/g)) return `${name} requires an uppercase character`;
    if (opt.includes('lower') || opt.includes('complex')) if (!val.match(/.*[a-z].*/g)) return `${name} requires a lowercase character`;
    
    if (opt.includes('email')) if (!val.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) return `Invalid Email`; 
    
    return false;
}

export function parseQueryString() {
    let str = window.location.search.replaceAll('%20', ' ');
    let objURL = {};

    str.replace(
        new RegExp("([^?=&]+)(=([^&]*))?", "g"),
        // @ts-ignore
        function ($0, $1, $2, $3) {
            // @ts-ignore
            objURL[$1] = $3;
        }
    );
    return objURL;
};

export function displayAlert(title: string, message: string, type: string) {
    const event = new CustomEvent('display-alert', { 
        detail: {
            alertTitle: title,
            alertMessage: message,
            alertType: type,
        }
    });

    window.dispatchEvent(event);
}

export function displayAlertModal(message: string) {
    const event = new CustomEvent('display-alert-modal', { 
        detail: {
            message: message,
        }
    });

    window.dispatchEvent(event);
}