import Icon from '@material-tailwind/react/Icon';

import { format } from 'date-fns';

import BtcChartController from './BtcChartController';

import _ from 'lodash';

import './BtcChart.css';

export default function BtcChart() {
    const {error, timeValue, setTimeValue, options, time, price} = BtcChartController();

    return (
        <div className="relative chart-h with-notification min-h-96 w-full rounded-xl bg-primary shadow-xl mb-5 overflow-visible">
            {!error ? (
                <>
                    <div className='flex relative top-5 left-5 right-10'>
                        {options.map(({value, label}) => (
                            <p onClick={() => setTimeValue(value)} className={`font-bold cursor-pointer ml-5 ${timeValue && _.isEqual(value, timeValue) ? 'text-orange font-bold underline' : 'text-white font-normal'}`}>{label}</p>
                        ))}
                    </div>
                    <div className="flex items-center">
                        <img
                            src="/images/logos/btc.svg"
                            alt="btc_logo"
                            className="w-20 h-auto ml-5 mt-10"
                        />
                        <div className="ml-auto justify-self-end">
                            <h2 className="asset-price text-white text-3xl mr-10 mt-10 mb-0">R{" "}{price}</h2>
                            <p className="asset-price text-white text-xs opacity-40 mr-10">
                                {time && format(new Date(time), 'dd-MM-yyyy HH:mm')}
                            </p>
                        </div>
                    </div>
                    <div id="btcChartContainer" className="btc-chart-container pt-10 overflow-visible">
                        <canvas id="btcChart"></canvas>
                    </div>
                </>
            ) : 
            (
                <>
                    <div className="flex items-center justify-center flex-col h-full">
                        <Icon name="error" color="white" size="9xl" />
                        <h3 className="text-white font-3xl mt-4">{error}</h3>
                    </div>
                </>
            )}
        </div>
    )
}
