import {useState, useRef} from 'react';

import Card from '@material-tailwind/react/Card';
import CardRow from '@material-tailwind/react/CardRow';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardStatus from '@material-tailwind/react/CardStatus';
import CardStatusFooter from '@material-tailwind/react/CardStatusFooter';
import Icon from '@material-tailwind/react/Icon';
import Button from '@material-tailwind/react/Button';
import Tooltips from '@material-tailwind/react/Tooltips';
import TooltipsContent from '@material-tailwind/react/TooltipsContent';

import WithdrawFromFundToWalletModal from 'components/withdraw/WithdrawFromFundToWalletModal';
import FundTransactionsModal from 'components/investments/FundTransactionsModal';
import {format} from 'date-fns';

import {isValidDate} from 'utils';
import {bitorsat} from 'utils/js';

export default function StatusCard({
    color,
    icon,
    title,
    amount,
    hodlUntil,
    lastInteracted,
    fund,
    transactions,
    pending,
}) {
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);
    const [activeFund, setActiveFund] = useState(null);
    const helpRef = useRef();
    const [showFundTransactionsModal, setShowFundTransactionsModal] = useState(false);
    function onWithdrawClick() {
        setActiveFund(fund);

        setShowWithdrawModal(true);
    }

    function onShowFundTransactionsClick() {
        setShowFundTransactionsModal(true);
    }

    const amountEl = <span class="text-3xl text-white">{amount}</span>;

    const noHodl = (hodlUntil && isValidDate(new Date(hodlUntil)) && (new Date(hodlUntil) < new Date()) || !hodlUntil);

    return (
        <div className="px-4 mb-16">
            <Card className="hover:shadow-2xl transition cursor-pointer bg-primary">
                <CardRow>
                    <CardHeader color={!color ? "orange" : color} iconOnly className="mb-0">
                        {icon !== "bitcoin" && <Icon name={icon} size="3xl" color="white" />}
                        {icon === "bitcoin" && <img alt="bitcoin_logo" src="/icons/bitcoin.svg" />}
                    </CardHeader>

                    <CardStatus title={title} amount={amountEl} />
                </CardRow>

                <CardStatusFooter>
                    {/* <Icon color={percentageColor} name={percentageIcon} /> */}

                    <div className="w-full">
                        <div>
                            {hodlUntil && (
                                <div className="flex items-center text-gray-500 font-bold">
                                    HODL Until{" "}
                                    <span className="font-normal ml-1">
                                        {format(new Date(hodlUntil), 'dd-MM-yyyy HH:mm')}
                                    </span>
                                    <div className="flex justify-end">
                                        <span ref={helpRef} className="ml-2 text-gray-500 h-5 cursor-pointer">
                                            <Icon className="ml-2" name="info" size="xl" color="gray" />
                                        </span>
                                        <Tooltips placement="right" ref={helpRef}>
                                            <TooltipsContent>There is an active HODL on this investment, withdrawals are disallowed until this date</TooltipsContent>
                                        </Tooltips>
                                    </div>
                                </div>
                            )}
                            {lastInteracted && !hodlUntil && (
                                    <p className="text-gray-500 font-bold">
                                        Last interacted with{" "}
                                        <span className="font-normal">
                                            {lastInteracted}
                                        </span>
                                    </p>
                                )}
                        </div>
                        <div className="flex items-center mt-2">
                            <h3 className="text-orange italic">Pending {bitorsat(pending)}</h3>
                            <div className="flex justify-end">
                                <span ref={helpRef} className="ml-2 text-gray-500 h-5 cursor-pointer">
                                    <Icon className="ml-2" name="help" size="xl" color="gray" />
                                </span>
                                <Tooltips placement="right" ref={helpRef}>
                                    <TooltipsContent>Any investment has to approved by a Venturebay administrator before it is added to a fund. This amount reflects the total pending amount related to this fund.</TooltipsContent>
                                </Tooltips>
                            </div>
                        </div>
                        <div className="flex pt-3 pb-1 w-full">
                            {noHodl && <Button
                                color={hodlUntil ? 'gray' : 'green'}
                                disabled={hodlUntil ? true : false}
                                className={`${!hodlUntil ? "text-black" : "text-white"}`}
                                size="sm"
                                onClick={onWithdrawClick}
                            >
                                Withdraw
                            </Button>}
                            <Button
                                color='green'
                                className={`text-black ${noHodl ? 'ml-5' : ''}`}
                                size="sm"
                                onClick={onShowFundTransactionsClick}
                            >
                                View Transactions
                            </Button>

                            {!noHodl && <div className="justify-self-end ml-auto">
                                
                                <span ref={helpRef} className="ml-2 text-gray-500 h-5 cursor-pointer">
                                    <Icon name="lock" color="gray" size="3xl" />
                                </span>
                                <Tooltips placement="bottom" ref={helpRef}>
                                    <TooltipsContent>HODL is currently active on this account</TooltipsContent>
                                </Tooltips>
                            </div>}
                        </div>
                    </div>
                </CardStatusFooter>
            </Card>
            <WithdrawFromFundToWalletModal
                show={showWithdrawModal}
                setShow={setShowWithdrawModal}
                fund={activeFund}
                currentAmount={amount}
            />
            <FundTransactionsModal
                show={showFundTransactionsModal}
                setShow={setShowFundTransactionsModal}
                transactions={transactions}
                fund={fund}
                title={title}
            />
        </div>
    );
}
