import { useState } from 'react'

import Progress from '@material-tailwind/react/Progress'

// TODO: Below may be used at later stage...
// import GettingToKnowYou1 from 'components/verify/GettingToKnowYou1';
// import GettingToKnowYou2 from 'components/verify/GettingToKnowYou2';
// import SourceOfIncome from 'components/verify/SourceOfIncome';

// LEVEL 1
import IdAddress from 'components/verify/level1/IdAddress'

// LEVEL 2
import SelectIdentificationType from 'components/verify/level2/SelectIdentificationType'
import IdentificationPhotos from 'components/verify/level2/IdentificationPhotos'
import Selfie from 'components/verify/level2/Selfie'

// LEVEL 3
import ProofOfResidence from 'components/verify/level3/ProofOfResidence'

// SHARED
import Expectations from 'components/verify/shared/Expectations'
import Fin from 'components/verify/shared/Fin'

import BackButton from 'components/buttons/BackButton'
import NextButton from 'components/buttons/NextButton'

import { useParams } from 'react-router'

import { useGlobal } from 'hooks/useGlobalState'
import { useAuth } from 'hooks/useAuth'

import {displayAlertModal} from 'utils';

import { Helmet } from 'react-helmet';

let numStages = 1

export default function Verify() {
    // 1
    const [idNumber, setIdNumber] = useState('')
    const [address, setAddress] = useState('')

    // 2
    const [idType, setIdType] = useState(null)
    const [frontId, setFrontId] = useState('')
    const [backId, setBackId] = useState('')
    const [selfie, setSelfie] = useState('')

    const { level, stage } = useParams()

    const { add } = useGlobal();
    const {commit} = useAuth();

    if (level === '2') numStages = 5
    if (level === '3') numStages = 3

    const percentageComplete = (stage / numStages) * 100

    const submit = {
        '1': async () => {
            const body = {
                address_physical: address,
                identification_number: idNumber,
            }

            const isSuccessful = await commit(body, '/user/useraddressandid');

            if(!isSuccessful) displayAlertModal('Something went wrong, please try again later.');
            
            if(isSuccessful) return isSuccessful;
        },
        '2': async () => {
            const body = {
                front_picture_data: frontId,
                back_picture_data: backId,
                user_picture_data: selfie,
            }

            const isSuccessful = await add(body, '/user/idupload');

            if(!isSuccessful) displayAlertModal('Something went wrong, please try again later.');
            
            if(isSuccessful) return isSuccessful;
        },
        '3': async (doc) => {
            const body = {
                resisdent_doc: doc, // spelling mistake or nah? (spelt like this in the rest file too)
            }

            const isSuccessful = await add(body, '/user/porupload')
            if(!isSuccessful) displayAlertModal('Something went wrong, please try again later.');

            if(isSuccessful) return isSuccessful;
        },
    }

    const VERIFY_COMPONENTS = {
        '1': {
            'id-address': (
                <IdAddress
                    idNumber={idNumber}
                    setIdNumber={setIdNumber}
                    address={address}
                    setAddress={setAddress}
                    submit={submit['1']}
                />
            ),
            complete: <Fin />,
        },
        '2': {
            expectations: <Expectations level={level} />,
            'id-type': <SelectIdentificationType setIdType={setIdType} />,
            'id-proof': (
                <IdentificationPhotos
                    idType={idType}
                    frontId={frontId}
                    setFrontId={setFrontId}
                    backId={backId}
                    setBackId={setBackId}
                />
            ),
            selfie: (
                <Selfie selfie={selfie} setSelfie={setSelfie} submit={submit['2']} />
            ),
            complete: <Fin />,
        },
        '3': {
            expectations: <Expectations level={level} />,
            'proof-of-residence': (
                <ProofOfResidence
                    submit={submit['3']}
                />
            ),
            complete: <Fin />,
        },
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>KYC - Venturebay</title>
            </Helmet>
            <div className="h-screen flex items-center flex-col relative">
                {level !== '1' && (
                    <div className="w-full">
                        <Progress
                            color="gray"
                            value={percentageComplete}
                            percentage={false}
                        />
                    </div>
                )}

                <div className=" absolute left-1 top-5 md:top-3">
                    <BackButton />
                </div>

                {stage !== 1 && stage !== numStages && level !== '1' && (
                    <div className=" absolute right-1 top-5 md:top-3">
                        <NextButton />
                    </div>
                )}

                <div className="max-w-full md:max-w-3xl px-3 md:px-8 flex items-center flex-col">
                    <div className="h-12 md:h-5" />
                    {VERIFY_COMPONENTS[level][stage]}
                    <div className="h-10" />
                </div>
            </div>
        </>
    )
}
