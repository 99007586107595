
function Svg404Image(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 378.67 229.33"
      {...props}
    >
      <defs>
        <style>{".404-image_svg__b{fill:#2a2a2a}"}</style>
      </defs>
      {/* <path fill="#fff" d="M0 0h378.67v229.33H0z" /> */}
      <path
        className="404-image_svg__b"
        d="M79.3 47l-56.45 83.1v29.33H94v21.78h43.56v-20s-16.93-17.33-12.49-58.21l-31.55.44v21.34l-18.67-.89L126 47z"
      />
      <path
        className="404-image_svg__b"
        d="M239.24 165.83a62.88 62.88 0 1121.64-68.22L266.8 89a71.43 71.43 0 10-14.75 76.78z"
      />
      <path
        className="404-image_svg__b"
        d="M286.48 52.51h46.22l-51.56 77.33h19.11l.45-22.66 42.22.44v22.22h18.22v34.67h-19.11l-.44 22.22h-43.11l.44-24.89-68.45.45v-28l56.01-81.78zM201.14 112.37s4.68-.54 4.72-5.05-7.16-5-7.16-5l-4.95-.14-.21 10.65a30.88 30.88 0 007.6-.46zM200.3 118.36l-6.59-.26-.1 11.48h8.15s6-.44 6.46-5.71-7.92-5.51-7.92-5.51z"
      />
      <path
        className="404-image_svg__b"
        d="M226.14 135.05L253 95.81A56.68 56.68 0 10231.9 163h-5.76zm-10.27-3c-3.54 4.21-12.6 3.94-12.6 3.94l-.25 8.73-5.21.14v-8.56l-4-.26-.22 8.83-5.27-.06-.27-8.71h-10.4l.89-6.22a32 32 0 003.63-.06c2.64-.13 2.43-1.79 2.43-1.79l-.1-1.69-.13-20.84c.14-3.68-2.63-3.62-2.63-3.62h-4.34l.11-5.49h10.6l-.2-8.83 5.51-.22-.12 8.81 4.43-.26-.13-8.54 5.34-.06-.1 8.91c12.23.55 12.93 7.54 12.93 7.54 1 9-4.6 10.16-4.6 10.16 8.82 2.7 7.8 9.27 7.8 9.27a13.14 13.14 0 01-3.1 8.92z"
      />
    </svg>
  );
}

export default Svg404Image;