import { Paragraph, Heading3 } from "@material-tailwind/react";

export default function CheckYourEmail() {

    return (
        <div className="w-full text-center">
            <p>{`{icon goes here}`}</p>
            <Heading3 color="white">Check your email</Heading3>
            <Paragraph color="white">
                We've sent you a magic link that'll sign you into Venture Bay.
            </Paragraph>
            <Paragraph color="white">
                Please click the 'Verify Email' button to continue with your verification.
            </Paragraph>
            <Paragraph color="white">
                Cant find the email? Check your spam folder.
            </Paragraph>
        </div>
    );
}
