import React from 'react'
import { Player } from 'video-react';

const VideoPlayer = () => {
    return (
        <Player
            playsInline
            poster='/images/logos/Luno-logo.jpg'
            src='/assets/videos/LunoVideo.mp4'
        />
      );
};

export default VideoPlayer