import {useState, useEffect} from 'react';

import 'assets/styles/core.css'

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import Modal from 'components/general/Modal';

import {Link, useHistory} from 'react-router-dom';

import {useAuth} from 'hooks/useAuth';

import SvgLogo from 'assets/svg-icons/SvgLogo';

import { Helmet } from 'react-helmet';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState({});
    const [incorrectDetailsShow, setIncorrectDetailsShow] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    const {login} = useAuth();
    const history = useHistory();

    useEffect(() => {
        const listener = (event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                onLoginClick();
            }
        };
        document.removeEventListener("keydown", listener);
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [email, password, error]);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    function onLoginClick() {
        let tempErrors = {};

        if(!email) {
            tempErrors.email = 'Please enter your email to continue';
        }

        if(!password) {
            tempErrors.password = 'Please enter your password to continue';
        }
        
        if(tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            setError(tempErrors);

            return;
        }

        login(email, password).then((value) => !value ? setIncorrectDetailsShow(true) : history.push('/'));
        
        return;
        
    }

    function clearError(key) {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError(tempErrors);
        }
    }

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Login - Venturebay</title>
            <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div className="h-screen px-3 md:px-8 flex items-center justify-center flex-col bg-image">
            {/* <h3 className="font-normal text-4xl text-white">Log in to Venture Bay</h3> */}
            <div className="h-2" />
            <Card className="max-w-3xl text-center bg-primary"> 
                <div className="w-full flex justify-center">
                    <SvgLogo className="w-40 h-auto sb-noclose mt-2"/>
                </div>
                <div className="h-2" />
                {/* <Paragraph color="white">Welcome back, enter your email and password to access your account.</Paragraph>
                <div className="h-2" /> */}
                <CardBody>
                    <div className={`${error && error.email ? "mb-10" : "mb-4"} text-left`}>
                        <Input
                            outline={false}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onFocus={() => clearError("email")}
                            type="text"
                            color="green"
                            placeholder="Email"
                            size="regular"
                            error={error && error.email}
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <div className='flex'>
                            <Input
                            outline={false}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            onFocus={() => clearError("password")}
                            type={passwordShown ? "text" : "password"}
                            color="green"
                            placeholder="Password"
                            size="regular"
                            error={error && error.password}
                        />
                        <div className='ml-5'>
                            <Button
                            color="green"
                            buttonType="filled"
                            size="regular"
                            rounded={false}
                            block={false}
                            iconOnly={true}
                            ripple="light"
                            onClick={togglePassword}
                            >
                                {!passwordShown ? 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                </svg>
                                }
                                
                            </Button>
                        </div>
                        </div>
                        
                        <Link className="text-white float-right mt-2 underline" to="/forgot-password">Forgot your password?</Link>
                    </div>
                </CardBody>
                <div className="h-5" />
                <CardFooter>
                    <Button
                        color="green"
                        className="w-full hover:bg-green hover:text-black hover:shadow-xl hover:pr-2"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={onLoginClick}
                    >
                        Login
                        <Icon name="arrow_forward" size="sm" />
                    </Button>
                    <div className="h-5" />
                    <Paragraph color="white">
                        Need an account? <Link className="text-green" to="/register">Register</Link>
                    </Paragraph>
                </CardFooter>
            </Card>
            <Modal
                    showModal={incorrectDetailsShow}
                    setShowModal={setIncorrectDetailsShow}
                    size="sm"
                >
                    <div className="p-5">
                        <h3 className="text-white font-normal text-xl mb-5">
                            Login unsuccessful
                            </h3>
                        <Paragraph color="white">
                            The email or password you entered is incorrect or you haven't registered yet. Either{" "}
                            <Link className="text-green cursor-pointer text-base font-normal" to="/register">register</Link>{" "}or{" "}
                            <span className="text-green cursor-pointer font-normal" to="/register" onClick={() => setIncorrectDetailsShow(false)}>try again</span>.
                            </Paragraph>
                        <div className="h-5" />
                        <Button
                            color="green"
                            className="w-full"
                            buttonType="filled"
                            size="lg"
                            ripple="dark"
                            onClick={() => setIncorrectDetailsShow(false)}
                        >
                            Okay
                            </Button>
                    </div>
                </Modal>
        </div>
        </>
    );
}