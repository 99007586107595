import {useState} from 'react';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import { Paragraph } from '@material-tailwind/react';

const QUESTIONS = [
    {
        q: "Can I deposit other cryptocurrencies?",
        a: "If you would like to deposit a large amount of a cryptocurrency that we currently do not support, please contact"
    },
    {
        q: "What’s the minimum amount of money I can start with?",
        a: "You can deposit as little as 50 000 Satoshi(R 300,40) into your Venturebay wallet at a time, satoshi is the smallest unit of the cryptocurrency bitcoin. The satoshi to bitcoin ratio is 100 million satoshis to one bitcoin."
    },
    {
        q: "Can I deposit in one currency or crypto and withdraw funds in another?",
        a: "You can deposit any supported digital asset or fiat currency that your bank account is linked to and you may withdraw the minimum withdrawal amount in any supported currency."
    },
    {
        q: "How long does it take for my deposit to reflect in my Venturebay account?",
        a: "While we try to process all deposits within 24 hours, weekends and public holidays can lead to delays. Attaching a proof of payment to your pending deposit can expedite the process of allocating your funds."
    },
    {
        q: "What do I do if I want to make a deposit in another currency?",
        a: "Venturebay currently only supports deposits in a supported currency, from your own bank account."
    },
    {
        q: "When can I withdraw my funds?",
        a: "You can withdraw your funds at any time. We endeavour to process withdrawals within 24 hours on workdays. We do not process withdrawals on weekends and public holidays."
    },
    {
        q: "Does Venturebay have a minimum trade amount?",
        a: "You can buy and sell any asset on Venturebay with as little as 50 000 Satoshi, satoshi is the smallest unit of the cryptocurrency bitcoin. The satoshi to bitcoin ratio is 100 million satoshis to one bitcoin."
    }
];

export default function Faq() {
    const [selected, setSelected] = useState('');

    return (
        <div className="mt-5">
            {QUESTIONS.map(({q, a}, i) => (
                <div key={i} onClick={() => selected !== i ? setSelected(i) : setSelected(null)}>
                    <Card className="mb-2 transition cursor-pointer group bg-primary">
                        <CardBody className='flex flex-col w-auto h-auto'>
                            <div className={`${selected === i ? "mb-5" : ""}`}>
                                <div className="inline left w-2/3">
                                    <span className="text-base font-normal text-white">{q}</span>
                                </div>
                                <Button
                                    color=""
                                    className="inline float-right -mt-1 w-1/3"
                                    buttonType="link"
                                    size="sm"
                                    iconOnly
                                    rounded
                                    ripple="light"
                                    onClick={() => {}}
                                >
                                        <Icon name={selected === i ? "remove" : "add"} size="2xl" color="white" />
                                </Button>
                            </div>
                            {selected === i && (
                                <div className="h-auto text-left">
                                    <Paragraph color="white">
                                        {a}
                                    </Paragraph>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </div>
            ))}
        </div>
    );
}
