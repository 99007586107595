import Icon from '@material-tailwind/react/Icon';

const Fin = ({amount, fundName}) => (
    <div className="text-white font-7xl h-96 flex flex-col items-center justify-center">
        <div className="w-full bg-primary rounded-xl overflow-hdden py-16 px-8 shadow-2xl flex h-72 flex-col items-center justify-center">
            <Icon name="check_circle_outline" size="7xl" />
            <div className="h-8 w-full" />
            <h3 className="text-2xl text-white font-normal"><span className="font-bold">{Number(amount).toFixed(8)} BTC</span> has been invested in <span className="font-bold">{fundName}</span></h3>
            <div className="h-4 w-full" />
        </div>
    </div>
);

export default Fin;