import {useEffect, useRef} from 'react';
import {store, useGlobalState} from 'state-pool';
import { useLocalStorage } from 'usehooks-ts';
// import _ from 'lodash';

// import {Fund} from 'models/fund';
// import {Transaction} from 'models/transaction';
// import {Article} from 'models/article';

import {vbApi} from 'api/vb';
import {useOffline} from './useOffline';
import {useAlert} from './useAlert';

type TimerReference = {timerReference: NodeJS.Timeout, key: string, interval: number, path: string}[];

store.setState('funds', []);
store.setState('fund', {});

store.setState('transactions', []);
store.setState('transaction', {});

store.setState('articles', []);
store.setState('article', {});

export const useGlobal = () => {
    const [funds, setFunds] = useGlobalState(`funds`);
    const [fund, setFund] = useGlobalState(`fund`);
    const [cachedFunds, setCachedFunds] = useLocalStorage<any[] | null>('funds', []);
    const [cachedFund, setCachedFund] = useLocalStorage('fund', null);

    const [transactions, setTransactions] = useGlobalState(`transactions`);
    const [transaction, setTransaction] = useGlobalState(`transaction`);
    const [cachedTransactions, setCachedTransactions] = useLocalStorage<any[] | null>('transactions', []);
    const [cachedTransaction, setCachedTransaction] = useLocalStorage('transaction', null);

    const [currentUser] = useGlobalState(`currentUser`);

    const {addToQueue,isOnline} = useOffline();
    const timer = useRef<TimerReference>([]);
    const {newAlert} = useAlert();

    useEffect(() => {
        return cleanUp;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        syncFunds();
        syncTransactions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [funds, cachedFunds, transactions, cachedTransactions]);

    useEffect(() => {
        if(!currentUser) cleanUp();
    }, [currentUser]);

    function syncFunds() {
        if(fund && !cachedFund) setCachedFund(fund);

        if(!funds && cachedFunds) {
            setFunds(cachedFunds);
        }
    }

    function syncTransactions() {
        if(transaction && !cachedTransaction) setCachedTransaction(transaction);

        if(!transactions && cachedTransactions) {
            setTransactions(cachedTransactions);
        }
    }

    async function load(path: string, key: string) {
        const online = isOnline();

        if(!online) {
            addToQueue('GET', path);

            loadCache();

            return;
        }
        // get key
        // set global state at appropriate key with response
        try {
            const res = await vbApi.get(path);

            if(res && res.data) {
                setKey(key, res.data);
            } else if(res) {
                setKey(key, res);
            }
        } catch(err: any) {
            console.log(err);

            setKey(key, []);
            //adds to network queue
            addToQueue('GET', path);
        }
    }

    function loadCache() {
        setFunds(cachedFunds);
        setTransactions(cachedTransactions);
        setFund(cachedFund);
        setTransaction(cachedTransaction);
    }

    function setKey(key: string, data: any) {

        switch(key) {
            case 'funds': {
                setCachedFunds(data);
                setFunds(data);
                break;
            }
            case 'fund': {
                setCachedFund(data);
                setFund(data);
                break;
            }
            case 'transactions': {
                setCachedTransactions(data);
                setTransactions(data);
                break;
            }
            case 'transaction': {
                setCachedTransaction(data);
                setTransaction(data);
                break;
            }
        }
    }

    async function refresh(path: string, key: string) {
        const online = isOnline();

        if(!online) {
            return;
        }
        // gets users
        // reset users with response
        try {
            const res = await vbApi.get(path);

            if(res && res.data) {
                setKey(key, res.data);
            }
        } catch {
            //adds to network queue
            addToQueue('GET', path);
        }
    }

    async function add(data: any, path: string, key?: string) {
        const online = isOnline();

        if(!online) {
            addToQueue('POST', path, data);
            //TODO
            newAlert('danger', 'No network connection', 'Data will be submitted when connection is restored');
            //show alert for no network
            return;
        }

        try {
            // adds new data
            const res = await vbApi.post(path, data);

            if(res) return res;

            // refreshes once complete
            if(key) refresh(path, key);
        } catch(err: any) {
            addToQueue('POST', path, data);
            return;
        }
    }

    function autoRefresh(interval: number, path: string, key: string) {
        const online = isOnline();

        if(!online) return;
        // setInterval with a refresh rate
        // s and sets key at interval with refresh function
        let timerReference = setInterval(() => {
            refresh(path, key);
        }, interval * 1000);

        timer.current = [...timer.current, {
            timerReference,
            key,
            interval,
            path
        }];
    }

    async function remove(id: string, path: string, key: string) {
        const online = isOnline();

        if(!online) {
            addToQueue('DELETE', `${path}/${id}`);
            //TODO
            newAlert('danger', 'No network connection', 'Item will be deleted when connection is restored');

            //show no network
            return;
        }

        try {
            // updates user with updated user object
            await vbApi.delete(`${path}/${id}`);

            // refreshes once complete
            refresh(path, key);
        } catch (err) {
            addToQueue('DELETE', `${path}/${id}`);
            return;
        }
    }

    async function commit(data: any, id: string, path: string, key: string) {
        const online = isOnline();

        if(!online) {
            addToQueue('PUT', `${path}/${id}`, data);
            // TODO
            newAlert('danger', 'No network connection', 'Item will be updated when connection is restored');

            // show no network
            return;
        }

        try {
            // updates data with updated data object
            await vbApi.put(`${path}/${id}`, data);

            // refreshes once complete
            refresh(path, key + 's');
        } catch (err) {
            addToQueue('PUT', `${path}/${id}`, data);
            return;
        }
    }

    function cleanUp() {
        if(timer && timer.current) {
            timer.current.forEach(({timerReference}: {timerReference: NodeJS.Timeout}) => {
                clearInterval(timerReference);
            });
        };

        setCachedTransaction(null);
        setCachedFund(null);
    }

    return {
        load,
        add,
        commit,
        refresh,
        autoRefresh,
        remove,
        store,
        funds,
        fund,
        transaction,
        transactions
    }
}

// UNUSED SYNC FUNCTIONALITY

// function sync() {

//     // FUNDS

//     // update

//     if(fund && (cachedFund && typeof cachedFund === 'object' && !_.isEqual(fund, cachedFund))) {
//         commit(fund, fund.id, '/users', 'user');
//     }

//     if(fund && !cachedFund) setCachedFund(fund);

//     if(funds && !_.isEqual(funds, cachedFunds)) {
//         // add
//         if(Array.isArray(cachedFunds) && funds.length > cachedFunds.length) {
//             add(funds[funds.length - 1], '/users', 'users');
//         }

//         // delete
//         if(Array.isArray(cachedFunds) && funds && (funds.length > 0) && funds.length < cachedFunds.length) {
//             const removeNullValues = cachedFunds.filter(item => item);
//             const result = removeNullValues.filter(item => item && (funds.indexOf(item) === -1));

//             result.map((f: Fund) => remove(String(f.id), '/funds', 'funds'));
//         }

//         //update
//         if(Array.isArray(cachedFunds) && funds && (funds.length > 0) && funds.length === cachedFunds.length) {
//             const result = cachedFunds.filter((item: Fund, i: number) => !_.isEqual(item, funds[i]));

//             console.log('CHANGED', result);
//         }
//     }

//     if(cachedFunds && (!funds || (funds && (funds.length === 0)))) setFunds(cachedFunds);

//     // TRANSACTIONS

//     // update

//     if(transaction && (cachedTransaction && typeof cachedTransaction === 'object' && !_.isEqual(transaction, cachedTransaction))) {
//         commit(transaction, transaction.id, '/transaction', 'transaction');
//     }

//     if(transaction && !cachedTransaction) setCachedTransaction(transaction);

//     if(transactions && !_.isEqual(transactions, cachedTransactions)) {
//         // add
//         if(Array.isArray(cachedTransactions) && transactions.length > cachedTransactions.length) {
//             add(transactions[transactions.length - 1], '/transactions', 'transactions');
//         }

//         // delete
//         if(Array.isArray(cachedTransactions) && transactions && (transactions.length > 0) && transactions.length < cachedTransactions.length) {
//             const removeNullValues = cachedTransactions.filter(item => item);
//             const result = removeNullValues.filter(item => item && (transactions.indexOf(item) === -1));

//             result.map((t: Transaction) => remove(String(t.id), '/transactions', 'transactions'));
//         }

//         //update
//         if(Array.isArray(cachedTransactions) && transactions && (transactions.length > 0) && transactions.length === cachedTransactions.length) {
//             const result = cachedTransactions.filter((item: Transaction, i: number) => !_.isEqual(item, transactions[i]));

//             console.log('CHANGED', result);
//         }
//     }
// }