import axios from "axios";

import {checkTokens} from 'utils/auth';

// import {displayAlert} from 'utils'; 

const vbApi = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

// request middleware
vbApi.interceptors.request.use(async function (config: any) {
    
    const tokens = await checkTokens();

    if(tokens && typeof tokens === "object" && tokens.accessToken) {
        config.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }

    return config;
}, function (error: any) {
    return Promise.reject(error);
});


vbApi.interceptors.response.use(async function (response: any) {
    
    if((response & response.data && response.data.statusCode !== 0) || !response) {
        // show alert

        // displayAlert('Something went wrong', 'There was an issue performing the request', 'danger');
    }

    return response;
}, function (error: any) {

    // displayAlert('Something went wrong', 'There was an issue performing the request', 'danger');
    return Promise.reject(error);
});

export {
    vbApi
};