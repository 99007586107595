import {useState} from 'react';

import {useHistory} from 'react-router-dom';

import Input from '@material-tailwind/react/Input';
import { Textarea } from '@material-tailwind/react';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Button from '@material-tailwind/react/Button';
import Paragraph from '@material-tailwind/react/Paragraph';
import Icon from '@material-tailwind/react/Icon';

import {useGlobalState} from 'state-pool';
import {useAuth} from 'hooks/useAuth';

export default function IdAddress({idNumber, setIdNumber, address, setAddress, submit}) {
    const [error, setError] = useState({});
    const history = useHistory();
    const [currentUser] = useGlobalState('currentUser');
    const {commit} = useAuth();

    const onSubmitClick = async () => {
        let tempErrors = {};

        if(!address) {
            tempErrors.address = 'Please enter your address'
        }

        if(!idNumber) {
            tempErrors.idNumber = 'Please enter your ID number'
        }

        if(idNumber && idNumber.length !== 13) {
            tempErrors.idNumber = 'Please enter a valid South African ID number'
        }

        if(typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            setError(tempErrors);

            return;
        }

        const isSuccessfulSubmit = await submit();

        const isSuccessfulUpdateUser = await commit({...currentUser, kyc_pending: 1}, '/user/edituser');
        
        if(isSuccessfulSubmit && isSuccessfulUpdateUser) history.push('/verify/1/complete');
    }

    function clearError(key) {
        if(key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError(tempErrors);
        }
    }

    return (
        <div className="w-full text-left">
        <div className="h-5" />
        <h3 className="font-normal text-4xl text-white">Additional details</h3>
        <Paragraph color="white">
            Please enter your residential address and provide us with your ID number.
        </Paragraph>
        <div className="h-7" />
        <Card className="text-center bg-primary">  
            <div className="h-2" />
            <CardBody>

            <div className="mb-4 text-left">
                <Input
                    outline={false}
                    value={idNumber}
                    onChange={e => setIdNumber(e.target.value)}
                    onFocus={() => clearError('idNumber')}
                    type="text"
                    color="green"
                    size="regular"
                    error={error && error.idNumber}
                    placeholder="ID Number"
                />
            </div>

            <div className="mb-4 mt-10 text-left">
                <Textarea
                    outline={false}
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    onFocus={() => clearError('address')}
                    type="text"
                    color="green"
                    size="regular"
                    error={error && error.address}
                    placeholder="Address"
                />
            </div>
            </CardBody>
            <CardFooter className="text-left">
                {/* <p className="font-semibold text-black">
                    Accepted documents
                </p> */}
                <div className="mb-0 text-left">
                    <div className="inline-block">
                        <span className="w-3 h-3 bg-green text-black rounded-full flex items-center justify-center" />
                    </div>
                    <span className="inline-block ml-2 text-white text-xs md:text-sm font-normal leading-relaxed">
                        Verification level 2 will ask you to provide proof for the above
                    </span>
                </div>
                <div className="mb-0 text-left">
                    <div className="inline-block">
                        <span className="w-3 h-3 bg-green text-black rounded-full flex items-center justify-center" />
                    </div>
                    <span className="inline-block ml-2 text-white text-xs md:text-sm font-normal leading-relaxed">
                        Only South African ID numbers are allowed
                    </span>
                </div>
            </CardFooter>
            </Card>
                <div className="h-5" />
                <Button
                    color="green"
                    
                    className="hover:bg-green hover:text-black w-full hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onSubmitClick}
                >
                    Submit
                    <Icon name="arrow_forward" size="sm" />
                </Button> 
            </div>
    );
}
