import { useState, useEffect } from 'react';

import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
// import Button from '@material-tailwind/react/Button';

import { isValidJson } from 'utils';
import { bitorsat } from 'utils/js';
import { useGlobalState } from 'state-pool';
import { format } from 'date-fns';
import WithdrawFromFundToWalletModal from 'components/withdraw/WithdrawFromFundToWalletModal';
import Pagination from 'components/general/Pagination';

const PER_PAGE = 10;

export default function TransactionsTable({selected}) {
    const [transactions] = useGlobalState('transactions');
    const [funds] = useGlobalState('funds');
    const [activeTransaction] = useState(null);
    const [transactionsDisplay, setTransactionsDisplay] = useState(transactions.results);
    const [activeFund] = useState(null);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [numPages, setNumPages] = useState(1);
    const [showFrom, setShowFrom] = useState(0);
    const [showTo, setShowTo] = useState(PER_PAGE);

    // Validate btc public key
    const regex = new RegExp(/([13]|bc1)[A-HJ-NP-Za-km-z1-9]{27,34}/)
    /* eslint-disable */
    useEffect(() => {
        if(!selected || selected === 'all') {
            setTransactionsDisplay([...transactions.results].sort().reverse());
            return;
        }

        const filteredTransactions = transactions && transactions.results && transactions.results.filter(transaction => transaction && transaction.misc && isValidJson(transaction.misc) && JSON.parse(transaction.misc).status === selected);
        
        if (filteredTransactions){
            setTransactionsDisplay([...filteredTransactions].sort().reverse());
        }

        return;
        //setTransactionsDisplay([...filteredTransactions]);

    }, [selected, transactions]);

    useEffect(() => {
        if(transactionsDisplay && Array.isArray(transactionsDisplay) && transactionsDisplay.length > 0) {
            const numtransactionsDisplay = transactionsDisplay.length;

            const tempNumPages = Math.ceil(numtransactionsDisplay / PER_PAGE);

            setNumPages(tempNumPages);
        }
    }, [transactionsDisplay]);

    useEffect(() => {
        setShowFrom(currentPage * PER_PAGE);
        setShowTo((currentPage * PER_PAGE) + PER_PAGE);
    }, [currentPage]);

    // function onWithdrawClick(transaction) {

    //     if(transaction && transaction.destination && transaction.destination.includes(':')) {
    //         const { destination } = transaction;
    //         let fundId = destination.split(':')[1];

    //         setActiveTransaction(transaction);

    //         if(fundId) {
    //             const relatedFund = funds.find(f => f.id === Number(fundId));

    //             if(relatedFund) {
    //                 setActiveFund(relatedFund);
        
    //                 setShowWithdrawModal(true);
    //             }
    //         }
    //     }
    // }

    const getTransactionStatus = (transaction) => transaction && transaction.misc && isValidJson(transaction.misc) && JSON.parse(transaction.misc).status && JSON.parse(transaction.misc).status;

    const getTransactionDestination = (transaction) => funds && transaction && transaction.destination && transaction.destination.includes('fund') && transaction.destination.split(':')[1]  && funds.find(fund => fund.id === (Number(transaction.destination.split(':')[1]))) && funds.find(fund => fund.id === (Number(transaction.destination.split(':')[1]))).name;

    const getBitcoinTransaction = (transaction) => transaction && transaction.confirmation_count > 1 && transaction.is_confirmed ===1 && transaction.block_height !== 0 ? "Confirmed" : transaction.block_height !== 0 && "Unconfirmed";
    
    const getStatusColor = (transaction) => transaction && transaction.misc && isValidJson(transaction.misc) && JSON.parse(transaction.misc).status === "success" ? "text-success" : transaction.is_confirmed ===1 && transaction.block_height !== 0 ? "text-success" : "text-orange";
    
    return (
        <>
            <Card className="bg-primary">
                <CardHeader color="gray" contentPosition="left">
                    <h2 className="text-white text-2xl">Transactions</h2>
                </CardHeader>
                <CardBody>
                    <div className="overflow-x-auto">
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                                <tr>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Date and time
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Type
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Amount before fees (BTC)
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Fee (BTC)
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Amount after fees (BTC)
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Status
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Destination (Fund)
                                    </th>
                                    {/* <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {transactionsDisplay && Array.isArray(transactionsDisplay) && transactionsDisplay.length > 0 && transactionsDisplay.slice(showFrom, showTo).map((transaction, i) => (
                                    <tr key={i}>
                                        <td className="border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            {/* TODO format below */}
                                            {transaction.created_at && format(new Date(transaction.created_at), 'dd-MM-yyyy HH:mm')}
                                        </td>
                                        <td className="border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            {/* TODO needs logic on destination to determine display string */}
                                            {transaction.destination.includes('user') && transaction.src.includes('fund') && "Fund Payout"}
                                            {transaction.destination.includes('fund') && transaction.src.includes('user') && "Fund Deposit"}
                                            {regex.test(transaction.destination) && transaction.src.includes('user') ? 'Withdrawal' : regex.test(transaction.destination) && transaction.src == 'External' ? 'Deposit': ' '}
                                        </td>
                                        <td className="border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            {/* TODO convert to decimal w/ 2 points & add currency symbol */}
                                            {" "}{transaction.amount && bitorsat(transaction.amount, 'top')}
                                        </td>
                                        <td className="border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            {/* TODO convert to decimal w/ 2 points & add currency symbol */}
                                            {" "}{transaction.fee_total && bitorsat(transaction.fee_total, 'top')}
                                        </td>
                                        <td className="border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            {/* TODO convert to decimal w/ 2 points & add currency symbol */}
                                            {/* TODO double check below logic once clarified on fee variables */}
                                            {transaction.fee_total === 0 ? bitorsat(transaction.amount, 'top') : transaction.amount ? (bitorsat((transaction.amount + transaction.fee_total), 'top')) : ' ' }
                                        </td>
                                        <td className={`border-b capitalize border-gray-200 align-middle ${getStatusColor(transaction)} font-normal text-sm whitespace-nowrap px-2 py-4 text-left`}>
                                            {/* TODO logic based on is_confirmed number to display string */}
                                            {/* {transaction.is_confirmed === 1 ? 'Successful' : 'Declined'} */}
                                            {getTransactionStatus(transaction)}
                                            {getBitcoinTransaction(transaction)}
                                            
                                        </td>
                                        <td className={`border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left`}>
                                            {/* TODO logic based on is_confirmed number to display string */}
                                            {/* {"R"}{" "}{transaction.your_balance}{" "}<Icon color="green" name="arrow_upward" size="sm" /> */}
                                            {/* {transaction.id} */}
                                            {getTransactionDestination(transaction)} 
                                            
                                            {transaction.destination && transaction.destination.includes('user') && "Personal"}
                                            {regex.test(transaction.destination) && transaction.destination}
                                        </td>
                                        {/* <th className={`border-b border-gray-200 align-middle ${transaction.destination.includes("in") ? "text-success" : "text-red"} font-normal text-sm whitespace-nowrap px-2 py-4 text-left`}>
                                            <Button
                                                size="sm"
                                                className="ml-2"
                                                onClick={() => onWithdrawClick(transaction)}
                                            >
                                                Withdraw
                                            </Button>
                                        </th> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
            <div className='flex w-100 justify-center'>
                <Pagination 
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    numPages={numPages}
                /> 
            </div>
            <WithdrawFromFundToWalletModal
                show={showWithdrawModal}
                setShow={setShowWithdrawModal}
                transaction={activeTransaction}
                fund={activeFund}
            />
        </>
    );
}


// TODO component for no transactions
// may need icon from Dan for above