import {useState, useEffect} from 'react';

import Icon from "@material-tailwind/react/Icon";
import Button from '@material-tailwind/react/Button';

import Modal from './Modal';

export default function AlertModal() {
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        window.addEventListener('display-alert-modal', (e) => {
            setShowModal(true);
            setMessage(e.detail.message);
        });
    }, []);

    return (
        <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            size="sm"
        >
            <div className="p-3">
                <div className="h-auto w-full flex flex-col items-center justify-center">
                    <Icon name="error_outline" size="9xl" color="white" />
                    <div className="h-5" />
                    <h2 className="text-white">{message}</h2>
                </div>
                <div className="h-8" />
                <Button
                    color="green"
                    className="w-full"
                    buttonType="filled"
                    size="lg"
                    ripple="dark"
                    onClick={() => setShowModal(false)}
                >
                    Okay
                </Button>
            </div>
        </Modal>
    )
}
