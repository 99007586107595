import {useState} from 'react';

import Progress from '@material-tailwind/react/Progress';

// import ChooseWithdrawalMethod from 'components/withdraw/ChooseWithdrawalMethod';
import ChooseWithdrawalType from 'components/withdraw/ChooseWithdrawalType';
import WithdrawalAmount from 'components/withdraw/WithdrawalAmount';
import BitcoinAddress from 'components/withdraw/BitcoinAddress';
import BankDetails from 'components/withdraw/BankDetails';
import Fin from 'components/withdraw/Fin';

import BackButton from 'components/buttons/BackButton';
import { useHistory } from 'react-router';
import {useGlobalState} from 'state-pool';
import { Helmet } from 'react-helmet';

export default function Withdraw() {
    const [stage, setStage] = useState(1);
    const [amount, setAmount] = useState('');
    const [type, setType] = useState(null);
    const [currentUser] = useGlobalState('currentUser');

    const history = useHistory();

    const percentageComplete = String((stage / 4) * 100);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Withdraw - Venturebay</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="h-auto flex items-center justify-center flex-col relative">
                <div className="w-full absolute top-0 right-0 left-0">
                    <Progress color="gray" value={percentageComplete} percentage={false} />
                </div>
                <div className="px-3 md:px-8 md:w-full">
                    <div className="absolute left-1 xs:top-5 md:top-3">
                        <BackButton onClick={() => {stage !== 1 ? setStage(stage - 1) : history.goBack()}} />
                    </div>

                    <div className="max-w-3xl flex items-center flex-col mx-auto">
                        {stage === 1 && <WithdrawalAmount amount={amount} setAmount={setAmount} stage={stage} setStage={setStage} />}
                        {stage === 2 && <ChooseWithdrawalType stage={stage} setStage={setStage} setType={setType} />}
                        {stage === 3 && type === "zar" && <BankDetails stage={stage} setStage={setStage} />}
                        {stage === 3 && type === "btc" && <BitcoinAddress stage={stage} setStage={setStage} amount={amount} user={currentUser} />}
                        {stage === 4 && <Fin amount={amount} />}
                    </div>
                </div>
            </div>
        </>
    );
}