import { useState, useEffect } from 'react';
import axios from 'axios';
import Chart from 'chart.js';

import {numberWithSpaces} from 'utils';
import {useOffline} from 'hooks/useOffline';

import { format } from 'date-fns';

const INITIAL_TIME_VALUE = { 
    val:'histohour', 
    limit : '24' 
};

export default function BtcChartController() {
    const [price, setPrice] = useState(null);
    const [time, setTime] = useState(null);
    const [error, setError] = useState('');
    const {isOnline} = useOffline();
    const [timeValue, setTimeValue] = useState(INITIAL_TIME_VALUE);

    const options = [
        {
            value: {
                val: 'histoday',
                limit: '30'
            },
            label: 'Last Month'
        },
        {
            value: {
                val: 'histohour',
                limit: '24'
            },
            label: 'Last Day'
        },
        {
            value: {
                val: 'histominute',
                limit: '60'
            },
            label: 'Last Hour'
        }
    ];

    ///  Calling API and modeling data for each chart ///
    const btcData = async () => {
        let online = isOnline();

        const {val, limit} = timeValue;

        if(online) {
            const response = await axios.get(
                `${process.env.REACT_APP_CRYPTO_COMPARE_API}/data/v2/${val}?fsym=BTC&tsym=ZAR&limit=${limit}&api_key=${process.env.REACT_APP_CRYPTO_COMPARE_KEY}`,
            );

            if(response && response.data && response.data.Data && response.data.Data.Data) {
                const data = response.data.Data.Data;

                const times = data.map((obj) => obj.time);
                const prices = data.map((obj) => obj.high);

                return {
                    times,
                    prices,
                }
            }
    
            setError('Not currently available');

            return false;
        }

        setError('No network connection');

        return false;
    }

    async function printBtcChart() {
        let chartData = await btcData();

        if(chartData && chartData.times && chartData.prices && !error) {
            const { times, prices } = chartData;

            try {
                let btcChartContainer = document.getElementById('btcChartContainer');
                let btcChartElement = document.getElementById('btcChart');
                btcChartElement.remove();
                let btcChartElementNew = document.createElement("canvas");
                btcChartElementNew.setAttribute('id', 'btcChart');
                btcChartContainer.append(btcChartElementNew);
                let btcChart = document.getElementById('btcChart').getContext('2d');

                let gradient = btcChart.createLinearGradient(0, 0, 0, 400)

                gradient.addColorStop(0, 'rgba(255, 201, 48, .5)')
                gradient.addColorStop(0.425, 'rgba(255,193,119,0)')
    
                Chart.defaults.global.defaultFontFamily = 'Lato';
                Chart.defaults.global.defaultFontSize = 16;
                Chart.defaults.global.defaultWeight = 300;
    
                new Chart(btcChart, {
                    type: 'line',
                    data: {
                        labels: times,
                        datasets: [
                            {
                                label: 'R',
                                data: prices,
                                backgroundColor: gradient,
                                borderColor: 'rgba(255, 201, 48, 1)',
                                borderJoinStyle: 'round',
                                borderCapStyle: 'round',
                                borderWidth: 3,
                                pointRadius: 0,
                                pointHitRadius: 10,
                                lineTension: 0.2,
                            },
                        ],
                    },
                    options: {
                        title: {
                            display: false,
                            text: 'Bitcoin',
                            fontSize: 35,
                        },
    
                        legend: {
                            display: false,
                        },
    
                        layout: {
                            padding: {
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                            },
                        },
    
                        scales: {
                            xAxes: [
                                {
                                    display: false,
                                    gridLines: {},
                                },
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    gridLines: {},
                                },
                            ],
                        },
    
                        tooltips: {
                            callbacks: {
                                //This removes the tooltip title
                                title: function (context) {
                                    context = context[0] && context[0]
                                    ;
                                    if(context && context.xLabel) { 
                                        const time = format(new Date(context.xLabel * 1000), 'dd-MM-yyyy HH:mm');
    
                                        return `${time}`;
                                    }
                                },
                                label: (context) => {

    
                                    if(context && context.yLabel) { 
                                        const priceFormatted = numberWithSpaces(context.yLabel);
    
                                        return `R ${priceFormatted}`;
                                    }
                                }
                            },
                            //this removes legend color
                            displayColors: false,
                            yPadding: 10,
                            xPadding: 10,
                            caretSize: 10,
                            backgroundColor: 'rgba(0,0,0,.9)',
                            bodyFontSize: 16,
                            bodyFontColor: '#ffffff',
                            usePointStyle: true,
                            xAlign: 'left',
                        },
                    },
                })
            } catch(e){
                return;
            }
        } 
    }

    async function updateBitcoinPrice() {
        let { times, prices } = await btcData();

        let currentPrice = prices[prices.length - 1].toFixed(2);
        let currentTime = times[times.length - 1] * 1000;

        const priceFormatted = numberWithSpaces(currentPrice);

        setPrice(priceFormatted);

        setTime(currentTime);
    }

    useEffect(() => {
        let online = isOnline();

        if(online) {
            printBtcChart();
            updateBitcoinPrice();
        }

        if(!online) setError('No network connection');

        return function cleanup() {
            let btcChart = document.getElementById('btcChart');

            if(btcChart && btcChart.innerHTML) btcChart.innerHTML = '';
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeValue]);

    return {
        error,
        timeValue,
        setTimeValue,
        options,
        time,
        price
    };
}
