import {useRef} from 'react';

import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import Icon from "@material-tailwind/react/Icon";

import Modal from 'components/general/Modal';

import {isValidDate, isValidJson} from 'utils';
import {bitorsat} from 'utils/js';

import {format} from 'date-fns';

export default function FundTransactionsModal({show, setShow, fund, transactions, title}) {
    const helpRef = useRef();

    const getStatusColor = (transaction) => transaction && transaction.misc && isValidJson(transaction.misc) && JSON.parse(transaction.misc).status === "success" ? "text-success" : transaction.is_confirmed ===1 && transaction.block_height !== 0 ? "text-success" : "text-orange";
    
    const TransactionCol = ({transaction}) => (
        <div className="grid grid-cols-3">
            {transaction && (
                <>
                    <div className="text-white text-left">
                        {transaction.created_at && isValidDate(new Date(transaction.created_at)) && format(new Date(transaction.created_at), 'dd MM yyyy HH:mm')}
                    </div>
                    <div className={`text-left ${getStatusColor(transaction)}`}>
                        {transaction.misc && isValidJson(transaction.misc) && JSON.parse(transaction.misc).status}
                    </div>
                    <div className="text-white text-left">
                        {bitorsat(transaction.amount)}
                    </div>
                </>
            )}
        </div>
    );

    return (
        <Modal 
            showModal={show}
            setShowModal={setShow}
            title=" "
            size="lg"
        >   
            <div className="p-5">
                <div className="flex items-center mt-2">
                    <h3 className="font-normal text-2xl text-white">{`Transactions for ${(fund && fund.name) || title}`}</h3>
                    <div className="flex justify-end">
                        <span ref={helpRef} className="ml-2 text-gray-500 h-5 cursor-pointer">
                            <Icon className="ml-2" name="help" size="xl" color="gray" />
                        </span>
                        <Tooltips placement="right" ref={helpRef}>
                            <TooltipsContent>These are all the deposits and withdrawals related to this specific fund.</TooltipsContent>
                        </Tooltips>
                    </div>
                </div>
                <div className="h-8" />
                <div className="w-full">
                <div className="grid grid-cols-3 mb-5">
                    <div className="text-white font-bold text-left">
                        Date
                    </div>
                    <div className="text-white font-bold text-left">
                        Status
                    </div>
                    <div className="text-white font-bold text-left">
                        Amount
                    </div>
                </div>
                {transactions && Array.isArray(transactions) && transactions.map(transaction => (
                    <TransactionCol transaction={transaction} />
                ))}
                <div className="h-8" />
                <p className="text-gray-500">*All pending transactions must be approved by an administrator before being processed</p>
                </div>
            </div>
        </Modal>
    )
}
