import { Paragraph } from "@material-tailwind/react";

import Icon from "@material-tailwind/react/Icon";

export default function SuccessfulSubmissiion() {

    return (
        <div className="w-full text-center">

            <Icon color="white" name="email" size="9xl" />
            <div className="h-5" />
            <h3 className="font-normal text-4xl mb-3 text-white">Check your email</h3>
            <Paragraph color="white">
                We've sent a link that'll redirect you to our site to reset your password.
            </Paragraph>
            <Paragraph color="white">
                Cant find the email? Check your spam folder.
            </Paragraph>
        </div>
    );
}
