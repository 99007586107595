// import {Link} from 'react-router-dom';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

export default function Legal() {

    return (
        <div className="mt-5">
            <Card className="mb-2 transition cursor-pointer group bg-primary">
            <a
                href='/legal/VB-Terms-of-service.pdf'
                download={'VB-Terms-of-service.pdf'}
            >
                <CardBody>
                    <div className="relative h-5">
                        <div className="inline left">
                            <span className="text-base font-normal text-white">Terms of Service</span>
                        </div>
                        <Button
                            color=""
                            className="inline float-right -mt-1 group-hover:pl-4"
                            buttonType="link"
                            size="sm"
                            iconOnly
                            rounded
                            ripple="light"
                            onClick={() => {}}
                        >
                                <Icon name="picture_as_pdf" size="2xl" color="white" />
                        </Button>
                    </div>
                </CardBody>
            </a>
            </Card>
            <Card className="mb-2 transition cursor-pointer group bg-primary">
                <a
                    href='/legal/VB-Privacy-Policy.pdf'
                    download={'VB-Privacy-Policy.pdf'}
                >
                <CardBody>
                    <div className="relative h-5">
                        <div className="inline left">
                            <span className="text-base font-normal text-white">Privacy Policy</span>
                        </div>
                        <Button
                            color=""
                            className="inline float-right -mt-1 group-hover:pl-4"
                            buttonType="link"
                            size="sm"
                            iconOnly
                            rounded
                            ripple="light"
                            onClick={() => {}}
                        >
                                <Icon name="picture_as_pdf" size="2xl" color="white" />
                        </Button>
                    </div>
                </CardBody>
                </a>
            </Card>
            <Card className="mb-2 transition cursor-pointer group bg-primary">
                <a
                    href='/legal/VB-Risk-Disclosure-Policy.pdf'
                    download={'VB-Risk-Disclosure-Policy.pdf'}
                >
                <CardBody>
                    <div className="relative h-5">
                        <div className="inline left">
                            <span className="text-base font-normal text-white">Risk disclosure</span>
                        </div>
                        <Button
                            color=""
                            className="inline float-right -mt-1 group-hover:pl-4"
                            buttonType="link"
                            size="sm"
                            iconOnly
                            rounded
                            ripple="light"
                            onClick={() => {}}
                        >
                                <Icon name="picture_as_pdf" size="2xl" color="white" />
                        </Button>
                    </div>
                </CardBody>
                </a>
            </Card>
            <Card className="mb-2 transition cursor-pointer group bg-primary">
                <a
                    href='/legal/VB-Cookie-Policy.pdf'
                    download={'VB-Cookie-Policy.pdf'}
                >
                <CardBody>
                    <div className="relative h-5">
                        <div className="inline left">
                            <span className="text-base font-normal text-white">Cookie Policy</span>
                        </div>
                        <Button
                            color=""
                            className="inline float-right -mt-1 group-hover:pl-4"
                            buttonType="link"
                            size="sm"
                            iconOnly
                            rounded
                            ripple="light"
                            onClick={() => {}}
                        >
                                <Icon name="picture_as_pdf" size="2xl" color="white" />
                        </Button>
                    </div>
                </CardBody>
                </a>
            </Card>
        </div>
    );
}
