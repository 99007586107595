import {useState} from 'react';

import {Link} from 'react-router-dom';
import Icon from '@material-tailwind/react/Icon';
import { Button } from '@material-tailwind/react';
import { bitorsat } from 'utils/js';


import WithdrawFromFundToWalletModal from 'components/withdraw/WithdrawFromFundToWalletModal';

export default function ProductsCard({title, description, minBuyIn, yearlyChange, id, hasInvested, fund}) {
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);
    const [activeFund, setActiveFund] = useState(null);

    // function onWithdrawClick() {
    //     setActiveFund(fund);

    //     setShowWithdrawModal(true);
    // }


    return (
        <div className="w-full rounded-xl p-8 bg-primary shadow-2xl mb-7 cursor-pointer flex flex-col justify-between">
            <div className="relative h-auto text-center">
                <Link to={`/funds/details/${id}`}>
                    <h3 className="text-4xl font-normal text-white text-yellow" style={{minHeight: '5rem'}}>{title}</h3>
                </Link>
                <div className="h-7" />
                <p className="text-white">{description ? description : <span className="italic">No description</span>}</p>
                <div className="h-7" />
                <div className="gs-12">
                    <div className="xs gs-1-2">
                        <p className="font-bold text-gray-500">30 day change</p>
                        <p className="text-success">{yearlyChange && yearlyChange.toFixed(2)}%</p>
                    </div>
                    <div className="xs gs-2-2">
                        <p className="font-bold text-gray-500">Min buy in</p>
                        <p className="text-white">{bitorsat(minBuyIn)}</p>
                    </div>
                </div>
                <div className="h-10" />
            </div>
            <div className="flex flex-col">
                {!hasInvested && <div className="h-16" />}
                <Link to={`/invest/new/${id}?n=${title}`}>
                    <Button
                        color="green"
                        className="hover:bg-green hover:text-black w-full hover:shadow-xl hover:pr-2"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                    >
                        {hasInvested ? "invest more" : "invest"}
                        <Icon name="arrow_forward" size="sm" />
                    </Button>
                </Link>
                
                {hasInvested &&
                    <>
                        <div className="h-4" />
                        <Link to={'/investments'}>
                            <Button
                                color="green"
                                className="hover:bg-green hover:text-black w-full hover:shadow-xl hover:pr-2"
                                buttonType="outline"
                                size="lg"
                                ripple="dark"
                            >
                                Withdraw
                                <Icon name="arrow_forward" size="sm" />
                            </Button>
                        </Link>
                        
                        </>
                }
            </div>
            <WithdrawFromFundToWalletModal
                show={showWithdrawModal}
                setShow={setShowWithdrawModal}
                fund={activeFund}
            />
        </div>
    )
}
