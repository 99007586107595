import {useState} from 'react';

import 'assets/styles/core.css'

import {useParams, useHistory} from 'react-router-dom';

// stages
import Stage1 from 'components/register/Stage1';
import Stage2 from 'components/register/Stage2';
import ConfirmYourDetails from 'components/register/ConfirmYourDetails';
import AcceptConditions from 'components/register/AcceptConditions';
import Welcome from 'components/register/Welcome';

// UNUSED FOR NOW
import CheckYourEmail from 'components/register/CheckYourEmail';
import WhereDidYouHearAboutUs from 'components/register/WhereDidYouHearAboutUs';

import {useGlobal} from 'hooks/useGlobalState';

import {displayAlertModal} from 'utils';

import { Helmet } from 'react-helmet';

export default function Register() {

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [dob, setDob] = useState('');
    const [address, setAddress] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const {stage} = useParams();
    const history = useHistory();

    const {add} = useGlobal();

    async function submit() {
        const body = {
            "name_first": firstname,
            "name_last": lastname,
            "email": email,
            "password": password,
            "confirm_password": confirmPassword,
            "identification_number": idNumber,
            "physical_address": address,
        };

        const isSuccessful = await add(body, '/user/adduser');

        if(isSuccessful) history.push('/register/welcome');

        if(!isSuccessful) displayAlertModal('Something went wrong, please try again later.');
    }

    const REGISTER_COMPONENTS = {
        "email-password": (
            <Stage1 
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
            />
        ),
        "your-details": (
            <Stage2 
                firstname={firstname}
                setFirstname={setFirstname}
                lastname={lastname}
                setLastname={setLastname}
                dob={dob}
                setDob={setDob}
                address={address}
                setAddress={setAddress}
                username={username}
                setUsername={setUsername}
                idNumber={idNumber}
                setIdNumber={setIdNumber}
            />
        ),
        "confirm-details": (
            <ConfirmYourDetails 
                firstname={firstname}
                lastname={lastname}
                dob={dob}
                address={address}
                idNumber={idNumber}
            />
        ),
        "accept-conditions": (
            <AcceptConditions submit={submit} />
        ),
        "welcome": (
            <Welcome />
        ),
        99: (
            <CheckYourEmail />
        ),
        999: (
            <WhereDidYouHearAboutUs />
        )
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Register - Venturebay</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="h-screen px-3 md:px-8 flex items-center justify-center bg-image">
                {REGISTER_COMPONENTS[stage]}
            </div>
        </>
    );
}
